import { of } from "rxjs";
import { catchError, switchMap, map } from "rxjs/operators";
import { Reducers } from "@jauntin/reactables";
import { AppState } from "../RxApp";
import UserService from "../../../../Services/UserService";
import { AuthUser } from "../../Models/authUser.model";
import { maintenanceModeCheck } from "../../../../Helpers/maintenanceModeCheck";

export const authReducers = (userService: UserService) => ({
  fetchAuthUser: {
    reducer: (state: AppState) => ({
      ...state,
      auth: Reducers.load(state.auth),
    }),
    effects: [
      (actions$) =>
        actions$.pipe(
          switchMap(() => userService.loggedInUser()),
          map(({ data }: { data: AuthUser }) => ({
            type: "fetchAuthUserSuccess",
            payload: data,
          })),
          catchError(
            maintenanceModeCheck((error) =>
              of({ type: "fetchAuthUserFailure", payload: error })
            )
          )
        ),
    ],
  },
  fetchAuthUserSuccess: (state: AppState, action) => ({
    ...state,
    auth: Reducers.loadSuccess(state.auth, action),
  }),
  fetchAuthUserFailure: (state, action) => ({
    ...state,
    auth: Reducers.loadError(state.auth, action),
  }),
});
