import { useEffect, useRef } from "react";
import { Form } from "@reactables/react-forms";
import { useAppStore, useReactable } from "@reactables/react-helpers";
import { Route, useHistory, useLocation } from "react-router-dom";
import download from "downloadjs";

import Header from "Features/Shared/Components/Header";
import Footer from "Features/Shared/Components/Footer";
import CustomSwitch from "Features/Shared/Components/CustomSwitch";
import GuardedRoute from "Features/Shared/Components/GuardedRoute";
import { AppState } from "Features/Shared/Rx/RxApp";

import API from "Helpers/API";
import Storage from "Helpers/Storage";

import EventTypeService from "Services/EventTypeService";
import VenuePresetsService from "Services/VenuePresetsService";
import StateTaxService from "Services/StateTaxService";
import PriceService from "Services/PriceService";
import PaymentService from "Services/PaymentService";
import CyberSourceService from "Services/CyberSourceService";
import FacilityNameService from "Services/FacilityNameService";

import { apiUrl } from "Constants/apiUrl";

import { RxCoverageApplication } from "../Rx/RxCoverageApplication/RxCoverageApplication";
import RxStorageEffect from "../Rx/RxStorageEffect/RxStorageEffect";
import ReferralBanner from "./ReferralBanner/ReferralBanner";
import Breadcrumbs from "./BreadCrumbs/Breadcrumbs";
import EventSelect from "./EventSelect/EventSelect";
import UnderwritingQuestions from "./UnderwritingQuestions/UnderwritingQuestions";
import ApplicationPage from "./ApplicationPage/ApplicationPage";
import CheckoutPage from "./CheckoutPage/CheckoutPage";
import ThankYou from "./ThankYou/ThankYou";
import Summary from "./Summary/Summary";

import { getFacilityName } from "../Rx/RxCoverageApplication/Selectors/referral.selector";
import { getApplicationPageConfirmed } from "../Rx/RxCoverageApplication/Selectors/confirmation.selector";
import { getIsUnderwritingQuestionsValid } from "../Rx/RxCoverageApplication/Selectors/underwritingQuestions.selector";
import { getApplicationIncomplete } from "../Rx/RxCoverageApplication/Selectors/purchase.selector";

const CoverageApplication = () => {
  const history = useHistory();
  const location = useLocation();
  const { search } = useRef(location).current;

  const rxStore = useAppStore<AppState>();

  const rxCoverageApplication = useReactable(RxCoverageApplication, {
    eventTypeService: new EventTypeService(new API()),
    venueService: new VenuePresetsService(new API()),
    stateTaxService: new StateTaxService(new API()),
    storage: Storage,
    priceService: new PriceService(new API()),
    paymentService: new PaymentService(new API(apiUrl, false)),
    cyberSourceService: new CyberSourceService(
      new API(`${process.env.REACT_APP_CYBERSOURCE_FLEX_API}`, false)
    ),
    facilityNameService: new FacilityNameService(new API()),
    appState$: rxStore[2],
    options: {
      referralParams: search,
      hooks: {
        onCreateCoverageSuccess: () => {
          history.push("/thank-you");
        },
        onDownloadDocumentsSuccess: download,
      },
    },
  });

  const [state, actions, state$] = rxCoverageApplication;

  useEffect(() => {
    if (location.pathname === "/") {
      actions.applicationForm.resetControl([]);
    }
  }, [location, actions]);

  useReactable(RxStorageEffect, {
    storage: Storage,
    rxCoverageApplicationState$: state$,
  });

  if (!state || !state.eventTypes.data?.length) return;

  const facilityName = getFacilityName(state);

  return (
    <>
      <Header />
      <div className="main-content">
        <ReferralBanner facilityName={facilityName} />
        <Form rxForm={[state.applicationForm, actions.applicationForm]}>
          <CustomSwitch>
            <Route path="/thank-you">
              <Breadcrumbs disableNavigation />
              <ThankYou rxCoverageApplication={rxCoverageApplication} />
            </Route>
            <Route path="/:eventType/:pageType">
              <Breadcrumbs />
              <div className="container-main">
                <div className="row">
                  <div className="col-lg order-1 order-lg-0">
                    <div className="container-main__inner">
                      <GuardedRoute
                        path="/:eventType/application"
                        state={state}
                        component={ApplicationPage}
                        rxCoverageApplication={rxCoverageApplication}
                        guards={[
                          getIsUnderwritingQuestionsValid,
                          getApplicationIncomplete,
                        ]}
                      />
                      <GuardedRoute
                        path="/:eventType/checkout"
                        state={state}
                        component={CheckoutPage}
                        rxCoverageApplication={rxCoverageApplication}
                        guards={[
                          getApplicationPageConfirmed,
                          getApplicationIncomplete,
                        ]}
                      />
                    </div>
                  </div>
                  <Summary rxCoverageApplication={rxCoverageApplication} />
                </div>
              </div>
            </Route>
            <GuardedRoute
              path="/:eventType"
              state={state}
              component={UnderwritingQuestions}
              rxCoverageApplication={rxCoverageApplication}
              guards={[getApplicationIncomplete]}
            />
            <Route path="/">
              <Breadcrumbs />
              <EventSelect rxCoverageApplication={rxCoverageApplication} />
            </Route>
          </CustomSwitch>
        </Form>
      </div>
      <Footer />
    </>
  );
};

export default CoverageApplication;
