import { Action, ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { VenueListItem } from "../../../Models/venueListItem.model";

export interface RemoveVenueActions extends ActionMap {
  removeVenue: (payload: number) => void;
}

export const removeVenueReducers: CustomReducers<RemoveVenueActions> = {
  removeVenue: (
    { updateValues, removeControl },
    state,
    { payload: removeIndex }: Action<number>
  ) => {
    const venueListRef = ["uploadForm", "venueList"];

    const newList = (
      state.form[venueListRef.join(".")].value as VenueListItem[]
    ).filter((_, index) => index !== removeIndex);

    state = updateValues(state, {
      controlRef: ["uploadForm", "venueList"],
      value: newList,
    });

    if (state.form["uploadForm.editVenue"]) {
      state = removeControl(state, ["uploadForm", "editVenue"]);
    }
    return state;
  },
};
