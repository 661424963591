class VenuePresetsService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getPresets(placeId) {
    return this.api.get({
      location: "/venue/byPlace",
      params: {
        id: placeId,
      },
    });
  }

  getPresetsByVenueCode(facilityCode, venueCode) {
    return this.api.get({
      location: "/venue/byVenueCode",
      params: {
        facilityCode,
        venueCode,
      },
    });
  }

  getCoordinates(zip) {
    const api = new this.api.constructor(null, false);

    return api.get({
      location: "https://maps.googleapis.com/maps/api/geocode/json",
      params: {
        address: zip,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    });
  }

  getAdditionalCertHolders(code) {
    return this.api.get({
      location: "/certificateHolders",
      params: {
        code,
      },
    });
  }

  getUtcOffsetByPlaceId(placeId) {
    const api = new this.api.constructor(null, false);
    return api.get({
      location: `https://content-places.googleapis.com/v1/places/${placeId}`,
      params: {
        fields: "utc_offset_minutes",
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    });
  }

  getUtcOffsetByZip(zip) {
    return this.getCoordinates(zip).then((response) => {
      const placeId = response.data.results[0]?.place_id;
      if (placeId) return this.getUtcOffsetByPlaceId(placeId);

      return undefined;
    });
  }
}

export default VenuePresetsService;
