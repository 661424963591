import React from "react";
import { TextInput, Button } from "@jauntin/react-ui";
import { useAppStore } from "@reactables/react-helpers";
import { Field } from "@reactables/react-forms";
import ButtonEdit from "Features/Shared/Components/ButtonEdit";
import RadioInput from "Features/Shared/Components/RadioInput";
import { AppState } from "Features/Shared/Rx/RxApp";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import { ContactType } from "Constants/contactTypes";
import CompanyTypeField from "./CompanyTypeField";
import JointVentureField from "./JointVentureField";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

const Insured = ({
  rxCoverageApplication: [state, actions],
  onConfirm,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
  onConfirm: () => void;
}) => {
  const [appState] = useAppStore<AppState>();

  const usStates = appState?.usStates.data;
  if (!usStates) return;

  const { insured } = FormSelectors.getRootValue(state);
  const confirmed = insured.confirmed;
  const insuredCompanyName = insured.companyDetails?.name;
  const insuredPerson = insured.personName;

  return (
    <div className={`component enabled`}>
      <div className="form-row justify-content-between">
        <div className="col-auto my-auto">
          <h3>Insured</h3>
        </div>
        <ButtonEdit
          edit={() => {
            actions.applicationForm.updateValues({
              controlRef: ["insured", "confirmed"],
              value: false,
            });
          }}
          show={confirmed}
        />
      </div>
      <div className="card">
        <div className="card-body">
          {confirmed ? (
            <>
              {insuredCompanyName && <p>{insuredCompanyName}</p>}
              {insuredPerson && (
                <p>{`${insuredPerson.firstName} ${insuredPerson.lastName}`}</p>
              )}
              <p>{insured.address.address}</p>
              <p>
                {`${insured.address.city}, ${insured.address.state}, ${insured.address.zip}, US`}
              </p>
            </>
          ) : (
            <>
              <div className="form-group">
                <div className="label mb-3">
                  Who is the renter of the venue?
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <Field
                    id={`insured.typeInd`}
                    name="insured.type"
                    className="custom-control-input"
                    component={RadioInput}
                    value={ContactType.Person}
                    onChange={() =>
                      actions.applicationForm.selectInsuredType(
                        ContactType.Person
                      )
                    }
                  />
                  <label
                    className="custom-control-label label"
                    htmlFor={`insured.typeInd`}
                  >
                    an individual
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <Field
                    id={`insured.typeCorp`}
                    name="insured.type"
                    className="custom-control-input"
                    component={RadioInput}
                    value={ContactType.Company}
                    onChange={() =>
                      actions.applicationForm.selectInsuredType(
                        ContactType.Company
                      )
                    }
                  />
                  <label
                    className="custom-control-label label"
                    htmlFor={`insured.typeCorp`}
                  >
                    a company/organization
                  </label>
                </div>
              </div>
              {insuredPerson && (
                <div id="insuredIndividual" className="form-row">
                  <Field
                    component={TextInput}
                    name="insured.personName.firstName"
                    label="First Name"
                    ariaLabel="First Name"
                    placeholder="First Name"
                    wrapperClassName="col-sm"
                    inputClassName="form-control-lg"
                  />
                  <Field
                    component={TextInput}
                    name="insured.personName.lastName"
                    label="Last Name"
                    ariaLabel="Last Name"
                    placeholder="Last Name"
                    wrapperClassName="col-sm"
                    inputClassName="form-control-lg"
                  />
                </div>
              )}
              {insured.companyDetails && (
                <div id="insuredCompanyGroup">
                  <div className="row">
                    <Field
                      component={TextInput}
                      name="insured.companyDetails.name"
                      label="Company name"
                      ariaLabel="Company name"
                      placeholder="Company name"
                      wrapperClassName="col-sm"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div className="row">
                    <Field
                      component={CompanyTypeField}
                      name="insured.companyDetails.type"
                      onChange={
                        actions.applicationForm.selectInsuredCompanyType
                      }
                    />
                    {insured.companyDetails.jointVentureType && (
                      <JointVentureField name="insured.companyDetails.jointVentureType" />
                    )}
                  </div>
                </div>
              )}
              <AddressFieldGroup
                groupName="insured.address"
                usStates={usStates}
              />
              <hr />
              <div className="float-right">
                <Button
                  disabled={!FormSelectors.getControl(state, "insured").valid}
                  onClick={() => {
                    actions.applicationForm.confirmFormGroup("insured");
                    onConfirm();
                  }}
                  text="Confirm Insured"
                  className="btn-primary"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Insured;
