import React from "react";

const RadioInput = ({ input, meta, ...props }) => {
  return (
    <input
      type="radio"
      {...input}
      {...props}
      checked={input.value === props.value}
    />
  );
};

export default RadioInput;
