import { Observable } from "rxjs";
import { switchMap, filter, map } from "rxjs/operators";
import { AppState } from "../Features/Shared/Rx/RxApp";
import { ControlModels, ValidatorAsyncFn } from "@reactables/forms";
import { SelectPlaceAddressSearchPayload } from "../Features/CoverageApplication/Models/venue.model";

export const validateUsState =
  (appState$: Observable<AppState>): ValidatorAsyncFn =>
  (control$) =>
    control$.pipe(
      switchMap((control) => {
        return appState$.pipe(
          filter((appState) => Boolean(appState.usStates.data.length)),
          map((appState) => {
            const { value } =
              control as ControlModels.BaseControl<SelectPlaceAddressSearchPayload>;

            const stateInvalid =
              value?.addressComponents &&
              !appState.usStates.data.some(
                (e) => e.code === value.addressComponents?.state
              );

            return {
              validateUsState: stateInvalid,
            };
          })
        );
      })
    );
