export enum ProviderTypes {
  Performers = "performers",
  Exhibitors = "exhibitors",
  GoodsVendors = "goodsVendors",
  FoodVendors = "foodVendors",
}

export enum ProviderFrequency {
  Everyday = "everyday",
  Somedays = "somedays",
}
