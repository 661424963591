import { group, control } from "@reactables/forms";

export const refundsCancellations = group({
  controls: {
    policyNumber: control({
      initialValue: "",
      validators: ["required", "fiveDigits"],
      normalizers: ["normalize5Digits"],
    }),
    creditCardNumber: control({
      initialValue: "",
      validators: ["required", "fourDigits"],
      normalizers: ["normalize4Digits"],
    }),
    venueZip: control({
      initialValue: "",
      validators: ["required", "zipCodePlus4"],
      normalizers: ["normalizeZipPlus4"],
    }),
    confirm: control([false, "required"]),
  },
});
