import { Action } from "@reactables/core";
import { ControlModels } from "@reactables/forms";
import { Reducers } from "@jauntin/reactables";
import PriceService from "Services/PriceService";
import FacilityNameService from "Services/FacilityNameService";
import VenuePresetsService from "Services/VenuePresetsService";
import { QuoteResponse } from "../../../Models/quote.model";
import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { getPriceValues } from "../Selectors/quote.selector";

import {
  onFormChangeFetchQuote,
  onVenueCodeSearchGetFacilityName,
  onVenueChangeFetchCertHolders,
} from "./applicationFormChange.effect";
import { ApplicationForm } from "Features/CoverageApplication/Models/applicationForm.model";

/**
 * @description maps form state changes to RxCoverageApplication
 * - also detects form changes and fetches quote
 * - also detects form venue code search and fetches facilityName
 */
export const applicationFormChange = (
  priceService: PriceService,
  facilityNameService: FacilityNameService,
  venuePresetsService: VenuePresetsService
) => ({
  applicationFormChange: {
    reducer: (
      state: RxCoverageApplicationState,
      { payload }: Action<ControlModels.Form<ApplicationForm>>
    ) => {
      state = {
        ...state,
        applicationForm: payload,
        quote: Reducers.load(state.quote),
      };

      // If form is pristine, purchase and documents states should be reset
      if (!state.applicationForm.root.dirty) {
        state = {
          ...state,
          purchase: Reducers.loadableInitialState,
          documents: Reducers.loadableInitialState,
        };
      }

      return state;
    },
    effects: [
      onFormChangeFetchQuote(priceService),
      onVenueCodeSearchGetFacilityName(facilityNameService),
      onVenueChangeFetchCertHolders(venuePresetsService),
    ],
  },
  /**
   * @description calculate price values based on received quote and stores in state
   */
  fetchQuoteSuccess: (
    state: RxCoverageApplicationState,
    action: Action<QuoteResponse>
  ) => {
    state = { ...state, quote: Reducers.loadSuccess(state.quote, action) };

    return {
      ...state,
      priceValues: getPriceValues(state),
    };
  },
});
