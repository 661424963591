import { AddVenueSelection, ManualVenue } from "../../../Models/addVenue.model";
import { control, group, ValidatorFn } from "@reactables/forms";
import { Configs } from "@jauntin/reactables";
import { GoogleVenue } from "../../../Models/addVenue.model";

const { address: addressConfig, emptyAddress } = Configs;

export const emptyVenue: AddVenueSelection = {
  googleVenue: null,
};
export const emptyManualVenue: ManualVenue = {
  name: "",
  address: emptyAddress,
  email: "",
  copyOnEmails: false,
};

export const googlePlaceRequired: ValidatorFn = (googleVenue: GoogleVenue) => ({
  googlePlaceRequired: !Boolean(googleVenue?.address),
});

export const venueCompanyNameRequired: ValidatorFn = (
  googleVenue: GoogleVenue
) => ({
  venueCompanyNameRequired:
    googleVenue?.address && !Boolean(googleVenue.address.companyName),
});

export const googleVenueConfig = control([
  null,
  ["googlePlaceRequired", "venueCompanyNameRequired"],
]);

export const manualVenueConfig = group({
  controls: {
    name: control(["", ["required"]]),
    address: addressConfig(),
  },
});

export const addVenueForm = group({
  controls: {
    googleVenue: googleVenueConfig,
  },
});
