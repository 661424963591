import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { getEventDates } from "./event.selector";
import {
  getVenueState,
  getKnownVenue,
  getVenueAddress,
  getUtcOffset,
} from "./venue.selector";
import { getControl } from "./form.selector";
import { dateHelpers } from "@jauntin/utilities";
import { mapProvidersForQuote } from "../../../../../Helpers/providerInputParser";
import { EventType, ProviderDetails } from "../../../Models/event.model";
import { ProviderTypes } from "../../../../../Constants/providers";
import { ApplicationForm } from "../../../Models/applicationForm.model";
import { mapProvidersForSubmission } from "../../../../../Helpers/providerInputParser";
import { AdditionalCoverageTypes } from "../../../Models/venue.model";

export const getQuoteRequestParams = (state: RxCoverageApplicationState) => {
  const eventDates = getEventDates(state).map((d) =>
    dateHelpers.dateOnlyStringFormat(d)
  );

  const form = state.applicationForm;

  const numberOfDays = eventDates.length;

  return {
    selection: (form["eventType"].value as EventType)?.identifier,
    daysOfWeek: form["event.weekly.daysOfWeek"]?.value,

    riskClass: (form["eventType"].value as EventType)?.riskClass || 1,
    venueState: getVenueState(state) || "TX",
    isFederalEntity: getIsFederalEntity(state) ? 1 : 0,
    isKentuckyEntity: getIsKentuckyStateEntity(state) ? 1 : 0,
    venueMunicipalityCode:
      form["venue.venueSearchResults.taxFields.venueMunicipalityCode"]?.value ||
      "",
    averageDailyAttendance:
      parseInt(form["event.eventDailyGuests"].value as string) || 1,
    eventDates,
    numberOfDays,
    gll: form["basicCoverage.coverage"].value,
    performers: mapProvidersForQuote(
      form[`event.${ProviderTypes.Performers}.count`].value as number,
      form[`event.${ProviderTypes.Performers}.frequency`]?.value,
      form[`event.${ProviderTypes.Performers}.providerList`]
        ?.value as ProviderDetails[],
      numberOfDays
    ),
    goods: mapProvidersForQuote(
      form[`event.${ProviderTypes.GoodsVendors}.count`].value as number,
      form[`event.${ProviderTypes.GoodsVendors}.frequency`]?.value,
      form[`event.${ProviderTypes.GoodsVendors}.providerList`]
        ?.value as ProviderDetails[],
      numberOfDays
    ),
    food: mapProvidersForQuote(
      form[`event.${ProviderTypes.FoodVendors}.count`].value as number,
      form[`event.${ProviderTypes.FoodVendors}.frequency`]?.value,
      form[`event.${ProviderTypes.FoodVendors}.providerList`]
        ?.value as ProviderDetails[],
      numberOfDays
    ),
    exhibitors: mapProvidersForQuote(
      form[`event.${ProviderTypes.Exhibitors}.count`].value as number,
      form[`event.${ProviderTypes.Exhibitors}.frequency`]?.value,
      form[`event.${ProviderTypes.Exhibitors}.providerList`]
        ?.value as ProviderDetails[],
      numberOfDays
    ),
    hostLiquor: 1,
    personalAndAdvertisingLimit: 1,
    productsAndCompletedOperations: 1,
    liquorLiability: form[`additionalCoverages.liquorLiability`].value ? 1 : 0,
    personalProperty: 0,
    cancellation: 0,
    damageToRentedProperty: form[`additionalCoverages.damageToRentedProperty`]
      .value
      ? 1000000
      : 250000,
    terrorism: 1,
  };
};

export const getPriceValues = (state: RxCoverageApplicationState) => {
  const quoteValues = state.quote.data;
  if (!quoteValues) return null;

  const basicCoverageAmt = parseInt(quoteValues.basicCoverageAmount.amount, 10);
  const personalPropertyAmt = parseInt(
    quoteValues.additionalCoverages
      .reduce((saved: number, current) => {
        const amt =
          current.type === "personal-property"
            ? parseInt(current.amount.amount, 10)
            : 0;
        return saved + amt;
      }, 0)
      .toString(),
    10
  );

  const liquor = getQuoteAdditionalCoverage(
    quoteValues.additionalCoverageEstimate,
    "liquor-liability"
  );
  const damageToRentedProperty = getQuoteAdditionalCoverage(
    quoteValues.additionalCoverageEstimate,
    "damage-to-rented-property"
  );

  const surcharge = getSurcharges(state);

  const priceValues = {
    basicCoverageAmount: basicCoverageAmt + personalPropertyAmt,
    liquor,
    damageToRentedProperty,
    subtotal: parseInt(quoteValues.subtotal.amount, 10),
    federalSurcharge: surcharge.federalSurcharge,
    stateSurcharge: surcharge.stateSurcharge,
    total: parseInt(quoteValues.total.amount, 10),
  };

  return priceValues;
};

export const getIsFederalEntity = (state: RxCoverageApplicationState) =>
  getControl(state, "venue.venueSearchResults.taxFields.federalEntity")
    ?.value !== "no";

export const getIsKentuckyStateEntity = (state: RxCoverageApplicationState) =>
  getControl(state, "venue.venueSearchResults.taxFields.kentuckyStateEntity")
    ?.value !== "no";

const getQuoteAdditionalCoverage = (additionalCoverageEstimate, type) =>
  additionalCoverageEstimate.reduce(
    (saved, current) =>
      current.type === type ? parseInt(current.amount.amount, 10) : saved,
    0
  );

const getSurcharges = (state: RxCoverageApplicationState) => {
  const {
    quote: { data: quoteValues },
  } = state;

  let federalSurcharge = 0;
  let stateSurcharge = 0;

  const isFederalEntity = getIsFederalEntity(state);
  const isKentuckyEntity = getIsKentuckyStateEntity(state);
  if (quoteValues.surcharges.length === 1) {
    if (
      (!isFederalEntity && isKentuckyEntity) ||
      (!isFederalEntity && !isKentuckyEntity)
    ) {
      federalSurcharge = parseInt(quoteValues.surcharges[0].amount.amount, 10);
    } else {
      stateSurcharge = parseInt(quoteValues.surcharges[0].amount.amount, 10);
    }
  } else if (quoteValues.surcharges.length === 2) {
    federalSurcharge = parseInt(quoteValues.surcharges[0].amount.amount, 10);
    stateSurcharge = parseInt(quoteValues.surcharges[1].amount.amount, 10);
  }
  return { federalSurcharge, stateSurcharge };
};

export const getBaseData = (state: RxCoverageApplicationState) => {
  const { eventType, venue, basicCoverage, event, additionalCoverages } = state
    .applicationForm["root"].value as ApplicationForm;

  const eventDates = getEventDates(state);
  const numberOfDays = eventDates.length;
  const knownVenue = getKnownVenue(state);

  return {
    venueSearchType: venue.searchType,
    isFederalEntity:
      getControl(state, "venue.venueSearchResults.taxFields.federalEntity")
        ?.value === "yes",
    isKentuckyEntity:
      getControl(
        state,
        "venue.venueSearchResults.taxFields.kentuckyStateEntity"
      )?.value === "yes",
    isBlocked: Boolean(getKnownVenue(state)?.venue?.blockedAt),
    eventType: eventType.identifier,
    gll: basicCoverage.coverage.toString(),
    name: event.eventName,
    dates: eventDates.map((d) => dateHelpers.dateOnlyStringFormat(d)),
    eventFrequency: event.eventFrequencyField,
    averageDailyAttendance: event.eventDailyGuests,
    performers: mapProvidersForSubmission(
      event.performers.count,
      event.performers.frequency,
      event.performers.providerList,
      numberOfDays
    ),
    goods: mapProvidersForSubmission(
      event.goodsVendors.count,
      event.goodsVendors.frequency,
      event.goodsVendors.providerList,
      numberOfDays
    ),
    food: mapProvidersForSubmission(
      event.foodVendors.count,
      event.foodVendors.frequency,
      event.foodVendors.providerList,
      numberOfDays
    ),
    exhibitors: mapProvidersForSubmission(
      event.exhibitors.count,
      event.exhibitors.frequency,
      event.exhibitors.providerList,
      numberOfDays
    ),
    additionalCoverage: (() => {
      let additionalCoverage: {
        type: AdditionalCoverageTypes;
        value: unknown;
      }[] = [];

      additionalCoverage = additionalCoverage.concat([
        {
          type: AdditionalCoverageTypes.HostLiquor,
          value: null,
        },
        {
          type: AdditionalCoverageTypes.PersonalAndAdvertisingLimit,
          value: null,
        },
        {
          type: AdditionalCoverageTypes.ProductsAndCompletedOperations,
          value: null,
        },
      ]);

      if (additionalCoverages.liquorLiability) {
        additionalCoverage = additionalCoverage.concat({
          type: AdditionalCoverageTypes.LiquorLiability,
          value: "1",
        });
      }

      additionalCoverage = additionalCoverage.concat({
        type: AdditionalCoverageTypes.Terrorism,
        value: null,
      });

      additionalCoverage = additionalCoverage.concat({
        type: AdditionalCoverageTypes.DamageToRentedProperty,
        value: additionalCoverages.damageToRentedProperty
          ? "1000000"
          : "250000",
      });

      return additionalCoverage;
    })(),
    venueAddress: [
      {
        placeId: (() => {
          if (knownVenue && knownVenue.places?.length) {
            return knownVenue.places[0]?.placeId;
          } else {
            return venue.bySearch?.selectedPlace?.placeId || "";
          }
        })(),
        facilityCode: (venue.byVenueCode && knownVenue?.facility?.code) || "",
        venueCode: (venue.byVenueCode && knownVenue?.venue?.venueCode) || "",
        ...getVenueAddress(state),
        utcOffset: getUtcOffset(state) || 0,
        municipalTaxCode:
          venue.venueSearchResults.taxFields?.venueMunicipalityCode || null,
      },
    ],
    sourceCode: "", // Is this ever used anymore?
    venueCode: state.referral.venueCode,
    facilityCode:
      state.referral.facilityCode ||
      (venue.byVenueCode && knownVenue?.facility?.code) ||
      "",
  };
};

export const getSaveQuoteData = (state: RxCoverageApplicationState) => {
  const { priceValues } = state;
  const baseData = getBaseData(state);
  return {
    ...baseData,
    numberOfDays: baseData.dates.length,
    quoteTotal: priceValues.total,
    surcharges: getSurcharges(state),
  };
};

export const getTaxType = (state: RxCoverageApplicationState) => {
  const venueState = getVenueState(state);
  let taxType = "State Tax";
  if (venueState?.toUpperCase() === "KY") {
    taxType = "KY Municipal Tax";
  }
  if (venueState?.toUpperCase() === "FL") {
    taxType = "State Surcharge";
  }
  return taxType;
};
