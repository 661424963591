import React from "react";
import { useReactable } from "@reactables/react-helpers";
import { Modal } from "react-bootstrap";
import { RxToggle } from "@jauntin/reactables";

const ExcludedActivitiesModal = ({ showIt, handleClose }) => (
  <Modal
    show={showIt}
    onHide={handleClose}
    dialogClassName="modal--width-lg px-md-5"
  >
    <Modal.Header closeButton>
      <h2 className="modal-title h3">Excluded objects & activities</h2>
    </Modal.Header>
    <Modal.Body>
      <p>
        The following activities or use of the following objects are not covered
        by the policy.
      </p>
      &nbsp;
      <p>Any event with a known attendance of greater than 5,000</p>
      &nbsp;
      <ul className="two-col list-unstyled">
        <li>Aircraft rides, exhibitions or operation</li>
        <li>Bicycle rally or race</li>
        <li>Bungee jumping</li>
        <li>Circus</li>
        <li>Wall climbing</li>
        <li>Crowd surfing, moshing or stage diving</li>
        <li>Exotic animal show or event</li>
        <li>Film production</li>
        <li>Fireworks</li>
        <li>Fraternity event</li>
        <li>Go kart race</li>
        <li>Hang gliding</li>
        <li>Heads of state event</li>
        <li>Hot air balloon ride</li>
        <li>Hypnotist</li>
        <li>
          Inflatable (including bounce houses and inflatables containing a
          person)
        </li>
        <li>Laser tag</li>
        <li>Luge</li>
        <li>
          Mechanical amusement device (i.e. carnival ride or mechanical bull)
        </li>
        <li>Motorized sporting event</li>
        <li>New Year&apos;s party (open to public)</li>
        <li>Overnight camping</li>
        <li>Paintball</li>
        <li>Parachuting</li>
        <li>Parasailing</li>
        <li>Petting zoo</li>
        <li>Political rally, march, or event</li>
        <li>Promoter</li>
        <li>Rave</li>
        <li>Reality TV shows</li>
        <li>Renaissance fairs or festivals</li>
        <li>Professional rodeo or roping event</li>
        <li>Roller coaster or sky coaster </li>
        <li>Saddle animal</li>
        <li>Skateboarding</li>
        <li>Skiing</li>
        <li>Skydiving</li>
        <li>Soap box derby race</li>
        <li>Sorority event</li>
        <li>Swimming or pool facility</li>
        <li>Temporary grandstand usage</li>
        <li>Tobogganing</li>
        <li>Tractor pulling</li>
        <li>Trampoline usage</li>
        <li>Wall climbing</li>
        <li>War Game or Re-enactment</li>
        <li>Water Sports, other than fishing</li>
        <li>Water slide usage</li>
      </ul>
    </Modal.Body>
  </Modal>
);

function ExcludedActivitiesCheckField({ input, meta, disabled }) {
  const [showModal, modalActions] = useReactable(RxToggle);

  return (
    <>
      {meta.touched && meta.error && <span>{meta.error}</span>}
      <div className="custom-control custom-checkbox">
        <input
          {...input}
          id={input.name}
          className="custom-control-input"
          type="checkbox"
          disabled={disabled}
          checked={input.value}
        />
        <label
          className="custom-control-label font-italic"
          htmlFor={input.name}
        >
          I have reviewed the list of{" "}
          <span
            tabIndex={0}
            role="button"
            className="font-italic span-link span-link--normal"
            onClick={modalActions.toggleOn}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === " " || e.key === "Spacebar")
                modalActions.toggleOn();
            }}
          >
            excluded objects and activities
          </span>{" "}
          and understand they are not covered by the policy.
        </label>
      </div>
      <ExcludedActivitiesModal
        showIt={showModal}
        handleClose={modalActions.toggleOff}
      />
    </>
  );
}

export default ExcludedActivitiesCheckField;
