import React from "react";
import { Field } from "@reactables/react-forms";
import RadioInput from "Features/Shared/Components/RadioInput";

const SecurityField = ({ name }: { name: string }) => (
  <div className="form-group">
    <div className="label text-center font-weight-normal">
      Will there be any armed security who are not police officers?
    </div>
    <div className="form-row justify-content-center mt-3 flex-nowrap">
      <div className="col-auto my-auto p-0">
        <div className="custom-control custom-radio">
          <Field
            name={name}
            value="yes"
            id={`${name}Yes`}
            component={RadioInput}
            type="radio"
            className="custom-control-input"
          />
          <label
            className="btn btn-outline-secondary eventSelect__radio eventSelect__radio--yes"
            htmlFor={`${name}Yes`}
          >
            Yes
          </label>
        </div>
      </div>
      <div className="col-auto my-auto p-0">
        <div className="custom-control custom-radio">
          <Field
            name={name}
            value="no"
            id={`${name}No`}
            component={RadioInput}
            type="radio"
            className="custom-control-input"
          />
          <label
            className="btn btn-outline-secondary eventSelect__radio eventSelect__radio--no"
            htmlFor={`${name}No`}
          >
            No
          </label>
        </div>
      </div>
    </div>
  </div>
);

export default SecurityField;
