import React from "react";
import { Field } from "@reactables/react-forms";

const Checkbox = ({ input, ...props }) => (
  <input
    type="checkbox"
    {...input}
    checked={input.value}
    value={input.value}
    {...props}
  />
);

const DaysOfWeekField = ({ name }) => (
  <div className="form-group weekly-recurring-picker">
    <div className="custom-control custom-control-inline">
      <div className="mr-2" aria-label="day-picker">
        <label>
          <Field
            name={`${name}.0`}
            className="weekly-checkbox"
            component={Checkbox}
          />
          <p className="first-day">S</p>
        </label>
        <label>
          <Field
            name={`${name}.1`}
            id="monday-weekly-checkbox"
            className="weekly-checkbox"
            component={Checkbox}
          />
          <p className="day-of-week">M</p>
        </label>
        <label>
          <Field
            id="tuesday-weekly-checkbox"
            name={`${name}.2`}
            className="weekly-checkbox"
            component={Checkbox}
          />
          <p className="day-of-week">T</p>
        </label>
        <label>
          <Field
            id="wednesday-weekly-checkbox"
            name={`${name}.3`}
            className="weekly-checkbox"
            component={Checkbox}
          />
          <p className="day-of-week">W</p>
        </label>
        <label>
          <Field
            id="thursday-weekly-checkbox"
            name={`${name}.4`}
            className="weekly-checkbox"
            component={Checkbox}
          />
          <p className="day-of-week">T</p>
        </label>
        <label>
          <Field
            id="friday-weekly-checkbox"
            name={`${name}.5`}
            className="weekly-checkbox"
            component={Checkbox}
          />
          <p className="day-of-week">F</p>
        </label>
        <label>
          <Field
            id="saturday-weekly-checkbox"
            name={`${name}.6`}
            className="weekly-checkbox"
            component={Checkbox}
          />
          <p className="last-day">S</p>
        </label>
      </div>
    </div>
  </div>
);

export default DaysOfWeekField;
