import React from "react";
import { IntegerNumberInput } from "@jauntin/react-ui";
import pluralize from "Helpers/Pluralize";

const GuestsField = ({
  input,
  meta,
  disabled,
  eventTotalGuests,
  eventDates,
  hasMaxAttendeesError,
}) => (
  <div className="guestField form-group d-flex flex-column flex-wrap">
    <div className="col-sm-12 p-0">
      <span
        className={
          meta.touched && (meta.error || hasMaxAttendeesError)
            ? "label form-error__label"
            : "label"
        }
      >
        How many people are you expecting?
      </span>
      <div className="label__subtext d-block">
        Include all who will attend including hosts, guests, volunteers, and any
        performers, vendors or exhibitors.
      </div>
    </div>
    <div className="row align-items-center">
      <div className="px-3 mt-3 guestField__input">
        <IntegerNumberInput
          input={input}
          meta={{
            ...meta,
            errors: {
              ...meta.errors,
              maxAttendees: hasMaxAttendeesError,
            },
          }}
          label="Average Daily Attendance?"
          placeholder="Avg Daily Attendance"
          min={1}
          disabled={disabled}
          required
        />
      </div>
      {eventDates.length > 0 && (
        <div className="col-sm-12 col-xl-8 mt-3 mt-xl-4 d-flex align-items-center pl-0 pl-xl-3">
          Total event attendance: {eventTotalGuests || 0}{" "}
          {pluralize(eventTotalGuests, "person", "people")}
        </div>
      )}
    </div>
  </div>
);

export default GuestsField;
