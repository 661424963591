import { group, control } from "@reactables/forms";

export const sameAddress = control(["yes"]);

export const insuranceContact = group({
  controls: {
    sameAddressAsInsured: sameAddress,
    firstName: control(["", "required"]),
    lastName: control(["", "required"]),
    phone: control({
      initialValue: "",
      normalizers: ["normalizePhone"],
      validators: ["required", "phoneNumber"],
    }),
    email: control({
      initialValue: "",
      normalizers: ["normalizeEmail"],
      validators: ["required", "email"],
    }),
    confirmed: control([false]),
    // differentAddressFields added here if sameAddress is 'no'
  },
});
