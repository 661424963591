import React from "react";
import { RxToggle } from "@jauntin/reactables";
import { useReactable } from "@reactables/react-helpers";
import { dateHelpers } from "@jauntin/utilities";
import ButtonCollapse from "Features/Shared/Components/ButtonCollapse";

const DateList = ({
  dates,
  deleteDate,
}: {
  dates: string[];
  deleteDate: (date: string) => void;
}) => {
  const [showList, showListToggle] = useReactable(RxToggle);

  return (
    <>
      <ButtonCollapse
        clickFunction={showListToggle.toggle}
        showList={showList}
        title={`${dates.length} date(s) selected`}
      />
      {showList &&
        dates.map((date) => (
          <div key={dateHelpers.dateOnlyStringFormat(date)} className="d-flex">
            <button
              type="button"
              className="btn btn-link py-0"
              onClick={() => deleteDate(date)}
            >
              <i className="fal fa-trash-alt" />
            </button>
            <span className="my-auto">
              {dateHelpers.lettersAndNumbers(date)}
            </span>
          </div>
        ))}
    </>
  );
};
export default DateList;
