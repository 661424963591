import React from "react";
import { Modal } from "react-bootstrap";
import { useReactable } from "@reactables/react-helpers";
import { CheckboxInput } from "@jauntin/react-ui";
import { RxToggle } from "@jauntin/reactables";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import CurrencyHelper from "Helpers/CurrencyHelper";

const TerrorismRiskModal = ({ showModal, handleClose }) => (
  <Modal
    show={showModal}
    onHide={handleClose}
    dialogClassName="modal--width-lg px-md-5"
  >
    <Modal.Header closeButton>
      <h2 className="modal-title h3">Terrorism Risk Insurance Act</h2>
    </Modal.Header>
    <Modal.Body>
      <p className="mb-3">
        You are hereby notified that under the Terrorism Risk Insurance Act (the
        Act), as amended, your policy will provide insurance coverage for losses
        resulting from acts of terrorism. As defined in Section 102(1) of the
        Act: The term “act of terrorism” means any act or acts that are
        certified by the Secretary of the Treasury - in consultation with the
        Secretary of Homeland Security, and the Attorney General of the United
        States - to be an act of terrorism; to be a violent act or an act that
        is dangerous to human life, property, or infrastructure; to have
        resulted in damage within the United States, or outside the United
        States in the case of an air carrier or vessel or the premises of a
        United States mission; and to have been committed by an individual or
        individuals as part of an effort to coerce the civilian population of
        the United States or to influence the policy or affect the conduct of
        the United States Government by coercion.
      </p>
      <p className="mb-3">
        YOU SHOULD KNOW THAT WHERE COVERAGE IS PROVIDED BY THIS POLICY FOR
        LOSSES RESULTING FROM CERTIFIED ACTS OF TERRORISM, SUCH LOSSES MAY BE
        PARTIALLY REIMBURSED BY THE UNITED STATES GOVERNMENT UNDER A FORMULA
        ESTABLISHED BY FEDERAL LAW. HOWEVER, YOUR POLICY MAY CONTAIN OTHER
        EXCLUSIONS WHICH MIGHT AFFECT YOUR COVERAGE, SUCH AS AN EXCLUSION FOR
        NUCLEAR EVENTS. UNDER THE FORMULA, THE UNITED STATES GOVERNMENT
        GENERALLY REIMBURSES 80%, BEGINNING ON JANUARY 1, 2020, OF COVERED
        TERRORISM LOSSES EXCEEDING THE STATUTORILY ESTABLISHED DEDUCTIBLE PAID
        BY THE INSURANCE COMPANY PROVIDING THE COVERAGE. THE PREMIUM TO BE
        CHARGED FOR THIS COVERAGE IS PROVIDED BELOW AND DOES NOT INCLUDE ANY
        CHARGES FOR THE PORTION OF LOSS THAT MAY BE COVERED BY THE FEDERAL
        GOVERNMENT UNDER THE ACT.
      </p>
      YOU SHOULD ALSO KNOW THAT THE TERRORISM RISK INSURANCE ACT, AS AMENDED,
      <p className="mb-3">
        CONTAINS A $100 BILLION CAP THAT LIMITS U.S. GOVERNMENT REIMBURSEMENT AS
        WELL AS INSURERS’ LIABILITY FOR LOSSES RESULTING FROM CERTIFIED ACTS OF
        TERRORISM WHEN THE AMOUNT OF SUCH LOSSES IN ANY ONE CALENDAR YEAR
        EXCEEDS $100 BILLION. IF THE AGGREGATE INSURED LOSSES FOR ALL INSURERS
        EXCEED $100 BILLION, YOUR COVERAGE MAY BE REDUCED.
      </p>
      <p className="mb-3">
        There is no premium charge for coverage for losses caused by acts of
        terrorism, as defined in the Act. Since coverage for acts of terrorism,
        as defined in the Act, is being provided in your policy you do not need
        to take any action with respect to this notice.
      </p>
    </Modal.Body>
  </Modal>
);

function TerrorismField({ input, meta, disabled, enabled }) {
  const [showModal, modalActions] = useReactable(RxToggle);

  return (
    <>
      <div className="col-auto w--70 label">
        <DollarsAndCents
          currency={new CurrencyHelper().setValue(0)}
          cents={false}
        />
      </div>
      <div className="col">
        <label
          htmlFor={input.name}
          className={
            meta.touched && meta.error ? "label form-error__label" : "label"
          }
        >
          <p className="mb-2">
            Terrorism Coverage &mdash;
            <span className="font-italic"> included</span>
          </p>
          <div className="font-14 text-dark font-weight-normal">
            Coverage for acts of terrorism as defined in the Terrorism Risk
            Insurance Act.{" "}
            <span
              tabIndex={!enabled ? -1 : 0}
              role="button"
              className="span-link"
              onClick={modalActions.toggleOn}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " " || e.key === "Spacebar")
                  modalActions.toggleOn();
              }}
            >
              View the required policyholder disclosure notice
            </span>
            .
          </div>
        </label>
        {meta.touched && meta.error && <span>{meta.error}</span>}
      </div>
      <div className="col-auto invisible">
        <CheckboxInput
          input={input}
          meta={meta}
          showErrors={() => false}
          disabled={disabled}
          label={<>&nbsp;</>}
        />
      </div>
      <TerrorismRiskModal
        showModal={showModal}
        handleClose={modalActions.toggleOff}
      />
    </>
  );
}

export default TerrorismField;
