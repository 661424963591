import { Reactable } from "@reactables/core";
import { RxBuilder, RxToggle, Reducers } from "@jauntin/reactables";
import { map, mergeMap, catchError } from "rxjs/operators";
import { from, of, combineLatest, Observable } from "rxjs";
import {
  RxVenueUploadForm,
  RxVenueUploadFormActions,
  RxVenueUploadFormState,
} from "../RxVenuesForm/RxVenuesForm";
import {
  IVenueUploadService,
  LoadFacilityInfoResponse,
} from "../../Models/IVenueUploadService.model";
const { load, loadError, loadSuccess, loadableInitialState } = Reducers;

export interface RxVenueUploaderActions {
  form: RxVenueUploadFormActions;
  submit: (payload: unknown) => void;
  toggleContactEdit: () => void;
}

export interface RxVenueUploaderState {
  form: RxVenueUploadFormState;
  facilityInfo: Reducers.LoadableState<LoadFacilityInfoResponse>;
  submission: Reducers.LoadableState<unknown>;
  editingContact: boolean;
}

export type RxVenueUploaderProp = [
  RxVenueUploaderState,
  RxVenueUploaderActions,
  Observable<RxVenueUploaderState>
];

/**
 * @description Manages overall state of VenueUploader feature including:
 * - loading facility info
 * - venue up load submission
 * - receiving updates from RxVenuesForm
 * - toggling edit view of contact information
 */
export const RxVenueUploader = (
  venueUploadService: IVenueUploadService,
  {
    uploadId,
    debug,
  }: {
    uploadId: string;
    debug?: {
      facilityInfo?: boolean;
      form?: boolean;
      submission?: boolean;
    };
  }
): Reactable<RxVenueUploaderState, RxVenueUploaderActions> => {
  // Observable to fetch facilityInfo
  const loadFacility$ = from(
    venueUploadService.loadFacilityInfo(uploadId)
  ).pipe(
    map(({ data }: { data: LoadFacilityInfoResponse }) => ({
      type: "loadFacilitySuccess",
      payload: data,
    })),
    catchError((error: unknown) =>
      of({ type: "loadFacilityFailure", payload: error })
    )
  );

  // Rx manages loading of Facility Info
  const rxFacilityInfo = RxBuilder({
    name: "rxFacilityInfo",
    initialState: {
      ...loadableInitialState,
      loading: true,
    },
    debug: debug?.facilityInfo,
    sources: [loadFacility$],
    reducers: {
      loadFacilitySuccess: loadSuccess,
      loadFacilityFailure: loadError,
    },
  });

  // Rx manages form state and validations
  const rxForm = RxVenueUploadForm({ debug: debug?.form });

  // Rx manages "editingContact" state
  const rxEditContactToggle = RxToggle(true);

  // Rx manages form submission
  const rxSubmission = RxBuilder({
    name: "rxSubmission",
    initialState: loadableInitialState,
    debug: debug?.submission,
    reducers: {
      submitForm: {
        reducer: load,
        effects: [
          (submission$) =>
            submission$.pipe(
              mergeMap(({ payload }) => {
                return from(
                  venueUploadService.uploadVenues(uploadId, payload)
                ).pipe(
                  map(() => ({ type: "submitSuccess" })),
                  catchError((error: unknown) =>
                    of({
                      type: "submitFailure",
                      payload: error,
                    })
                  )
                );
              })
            ),
        ],
      },
      submitSuccess: loadSuccess,
      submitFailure: loadError,
    },
  });

  // Combine states of reactables
  const state$ = combineLatest({
    form: rxForm[0],
    facilityInfo: rxFacilityInfo[0],
    submission: rxSubmission[0],
    editingContact: rxEditContactToggle[0],
  });

  // Expose public action methods
  const actions = {
    form: rxForm[1],
    submit: rxSubmission[1].submitForm,
    toggleContactEdit: rxEditContactToggle[1].toggle,
  };

  return [state$, actions];
};
