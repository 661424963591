export const listToString = (list) =>
  (list || [])
    .reduce((c, e, i) => `${c}${i ? ", " : ""}${e}`, "")
    .replace(/, ([^,]*)$/, " and $1");

export const listToStringDays = (list) =>
  (list || [])
    .reduce(
      (c, e, i) =>
        `${c}${i ? ", " : ""}${e.name} (${e.days} ${
          Number(e.days) > 1 ? "days" : "day"
        })`,
      ""
    )
    .replace(/, ([^,]*)$/, " and $1");
