import React from "react";

const ConsentCheckField = ({ input, meta, disabled }) => (
  <>
    {meta.touched && meta.error && <span>{meta.error}</span>}
    <div className="custom-control custom-checkbox">
      <input
        {...input}
        id={input.name}
        className="custom-control-input"
        type="checkbox"
        disabled={disabled}
        checked={input.value}
      />
      <label className="custom-control-label font-italic" htmlFor={input.name}>
        I agree that the entered information is correct to the best of my
        knowledge, and I have reviewed the{" "}
        <a
          href={`${process.env.REACT_APP_FRAUD_WARNING_LINK}`}
          target="_blank"
          rel="noreferrer noopener"
          className="span-link span-link--normal"
        >
          fraud warnings
        </a>
        , and&nbsp;
        <a
          href={`${process.env.REACT_APP_MARKETING_SITE_HOST}refunds-and-cancellations`}
          target="_blank"
          rel="noreferrer noopener"
          className="span-link span-link--normal"
        >
          refund policy
        </a>
        .
      </label>
    </div>
  </>
);

export default ConsentCheckField;
