import { group, control } from "@reactables/forms";
import { sameAddress } from "./address.config";

const creditCard = group({
  validators: ["validateCvv"],
  controls: {
    cardNumber: control({
      initialValue: "",
      validators: ["required", "creditCardNumber"],
      normalizers: ["normalizeCreditCard"],
    }),
    cardName: control(["", "required"]),
    expiryDate: control({
      initialValue: "",
      validators: ["required", "creditCardDate"],
      normalizers: ["normalizeCCDate"],
    }),
    cvv: control(["", "required"]),
  },
});

export const payment = group({
  controls: {
    billing: group({
      controls: {
        creditCard,
        sameAddressAsContact: sameAddress,
        // differentAddressFields added here if sameAddressAsContact is 'no'
      },
    }),
    confirmed: control([false]),
    token: control([null, ["required", "validToken"]]),
  },
});
