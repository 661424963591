import { TextInput, PlacesSearch, Button } from "@jauntin/react-ui";
import { useAppStore } from "@reactables/react-helpers";
import { AppState } from "Features/Shared/Rx/RxApp";
import { Field } from "@reactables/react-forms";
import { RxVenueUploaderProp } from "../Rx/RxVenueUploader/RxVenueUploader";
import React from "react";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";

const AddVenueForm = ({
  rxVenueUploader: [state, actions],
}: {
  rxVenueUploader: RxVenueUploaderProp;
}) => {
  const [appState] = useAppStore<AppState>();

  if (!appState || appState.usStates.loading) return;

  const { data: usStates } = appState.usStates;

  const { form } = state;

  const {
    addVenueForm: { googleVenue },
  } = form.root.value;

  const selectedGoogleAddress = googleVenue?.address;

  const selectedGoogleAddressLabel = [
    selectedGoogleAddress?.companyName,
    selectedGoogleAddress?.address1,
    selectedGoogleAddress?.city,
    selectedGoogleAddress?.state,
    selectedGoogleAddress?.country,
  ]
    .filter((item) => Boolean(item))
    .join(", ");

  return (
    <div>
      {form["addVenueForm.manualVenue"] ? (
        <>
          <div className="row">
            <div className="col">
              <Field
                name="addVenueForm.manualVenue.name"
                label="Venue Name"
                component={TextInput}
                inputClassName="form-control-lg"
              />
            </div>
          </div>
          <AddressFieldGroup
            groupName="addVenueForm.manualVenue.address"
            usStates={usStates}
          />
          <Button
            className="btn-link pl-0"
            text="Try Google Search"
            onClick={actions.form.toggleSearchType}
          />
        </>
      ) : (
        <>
          {appState?.googleMapsLoaded && (
            <>
              <Field
                name={"addVenueForm.googleVenue"}
                label="Search for a Venue"
                component={PlacesSearch}
                readOnly={selectedGoogleAddress}
                hasInput={true}
                clearForm={actions.form.clearGoogleSearch}
                onSelect={() => undefined}
              />
            </>
          )}
          {form["addVenueForm.googleVenue"].errors.venueCompanyNameRequired && (
            <>
              <div className="font-weight-bold text-danger">
                A venue name is required.
              </div>
              <div>
                <i className="fal fa-times-circle text-danger" />
                &nbsp;
                <span className="font-italic text-danger">
                  {selectedGoogleAddressLabel}
                </span>
              </div>
            </>
          )}
          {selectedGoogleAddress &&
            !form["addVenueForm.googleVenue"].errors
              .venueCompanyNameRequired && (
              <div className="my-3">
                <div className="font-weight-bold">
                  Please verify the following address before confirming the
                  venue
                </div>
                <div className="d-flex align-items-center mt-2">
                  <i className="fal fa-check-circle text-primary mr-2" />
                  &nbsp;
                  <span className="font-italic">
                    {selectedGoogleAddressLabel}
                  </span>
                </div>
              </div>
            )}
          <Button
            className="btn-link pl-0"
            text="Can't find a venue? Enter it manually"
            onClick={actions.form.toggleSearchType}
          />
        </>
      )}
      <Button
        onClick={actions.form.addVenue}
        disabled={!form.addVenueForm.valid}
        text="Add Venue"
        className="mt-3 w-100 btn-primary"
      />
    </div>
  );
};

export default AddVenueForm;
