import { Reactable, EffectsAndSources } from "@reactables/core";
import { ControlModels, RxFormActions } from "@reactables/forms";
import { normalizers } from "@jauntin/utilities";
import { validatorFns, FormBuilders } from "@jauntin/reactables";
import { formConfig } from "./Configs/form.config";
import { UploadForm } from "../../Models/uploadForm.model";
import {
  addVenueReducers,
  AddVenueFormActions,
} from "./Reducers/addVenueForm.reducer";
import {
  editVenueReducers,
  EditVenueActions,
} from "./Reducers/editVenue.reducer";
import {
  removeVenueReducers,
  RemoveVenueActions,
} from "./Reducers/removeVenue.reducer";
import { AddVenueSelection } from "../../Models/addVenue.model";
import {
  googlePlaceRequired,
  venueCompanyNameRequired,
} from "./Configs/addVenueForm.config";

export interface RxVenueUploadFormActions
  extends AddVenueFormActions,
    EditVenueActions,
    RemoveVenueActions,
    RxFormActions {}

export type RxVenueUploadFormState = ControlModels.Form<{
  addVenueForm: AddVenueSelection;
  uploadForm: UploadForm;
}>;

/**
 * @description Manages form state for VenueUploader feature
 */
export const RxVenueUploadForm = (
  options?: { debug?: boolean } & EffectsAndSources
) =>
  FormBuilders.build(formConfig, {
    name: "rxVenueUploadForm",
    ...options,
    // Reducers for custom form behaviour
    reducers: {
      ...addVenueReducers,
      ...editVenueReducers,
      ...removeVenueReducers,
    },
    providers: {
      // Provide validators when initializing the form
      validators: {
        ...validatorFns(),
        googlePlaceRequired,
        venueCompanyNameRequired,
        notEditing: (value: UploadForm) => ({
          notEditing: Boolean(value.editVenue),
        }),
      },
      normalizers,
    },
  }) as Reactable<RxVenueUploadFormState, RxVenueUploadFormActions>;
