import React from "react";
import { dateHelpers } from "@jauntin/utilities";

const ViewDateList = ({ dates }) => (
  <div className="py-2">
    {dates.map((date) => (
      <div key={dateHelpers.dateOnlyStringFormat(date)} className="d-flex">
        <button type="button" className="btn btn-link py-0" />
        <span className="my-auto">{dateHelpers.lettersAndNumbers(date)}</span>
      </div>
    ))}
  </div>
);

export default ViewDateList;
