import React from "react";
import CurrencyHelper from "Helpers/CurrencyHelper";

const BasicCoverage = ({ confirmed, coverage }) =>
  confirmed && (
    <li>
      <span>
        General liability up to
        {` ${new CurrencyHelper().setValue(coverage).formatAsTextInMillions()}`}
      </span>
    </li>
  );

export default BasicCoverage;
