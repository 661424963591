import React from "react";
import { mapExtendedErrors } from "@jauntin/utilities";

const ProviderNameField = ({ input, meta }) => {
  meta = mapExtendedErrors(meta);
  return (
    <input
      {...input}
      className={`form-control form-control-lg col-sm-6 mr-3 ${
        meta.touched && meta.error ? "border-danger" : ""
      }`}
      aria-label="Provider"
    />
  );
};

export default ProviderNameField;
