import { Action, ActionMap } from "@reactables/core";
import { dateHelpers } from "@jauntin/utilities";
import { BaseFormState } from "@reactables/forms/dist/Models/Controls";
import { CustomReducers, FormReducers } from "@reactables/forms";
import { EventFrequency } from "Constants/eventFrequency";
import { ProviderTypes, ProviderFrequency } from "Constants/providers";
import {
  providerFrequency,
  providerList,
  providerListItem,
} from "../Configs/event.config";
import { EventType } from "../../../Models/event.model";
import { getEventDatesFromForm } from "../../RxCoverageApplication/Selectors/event.selector";
import { selectEventFrequency } from "./selectEventFrequency.reducer";
import { selectEventType } from "./selectEventType.reducer";

export interface EventActions extends ActionMap {
  selectEventType: (payload: EventType) => void;
  selectEventTypeIfNeeded: (payload: EventType) => void;
  selectEventFrequency: (payload: EventFrequency) => void;
  selectProviderCount: (payload: {
    providerType: ProviderTypes;
    count: number;
  }) => void;
  selectProviderFrequency: (payload: {
    providerType: ProviderTypes;
    frequency: ProviderFrequency;
  }) => void;
  updateProviderListItemDay: (payload: {
    index: number;
    providerType: ProviderTypes;
    days: string;
  }) => void;
  selectContinuousEventDates: (payload: {
    startDate: string;
    endDate: string;
  }) => void;
  selectWeeklyEventDates: (payload: {
    startDate: string;
    endDate: string;
  }) => void;
  deleteDate: (payload: string) => void;
}

export const eventReducers: CustomReducers<EventActions> = {
  selectEventType,
  /**@description used to select event type from url path if no event type is chosen  */
  selectEventTypeIfNeeded: (formReducers, state, action: Action<EventType>) => {
    if (!state.form["eventType"].value) {
      state = selectEventType(formReducers, state, action);
    }
    return state;
  },
  selectEventFrequency,
  /**
   * @description Sets count for venue providers
   * - adds/remove controls based on count, provider frequency & event duration
   */
  selectProviderCount: (
    { updateValues, resetControl, addControl, pushControl, removeControl },
    state,
    {
      payload: { count, providerType },
    }: Action<{
      providerType: ProviderTypes;
      count: number;
    }>
  ) => {
    // If no change in count
    if (count === state.form[`event.${providerType}.count`].value) {
      return state;
    }

    // If count is zero or 7 reset the provider control, update the count, and return the state.
    if (!count || count === 7) {
      state = resetControl(state, ["event", providerType]);
      state = updateValues(state, {
        controlRef: ["event", providerType, "count"],
        value: count,
      });
      return state;
    }

    state = updateValues(state, {
      controlRef: ["event", providerType, "count"],
      value: count,
    });

    const duration = dateHelpers.numberOfDays(
      getEventDatesFromForm(state.form)
    );

    // If duration less than two we don't need the provider's frequency controls
    if (duration < 2) return state;

    // If frequency control is not present, add it
    if (!state.form[`event.${providerType}.frequency`]) {
      state = addControl(state, {
        controlRef: ["event", providerType, "frequency"],
        config: providerFrequency,
      });
    }

    // If frequency is 'somedays', check if we need to add or remove items based on new count
    if (
      state.form[`event.${providerType}.frequency`].value ===
      ProviderFrequency.Somedays
    ) {
      const providerListLength = (
        state.form[`event.${providerType}.providerList`].value as {
          name: string;
          days: number;
        }[]
      ).length;

      if (count > providerListLength) {
        // We need to push controls
        const diff = count - providerListLength;
        for (
          let i = providerListLength + 1;
          i < providerListLength + diff + 1;
          i++
        ) {
          state = pushControl(state, {
            controlRef: ["event", providerType, "providerList"],
            config: providerListItem(
              `${providerTypeLabelMap[providerType]} ${i}`
            ),
          });
        }
      } else {
        // We need to remove controls
        const diff = providerListLength - count;

        for (let i = 0; i < diff; i++) {
          const lastIndex =
            (
              state.form[`event.${providerType}.providerList`].value as {
                name: string;
                days: number;
              }[]
            ).length - 1;

          state = removeControl(state, [
            "event",
            providerType,
            "providerList",
            lastIndex,
          ]);
        }
      }
    }

    return state;
  },
  /**
   * @description Updates provider frequency
   * - add/removes providerList control as needed
   */
  selectProviderFrequency: (
    { updateValues, addControl, removeControl },
    state,
    {
      payload: { providerType, frequency },
    }: Action<{
      providerType: ProviderTypes;
      frequency: ProviderFrequency;
    }>
  ) => {
    state = updateValues(state, {
      controlRef: ["event", providerType, "frequency"],
      value: frequency,
    });

    if (frequency === ProviderFrequency.Somedays) {
      state = addControl(state, {
        controlRef: ["event", providerType, "providerList"],
        config: providerList({
          name: providerTypeLabelMap[providerType],
          count: state.form[`event.${providerType}.count`].value as number,
        }),
      });
    } else if (
      frequency === ProviderFrequency.Everyday &&
      state.form[`event.${providerType}.providerList`]
    ) {
      state = removeControl(state, ["event", providerType, "providerList"]);
    }
    return state;
  },
  /**
   * @description  Update day value for a provider in providerList
   * -normalizes the day count based on totalDays of event
   */
  updateProviderListItemDay: (
    { updateValues },
    state,
    {
      payload: { days, providerType, index },
    }: Action<{
      index: number;
      providerType: ProviderTypes;
      days: string;
    }>
  ) => {
    const totalDays = dateHelpers.numberOfDays(
      getEventDatesFromForm(state.form)
    );
    const onlyNums = days.replace(/[^\d]|^(0*)/g, "");

    // Normalizing value so days uses total days as a max
    const value =
      parseInt(onlyNums) > totalDays ? totalDays.toString() : onlyNums;

    state = updateValues(state, {
      controlRef: ["event", providerType, "providerList", index, "days"],
      value,
    });

    return state;
  },
  /**
   * @description Sets date range for continuous event and handles date change
   */
  selectContinuousEventDates: (
    formReducers,
    state,
    { payload }: Action<{ startDate: string; endDate: string }>
  ) => {
    const { updateValues } = formReducers;
    state = updateValues(state, {
      controlRef: ["event", EventFrequency.Continuous, "eventDateRange"],
      value: payload,
    });

    state = handleDateChange(formReducers, state);

    return state;
  },
  /**
   * @description Selects dates for weekly event and handles date change
   */
  selectWeeklyEventDates: (
    formReducers,
    state,
    { payload }: Action<{ startDate: string; endDate: string }>
  ) => {
    const { updateValues } = formReducers;
    state = updateValues(state, {
      controlRef: ["event", EventFrequency.Weekly, "eventDateRange"],
      value: payload,
    });

    state = handleDateChange(formReducers, state);

    return state;
  },
  /**
   * @description Delete's a picked date from custom or weekly date selection
   * - If current frequency is weekly, switch to Custom
   */
  deleteDate: (formReducers, state, { payload }: Action<string>) => {
    const { updateValues } = formReducers;
    const { form } = state;
    const eventDates = getEventDatesFromForm(form);

    if (form["event.weekly"]) {
      state = selectEventFrequency(formReducers, state, {
        payload: EventFrequency.Custom,
      });
    }

    state = updateValues(state, {
      controlRef: ["event", "custom", "eventDates"],
      value: eventDates.filter((date) => date !== payload),
    });

    return state;
  },
};

const providerTypeLabelMap = {
  [ProviderTypes.Exhibitors]: "Exhibitor",
  [ProviderTypes.Performers]: "Performer",
  [ProviderTypes.FoodVendors]: "Vendor",
  [ProviderTypes.GoodsVendors]: "Vendor",
};

/**
 * @description Updates provider fields based on event date changes
 */
const handleDateChange = (
  { removeControl, addControl, resetControl }: FormReducers,
  state: BaseFormState<unknown>
) => {
  const duration = dateHelpers.numberOfDays(getEventDatesFromForm(state.form));

  Object.values(ProviderTypes).forEach((providerType) => {
    const frequencyRef = ["event", providerType, "frequency"];
    const countRef = ["event", providerType, "count"];
    const count = state.form[countRef.join(".")]?.value as number;

    // Frequency fields not needed for event durations less than 2 days
    if (duration < 2) {
      state = state.form[frequencyRef.join(".")]
        ? removeControl(state, frequencyRef)
        : state;
    } else if (count > 0 && count < 7) {
      // Reset provider frequency field if present, otherwise add it
      state = state.form[frequencyRef.join(".")]
        ? (state = resetControl(state, frequencyRef))
        : addControl(state, {
            controlRef: frequencyRef,
            config: providerFrequency,
          });
    }

    // Remove all previous providerLists
    const providerListRef = ["event", providerType, "providerList"];
    if (state.form[providerListRef.join(".")]) {
      state = removeControl(state, providerListRef);
    }
  });

  return state;
};
