/**
 *
 * Collection of functions for storage.
 * Uses sessionStorage, but does its own serialization and deserialization
 *
 */

/** SETTERS AND GETTERS */
function getItem(name) {
  // invalidate(name);
  return JSON.parse(sessionStorage.getItem(name));
}

function setItem(name, value) {
  // refresh(name);
  sessionStorage.setItem(name, JSON.stringify(value));
}

function removeItem(name) {
  sessionStorage.removeItem(name);
}

function clear() {
  sessionStorage.clear();
}

const Storage = {
  getItem,
  setItem,
  removeItem,
  clear,
};

export default Storage;
