import React from "react";
import {
  TextInput,
  Button,
  EmailInput,
  CheckboxInput,
} from "@jauntin/react-ui";
import { Field, FormArray } from "@reactables/react-forms";
import { RxVenueUploaderProp } from "../Rx/RxVenueUploader/RxVenueUploader";
import { useAppStore } from "@reactables/react-helpers";
import { AppState } from "Features/Shared/Rx/RxApp";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";

const EditVenueForm = ({
  rxVenueUploader: [state, actions],
}: {
  rxVenueUploader: RxVenueUploaderProp;
}) => {
  const [appState] = useAppStore<AppState>();

  if (!appState || appState.usStates.loading) return;
  const { data: usStates } = appState.usStates;

  const { form } = state;
  return (
    <>
      <div className="row">
        <div className="col">
          <Field
            name="uploadForm.editVenue.venue.name"
            label="Venue Name"
            component={TextInput}
            inputClassName="form-control-lg"
          />
        </div>
      </div>
      <AddressFieldGroup
        groupName="uploadForm.editVenue.venue.address"
        usStates={usStates}
      />
      <FormArray name="uploadForm.editVenue.venue.contacts">
        {({ items: contacts, removeControl }) => {
          return (
            <>
              {contacts.map((_, index) => (
                <div className="mb-3 d-flex align-items-center" key={index}>
                  <div>
                    <Field
                      name={`uploadForm.editVenue.venue.contacts.${index}.email`}
                      component={EmailInput}
                      label="Venue contact email (optional)"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      name={`uploadForm.editVenue.venue.contacts.${index}.copyOnEmails`}
                      component={CheckboxInput}
                      label="Copy on emails containing event coverage information"
                      inputClassName="form-control-lg"
                    ></Field>
                  </div>
                  <Button
                    onClick={() => removeControl(index)}
                    className="mx-auto btn btn-link pb-4 c-danger"
                    text="Remove"
                  />
                </div>
              ))}
              {/* Max 5 Contacts */}
              {contacts.length < 5 && (
                <div className="d-flex">
                  <Button
                    onClick={actions.form.addVenueContact}
                    text="Add Contact"
                    className="ml-auto my-3 btn btn-link"
                  />
                </div>
              )}
            </>
          );
        }}
      </FormArray>
      <div className="card">
        <div className="card-header bg-transparent d-flex align-items-center p-3">
          <strong>Additional Insured</strong>
          <Button
            onClick={actions.form.clearAdditionalInsured}
            text="Clear content"
            className="ml-auto btn btn-link"
          />
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <Field
                name="uploadForm.editVenue.venue.additionalInsured.name"
                label="Additional Insured Name"
                component={TextInput}
                inputClassName="form-control-lg"
                placeholder="e.g., city/property owner/school district"
              />
            </div>
          </div>
          <AddressFieldGroup
            groupName="uploadForm.editVenue.venue.additionalInsured.address"
            usStates={usStates}
            includeAddress2
          />
        </div>
      </div>
      <Button
        onClick={actions.form.saveVenue}
        disabled={!form["uploadForm.editVenue"].valid}
        text="Save"
        className="mt-3 w-100 btn-primary"
      />
    </>
  );
};

export default EditVenueForm;
