import { group, control, array } from "@reactables/forms";
import { EventFrequency } from "Constants/eventFrequency";
import { ProviderTypes } from "Constants/providers";

export const eventDateRange = control([
  { startDate: null, endDate: null },
  "requiredDates",
]);

export const daysOfWeekField = array({
  controls: [
    control([false]),
    control([false]),
    control([false]),
    control([false]),
    control([false]),
    control([false]),
    control([false]),
  ],
  validators: ["atLeastOneDayMustBePicked"],
});

export const continuousDatesField = group({
  controls: {
    eventDateRange,
  },
});

export const weeklyDatesField = group({
  controls: {
    eventDateRange,
    daysOfWeek: daysOfWeekField,
  },
});

export const customDatesField = group({
  controls: {
    eventDates: control([[], ["arrayNotEmpty"]]),
  },
});

export const provider = group({
  controls: {
    count: control([0]),
    // frequency added if count is between 1 and 6 inclusive
    // providerList added if frequency is 'somedays'
  },
});

export const providerFrequency = control(["", ["required"]]);

export const providerList = ({
  name,
  count,
}: {
  name: string;
  count: number;
}) =>
  array({
    validators: ["atLeastOneProviderDays", "atLeastOneProviderName"],
    controls: new Array(count)
      .fill(null)
      .map((_, index) => providerListItem(`${name} ${index + 1}`)),
  });

export const providerListItem = (performerName: string) =>
  group({
    controls: {
      name: control({
        initialValue: performerName,
        validators: ["required"],
        normalizers: ["trimLeading"],
      }),
      days: control(["", "required"]),
    },
  });

export const event = group({
  validators: ["maxAttendees"],
  controls: {
    eventName: control({
      initialValue: "",
      validators: ["required"],
      normalizers: ["normalizeEventName"],
    }),
    eventFrequencyField: control([EventFrequency.Continuous, "required"]),
    [EventFrequency.Continuous]: continuousDatesField,
    eventDailyGuests: control(["", ["required", "minValue1"]]),
    [ProviderTypes.Performers]: provider,
    [ProviderTypes.GoodsVendors]: provider,
    [ProviderTypes.FoodVendors]: provider,
    [ProviderTypes.Exhibitors]: provider,
    confirmed: control([false]),
  },
});
