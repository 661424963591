import React from "react";

const VenueCard = ({ venue }) => {
  const additionalInsuredFields = [
    venue.additionalInsured.name,
    venue.additionalInsured.address.address,
    venue.additionalInsured.address.address2,
    venue.additionalInsured.address.city,
    venue.additionalInsured.address.state,
    venue.additionalInsured.address.zip,
  ].filter((field) => Boolean(field));

  return (
    <>
      <p className="mb-3">
        {[
          venue.name,
          venue.address.address,
          venue.address.city,
          venue.address.state,
          venue.address.zip,
          "US",
        ].join(", ")}
      </p>
      {venue.contacts.map((contact, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-4">
            <div>
              <strong>Venue Contact Email</strong>
            </div>
            <div>{contact.email}</div>
          </div>
          <div className="col-8">
            <div>
              <strong>Copied on email containing coverage information</strong>
            </div>
            <div>{contact.copyOnEmails ? "Yes" : "No"}</div>
          </div>
        </div>
      ))}
      {additionalInsuredFields.length > 0 && (
        <div>
          <div>
            <strong>Additional Insured</strong>
          </div>
          <div>{additionalInsuredFields.concat("US").join(", ")}</div>
        </div>
      )}
    </>
  );
};

export default VenueCard;
