import { of } from "rxjs";
import { catchError, switchMap, map } from "rxjs/operators";
import { Reducers } from "@jauntin/reactables";
import { UsState } from "../../Models/usState.model";
import { AppState } from "../RxApp";
import StatesService from "../../../../Services/StatesService";
import { maintenanceModeCheck } from "../../../../Helpers/maintenanceModeCheck";

export const usStatesReducers = (statesService: StatesService) => ({
  fetchStates: {
    reducer: (state: AppState) => ({
      ...state,
      usStates: Reducers.load(state.usStates),
    }),
    effects: [
      (actions$) =>
        actions$.pipe(
          switchMap(() => statesService.getStates({})),
          map(({ data }: { data: UsState[] }) => ({
            type: "fetchStatesSuccess",
            payload: data,
          })),
          catchError(
            maintenanceModeCheck((error) =>
              of({ type: "fetchStatesFailure", payload: error })
            )
          )
        ),
    ],
  },
  fetchStatesSuccess: (state: AppState, action) => ({
    ...state,
    usStates: Reducers.loadSuccess(state.usStates, action),
  }),
  fetchStatesError: (state, action) => ({
    ...state,
    usStates: Reducers.loadError(state.usStates, action),
  }),
});
