import { Action } from "@reactables/core";
import { EventFrequency } from "../../../../../Constants/eventFrequency";
import {
  continuousDatesField,
  weeklyDatesField,
  customDatesField,
} from "../Configs/event.config";

/**
 * @description Selects event frequency and add appropriate date fields
 */
export const selectEventFrequency = (
  { removeControl, updateValues, addControl },
  state,
  { payload }: Action<EventFrequency>
) => {
  // Remove previous date fields
  state = removeControl(state, [
    "event",
    state.form["event.eventFrequencyField"].value as string,
  ]);

  state = updateValues(state, {
    controlRef: ["event", "eventFrequencyField"],
    value: payload,
  });

  // Add date controls based on frequency
  switch (payload) {
    case EventFrequency.Continuous:
      state = addControl(state, {
        controlRef: ["event", payload],
        config: continuousDatesField,
      });
      break;
    case EventFrequency.Weekly:
      state = addControl(state, {
        controlRef: ["event", payload],
        config: weeklyDatesField,
      });
      break;
    case EventFrequency.Custom:
      state = addControl(state, {
        controlRef: ["event", payload],
        config: customDatesField,
      });
      break;
    default:
  }

  return state;
};
