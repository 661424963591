/**
 * Takes currency number values and manipulates them, e.g. display as $100
 * Can deal with decimals or ints (ints represent smallest unit, ie cents)
 */
class CurrencyHelper {
  constructor() {
    return this;
  }

  setValue(value) {
    this.value = value;
    return this;
  }

  convertFromInt() {
    this.value = (this.value * 1.0) / 100;
    return this;
  }

  dollars() {
    // Remove decimal - just dollar
    return this.formatDollars().replace(/\.[0-9]{2}$/, "");
  }

  cents() {
    return this.formatDollars().replace(/^.*(\.[0-9]{2})$/, "$1");
  }

  formatDollars() {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(this.value);
  }

  formatAsTextInMillions() {
    const value = this.value / 10 ** 6;
    return `$${value} million`;
  }
}

export default CurrencyHelper;
