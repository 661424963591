import { of } from "rxjs";
import { Reducers, RxBuilder } from "@jauntin/reactables";
import { usStatesReducers } from "./Reducers/usStates.reducer";
import { authReducers } from "./Reducers/auth.reducer";
import StatesService from "../../../Services/StatesService";
import UserService from "../../../Services/UserService";
import API from "../../../Helpers/API";
import { UsState } from "../Models/usState.model";
import { AuthUser } from "../Models/authUser.model";

export interface AppState {
  usStates: Reducers.LoadableState<UsState[]>;
  maintenanceMode: boolean;
  auth: Reducers.LoadableState<AuthUser>;
  googleMapsLoaded: boolean;
}

const initialState: AppState = {
  usStates: Reducers.loadableInitialState,
  maintenanceMode: false,
  auth: Reducers.loadableInitialState,
  googleMapsLoaded: false,
};

const RxApp = ({
  statesService,
  userService,
}: {
  statesService: StatesService;
  userService: UserService;
}) =>
  RxBuilder({
    initialState,
    name: "rxApp",
    storeValue: true,
    sources: {
      fetchStates: of({}),
      fetchAuthUser: of({}),
    },
    reducers: {
      ...usStatesReducers(statesService),
      maintenanceModeOn: (state) => ({ ...state, maintenanceMode: true }),
      ...authReducers(userService),
      googleMapsLoaded: (state) => ({ ...state, googleMapsLoaded: true }),
    },
  });

export default RxApp({
  statesService: new StatesService(new API()),
  userService: new UserService(new API()),
});
