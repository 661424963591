import {
  SelectPlaceAddressSearchPayload,
  VenueSearchResponse,
} from "../Features/CoverageApplication/Models/venue.model";
import { ValidatorFn, Validators } from "@reactables/forms";
import { addressTypeIsForAddress } from "./AddressTypes";
import { validators } from "@jauntin/utilities";
import { DateRange } from "../Features/CoverageApplication/Models/event.model";
import { EventFrequency } from "../Constants/eventFrequency";
import { calculateEventDates } from "../Features/CoverageApplication/Rx/RxCoverageApplication/Selectors/event.selector";
import { cybersouceTestCards } from "./CyberSourceCardTypes";

const { minValue } = validators;

export const eligibility: ValidatorFn = (value: { [key: string]: string }) => ({
  eligibility: Object.values(value).some((answer) => answer === "yes"),
});

export const venueCode: ValidatorFn = (value: string) => ({
  venueCode: value && !/^\d{3,5}$/i.test(value),
});

export const facilityCode: ValidatorFn = (value: string) => ({
  facilityCode: value && !/^[a-zA-Z0-9]{3,4}$/i.test(value),
});

export const venueNameRequired: ValidatorFn = (
  value: SelectPlaceAddressSearchPayload
) => ({
  venueNameRequired: !Boolean(value?.addressComponents?.companyName),
});

export const venueAddressRequired: ValidatorFn = (
  value: SelectPlaceAddressSearchPayload
) => {
  if (!value?.addressComponents) return { venueAddressRequired: false };

  const { address1, city, state, country, zip, addressType } =
    value.addressComponents;

  return {
    venueAddressRequired: !Boolean(
      // Evaluate address type if it is supplied
      (!addressType || addressTypeIsForAddress(addressType)) &&
        address1 &&
        city &&
        state &&
        country &&
        zip
    ),
  };
};

export const atLeastOneDayMustBePicked: ValidatorFn = (
  value: [boolean, boolean, boolean, boolean, boolean, boolean, boolean]
) => ({ atLeastOneDayMustBePicked: !value.some((day) => day) });

export const requiredDates: ValidatorFn = (value: {
  startDate: string;
  endDate: string;
}) => ({
  requiredDates: !Object.values(value).every((date) => Boolean(date)),
});

export const minValue1 = (value) => ({
  minValue1: Boolean(minValue(1)(value)),
});

export const maxAttendees = (value: {
  eventDailyGuests: number;
  [EventFrequency.Continuous]?: {
    eventDateRange: DateRange;
  };
  [EventFrequency.Weekly]?: {
    daysOfWeek: Day[];
    eventDateRange: DateRange;
  };
  [EventFrequency.Custom]?: {
    eventDates: string[];
  };
}) => {
  let numberOfDays = 0;

  if (value[EventFrequency.Continuous]) {
    numberOfDays = calculateEventDates({
      eventDateRangeField: value[EventFrequency.Continuous].eventDateRange,
    }).length;
  } else if (value[EventFrequency.Weekly]) {
    numberOfDays = calculateEventDates({
      daysOfWeekField: value[EventFrequency.Weekly].daysOfWeek,
      eventDateRangeField: value[EventFrequency.Weekly].eventDateRange,
    }).length;
  } else if (value[EventFrequency.Custom]) {
    numberOfDays = value[EventFrequency.Custom].eventDates.length;
  }

  return {
    maxAttendees: numberOfDays * value.eventDailyGuests > 5000,
  };
};

// ProviderFields errors
export const atLeastOneProviderName: ValidatorFn = (
  value: { name: string; days: string }[]
) => ({
  atLeastOneProviderName: value.some(
    (item) => Validators.required(item.name).required
  ),
});

export const atLeastOneProviderDays: ValidatorFn = (
  value: { name: string; days: string }[]
) => ({
  atLeastOneProviderDays: value.some(
    (item) => Validators.required(item.days).required
  ),
});

export const validateCvv: ValidatorFn = ({
  cardNumber,
  cvv,
}: {
  cardNumber: string;
  cvv: string;
}) => {
  return {
    creditCardCvv: Boolean(
      validators.creditCardCvv(cvv, { payeeCardNum: cardNumber })
    ),
  };
};

export const validToken: ValidatorFn = (value: string) => ({
  validToken: !(value && value !== "pending" && value !== "error"),
});

export const arrayNotEmpty: ValidatorFn = (value: unknown[]) => ({
  arrayNotEmpty: Boolean(validators.arrayNotEmpty(value)),
});

export const blockedVenue: ValidatorFn = (
  value: VenueSearchResponse | null
) => ({
  blockedVenue: Boolean(value?.venue?.blockedAt),
});

export const creditCardNumber = (value) => ({
  creditCardNumber: Boolean(
    validators.creditCardNumber(
      process.env.REACT_APP_ALLOW_TEST_CARDS === "true"
        ? []
        : cybersouceTestCards
    )(value)
  ),
});

export const required = Validators.required;
