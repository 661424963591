import SelectList from "Features/Shared/Components/SelectList";
import { CompanyTypes } from "Constants/contactTypes";

const CompanyTypeField = ({ input, meta, onChange }) => (
  <div className="col-sm-6 form-group">
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Type of Company
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <SelectList
      meta={meta}
      options={[
        { value: CompanyTypes.Individual, label: "Individual" },
        { value: CompanyTypes.Corporation, label: "Corporation" },
        { value: CompanyTypes.JointVenture, label: "Joint Venture" },
        { value: CompanyTypes.Partnership, label: "Partnership" },
        { value: CompanyTypes.LLC, label: "LLC" },
        { value: CompanyTypes.Other, label: "Other" },
      ]}
      searchable={false}
      input={input}
      onChange={onChange}
    />
  </div>
);

export default CompanyTypeField;
