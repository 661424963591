import cc from "card-validator";
import { dateHelpers } from "@jauntin/utilities";
const { numberOfDays } = dateHelpers;

export const normalizeYear = (twoDigitYear) => {
  // Better method for this?
  return `20${twoDigitYear}`;
};

export const extractMonthYear = (expiryDate) => {
  return cc.expirationDate(expiryDate);
};

export const normalizeEventName = (value) => {
  const maxLength = 50;

  if (!value) return value;

  const newValue = value.slice(0, maxLength);

  return newValue;
};

export const normalizeFacilityCode = (value) => {
  if (!value) return value;

  const onlyAlphaNumeric = value.replace(/[^A-Za-z0-9]/g, "");

  return onlyAlphaNumeric.slice(0, 4).toUpperCase();
};

export const normalizeVenueCode = (value) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums.slice(0, 5);
};

export const normalizeProviderDays = (value, _, allValues) => {
  if (!value) return value;

  const totalDays = numberOfDays(allValues.eventDates);
  const onlyNums = value.replace(/[^\d]|^(0*)/g, "");

  if (onlyNums > totalDays) return totalDays.toString();

  return onlyNums;
};

export const trimLeading = (value) => {
  if (!value) return value;

  const trimText = value.replace(/^\s+/g, "");

  return trimText;
};
