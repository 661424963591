import { useEffect } from "react";
import { useAppStore } from "@reactables/react-helpers";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { Route } from "react-router-dom";
import CustomSwitch from "./Features/Shared/Components/CustomSwitch";
import Header from "Features/Shared/Components/Header";
import CoverageApplication from "./Features/CoverageApplication/Components/CoverageApplication";
import QuoteLoader from "./Features/CoverageApplication/Components/SaveQuote/QuoteLoader";
import VenueUploader from "Features/VenueUploader/Components/VenueUploader";
import "./App.scss";
import ScrollToTop from "./Features/Shared/Components/ScrollToTop";
import RefundsCancellations from "./Features/RefundsCancellations/Components/RefundsCancellations";
import SamplePolicyDocument from "./Features/SamplePolicyDocument/Components/SamplePolicyDocument";
import { AppState } from "./Features/Shared/Rx/RxApp";
import MaintenanceMode from "./Features/Shared/Components/MaintenanceMode";
import BrowserFeatures from "./Helpers/BrowserFeatures";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";

const touch = () => {
  const touchClass = BrowserFeatures.isTouch() ? "touch" : "no-touch";
  return document.body.classList.add(touchClass);
};
touch();

if (process.env.NODE_ENV === "production") {
  hotjar.initialize(
    parseInt(process.env.REACT_APP_HOTJAR_ID, 10),
    parseInt(process.env.REACT_APP_HOTJAR_SV, 10)
  );

  // Google Analytics (may be redundant once GA is handled through GTM. See index.html)
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const libraries: Libraries = ["places"];

const App = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: "en",
  });

  const [appState, appActions] = useAppStore<AppState>();

  useEffect(() => {
    if (isLoaded) {
      appActions.googleMapsLoaded();
    }
  }, [isLoaded, appActions]);

  if (!appState) return;

  if (appState.maintenanceMode) return <MaintenanceMode />;

  return (
    <>
      <CustomSwitch>
        <Route path="/sample-policy" component={SamplePolicyDocument} />
        <Route path="/refunds-cancellations" component={RefundsCancellations} />
        <Route path="/quote/:quoteId" component={QuoteLoader} />
        <Route path="/venue-uploader/:uploadId">
          <Header />
          <VenueUploader />
        </Route>
        <ScrollToTop>
          <Route path="/" component={CoverageApplication} />
        </ScrollToTop>
      </CustomSwitch>
    </>
  );
};

export default App;
