import { Field } from "@reactables/react-forms";
import { Button } from "@jauntin/react-ui";
import CurrencyHelper from "Helpers/CurrencyHelper";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import ConsentCheckField from "./ConsentCheckField";
import ExcludedActivitiesCheckField from "./ExcludedActivitiesCheckField";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as PurchaseSelectors from "../../Rx/RxCoverageApplication/Selectors/purchase.selector";
import * as ConfirmationSelectors from "../../Rx/RxCoverageApplication/Selectors/confirmation.selector";
import * as FormSelectors from "../../Rx/RxCoverageApplication/Selectors/form.selector";

const btnText = (isLoading) => (
  <>
    {isLoading && (
      <span
        className="spinner-border spinner-border-sm text-light align-middle"
        role="status"
        aria-hidden="true"
      />
    )}
    <span className="mx-2">Purchase</span>
  </>
);
const Purchase = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state, actions] = rxCoverageApplication;
  const { priceValues } = state;
  const isLoading = state.purchase.loading;
  const checkoutPageConfirmed =
    ConfirmationSelectors.getCheckoutPageConfirmed(state);
  const formValid = FormSelectors.getControl(state).valid;

  return (
    <>
      <div className="form-row mb-2 pt-2 mt-2 border-top">
        <div className="col font-weight-bold">Total</div>
        <div className="col-auto font-weight-bold">
          <DollarsAndCents
            currency={new CurrencyHelper()
              .setValue(priceValues.total)
              .convertFromInt()}
          />
        </div>
      </div>
      {checkoutPageConfirmed && (
        <div className="pt-3 mt-3">
          <Field
            name="purchase.excludedActivities"
            component={ExcludedActivitiesCheckField}
          />
          <Field component={ConsentCheckField} name="purchase.consent" />
          {state.purchase.error && (
            <div className="purchase-error">
              Your request could not be processed at this time. Please call{" "}
              <strong>(844) 747-6240</strong> or email us at{" "}
              <a
                href="mailto:gatherguard@intactinsurance.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>gatherguard@intactinsurance.com</strong>
              </a>
              .
            </div>
          )}
          <Button
            disabled={!formValid || isLoading}
            onClick={() => {
              actions.createCoverage(
                PurchaseSelectors.getCreateCoveragePayload(state)
              );
            }}
            text={btnText(isLoading)}
            className={`btn-primary d-block mt-3 w-100 btn--summary`}
          />
        </div>
      )}
    </>
  );
};

export default Purchase;
