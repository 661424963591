import { isMobile } from "react-device-detect";

export const sectionScroll = (ref, offset = 0) => {
  if (!ref || !ref.current || !isMobile) return;
  setTimeout(() => {
    const scrollToPosition = ref.current.offsetTop + offset;

    window.scrollTo({
      left: 0,
      top: scrollToPosition,
      behavior: "smooth",
    });
  }, 300);
};
