import React from "react";
import { Field } from "@reactables/react-forms";
import { ProviderTypes } from "Constants/providers";
import SelectList from "Features/Shared/Components/SelectList";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import AdditionalQuestions from "./AdditionalQuestions";
import { getProviderHasFrequency } from "Features/CoverageApplication/Rx/RxCoverageApplication/Selectors/event.selector";

const PerformersField = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state, actions] = rxCoverageApplication;

  const hasFrequency = getProviderHasFrequency(state, ProviderTypes.Performers);

  return (
    <>
      <label className="label">
        How many bands, DJs, dance troupes, or other types of performers have
        you hired for your event,{" "}
        <i>that have not provided you with proof of insurance?</i>
      </label>
      <div className="label__subtext">A band counts as one performer.</div>
      <div className="mw--130 mb-3">
        <Field
          name={`event.${ProviderTypes.Performers}.count`}
          component={SelectList}
          options={[
            { value: 0, label: "0" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7 or more" },
          ]}
          searchable={false}
          onChange={(value) => {
            actions.applicationForm.selectProviderCount({
              providerType: ProviderTypes.Performers,
              count: value,
            });
          }}
        />
      </div>

      {hasFrequency && (
        <>
          <AdditionalQuestions
            providerType={ProviderTypes.Performers}
            providerName="Performer"
            rxCoverageApplication={rxCoverageApplication}
          />
        </>
      )}
    </>
  );
};

export default PerformersField;
