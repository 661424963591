import React from "react";
import { Field } from "@reactables/react-forms";
import PopoverTrigger from "Features/Shared/Components/Popover";
import RadioInput from "Features/Shared/Components/RadioInput";

const PromoterField = ({ name }: { name: string }) => (
  <div className="form-group">
    <div className="label text-center font-weight-normal">
      <span className="mr-1 align-middle">Are you a promoter?</span>
      <PopoverTrigger content="A promoter is a company in the business of financing or organizing events for profit. One who assumes the financial responsibilities of the event." />
    </div>
    <div className="form-row justify-content-center mt-3 flex-nowrap">
      <div className="col-auto my-auto p-0">
        <div className="custom-control custom-radio">
          <Field
            name={name}
            value="yes"
            id={`${name}Yes`}
            component={RadioInput}
            type="radio"
            className="custom-control-input"
          />
          <label
            className="btn btn-outline-secondary eventSelect__radio eventSelect__radio--yes"
            htmlFor={`${name}Yes`}
          >
            Yes
          </label>
        </div>
      </div>
      <div className="col-auto my-auto p-0">
        <div className="custom-control custom-radio">
          <Field
            name={name}
            value="no"
            id={`${name}No`}
            component={RadioInput}
            type="radio"
            className="custom-control-input"
          />
          <label
            className="btn btn-outline-secondary eventSelect__radio eventSelect__radio--no"
            htmlFor={`${name}No`}
          >
            No
          </label>
        </div>
      </div>
    </div>
  </div>
);

export default PromoterField;
