import React from "react";
import { Button } from "@jauntin/react-ui";

const ButtonCollapse = ({
  clickFunction,
  showList,
  title,
}: {
  clickFunction: () => void;
  showList: boolean;
  title: string;
}) => {
  return (
    <Button
      className="btn-link p-0 font-weight-bold text-left"
      onClick={clickFunction}
      text={`${showList ? `-` : `+`} ${title}`}
    />
  );
};

export default ButtonCollapse;
