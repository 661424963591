/**
 *
 * Services provided by the onebeacon server related to logged-in User
 *
 */
class UserService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  loggedInUser = () =>
    this.api.get({
      location: `/auth/me`,
    });
}

export default UserService;
