import React from "react";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import CurrencyHelper from "Helpers/CurrencyHelper";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

const View = ({
  rxCoverageApplication: [state],
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const { liquorLiability: sellingLiquor, damageToRentedProperty } =
    FormSelectors.getRootValue(state).additionalCoverages;

  const { priceValues } = state;
  return (
    <>
      <div className="form-row mb-3">
        <div className="col-auto w--70 font-weight-bold">
          <DollarsAndCents
            currency={new CurrencyHelper().setValue(0)}
            cents={false}
          />
        </div>
        <div className="col my-auto">
          Terrorism Coverage &mdash;
          <span className="font-italic"> included</span>
        </div>
      </div>

      {/* We never show this? */}
      {!sellingLiquor && false && (
        <div className="form-row mb-3">
          <div className="col-auto w--70 font-weight-bold">
            <DollarsAndCents
              currency={new CurrencyHelper().setValue(0)}
              cents={false}
            />
          </div>
          <div className="col my-auto">Host Liquor (included)</div>
        </div>
      )}

      {sellingLiquor && (
        <div className="form-row mb-3">
          <div className="col-auto w--70 font-weight-bold">
            <DollarsAndCents
              currency={new CurrencyHelper()
                .setValue(priceValues.liquor)
                .convertFromInt()}
              cents={false}
            />
          </div>
          <div className="col my-auto">Selling Liquor (liquor liability)</div>
        </div>
      )}

      {damageToRentedProperty && (
        <div className="form-row mb-3">
          <div className="col-auto w--70 font-weight-bold">
            <DollarsAndCents
              currency={new CurrencyHelper()
                .setValue(priceValues.damageToRentedProperty)
                .convertFromInt()}
              cents={false}
            />
          </div>
          <div className="col my-auto">Damage to Rented Premises</div>
        </div>
      )}

      {!sellingLiquor && !damageToRentedProperty && (
        <div className="form-row mb-3">
          <div className="col-auto w--70 font-weight-bold">{`${" "}`}</div>
          <div className="col my-auto">No Additional Coverages selected</div>
        </div>
      )}
    </>
  );
};

export default View;
