import React from "react";
import CurrencyHelper from "Helpers/CurrencyHelper";

const DollarsAndCents = ({
  currency,
  cents = true,
}: {
  currency: CurrencyHelper;
  cents?: boolean;
}) => {
  return (
    <>
      <span>{currency.dollars()}</span>
      {cents && <span className="summary__pennies">{currency.cents()}</span>}
    </>
  );
};

export default DollarsAndCents;
