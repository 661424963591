import React from "react";
import { useLocation } from "react-router-dom";
import RouteParser from "Helpers/RouteParser";
import { Breadcrumb as IBreadcrumb } from "../../Models/breadcrumb.model";
import Breadcrumb from "./Breadcrumb";

const Breadcrumbs = ({
  disableNavigation = false,
}: {
  disableNavigation?: boolean;
}) => {
  const location = useLocation();
  const routerParser = new RouteParser(location.pathname, location.search);

  const breadcrumbs: IBreadcrumb[] = [
    {
      step: 1,
      text: "Event Type",
      link: routerParser.linkToEventTypePage(),
    },
    {
      step: 2,
      text: "Event Information",
      link: routerParser.linkToApplicationPage(),
    },
    {
      step: 3,
      text: "Insured Information",
      link: routerParser.linkToCheckoutPage(),
    },
    {
      step: 4,
      text: "Policy Documents",
      link: "/thank-you",
    },
  ];

  const currentStep =
    // Underwriting question page is part of step 1. And does not have its own breadcrumb
    breadcrumbs.find(({ link }) => location.pathname === link)?.step || 1;

  const onUnderwritingPage = !Boolean(
    breadcrumbs.find(({ link }) => location.pathname === link)
  );

  return (
    <div className="container breadcrumbs__container">
      <div className="row breadcrumbs__row">
        {breadcrumbs.map(({ step, text, link }, i) => {
          const isActive = currentStep === step && !onUnderwritingPage;
          return (
            <div key={step} className="breadcrumbs__col">
              <Breadcrumb
                {...{ step, text, link }}
                currentStep={currentStep}
                onUnderwritingPage={onUnderwritingPage}
                isActive={isActive}
                disableNavigation={disableNavigation || isActive}
                disabled={step > currentStep}
              />
              {i < breadcrumbs.length - 1 && (
                <div className="breadcrumb breadcrumb__arrow">
                  <i className="fas fa-arrow-right" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
