import { of, Observable, from, share } from "rxjs";

import { Reducers, RxBuilder } from "@jauntin/reactables";
import { Reactable, Action } from "@reactables/core";
import { ControlModels } from "@reactables/forms";

import VenuePresetsService from "Services/VenuePresetsService";
import StateTaxService from "Services/StateTaxService";
import PriceService from "Services/PriceService";
import PaymentService from "Services/PaymentService";
import CyberSourceService from "Services/CyberSourceService";
import EventTypeService from "Services/EventTypeService";
import FacilityNameService from "Services/FacilityNameService";

import { AppState } from "Features/Shared/Rx/RxApp";
import { QuoteResponse } from "Features/CoverageApplication/Models/quote.model";
import { ApplicationForm } from "Features/CoverageApplication/Models/applicationForm.model";
import {
  EventType,
  EventTypeWithIcon,
} from "Features/CoverageApplication/Models/event.model";

import { eventTypesReducers } from "./Reducers/eventTypes.reducer";
import { venueCodeFacilityNameSuccess } from "./Reducers/venueCodeFacilityNameSuccess.reducer";
import { facilityReferralSuccess } from "./Reducers/facilityReferralSuccess.reducer";
import { applicationFormChange } from "./Reducers/applicationFormChange.reducer";
import { venueCertHoldersReducers } from "./Reducers/venueCertHolders.reducer";
import { venueReferralLookup } from "./Reducers/venueReferralLookup.reducer";
import {
  downloadDocuments,
  DownloadDocumentsActions,
} from "./Reducers/downloadDocuments.reducer";
import {
  CreateCoverageActions,
  createCoverage,
} from "./Reducers/createCoverage.reducer";

import { handleFacilityReferral } from "./Operators/facility.operator";
import { mapEventTypeResponse } from "./Operators/eventType.operator";
import { formChangeToAction } from "./Operators/form.operator";
import { handleVenueReferral } from "../RxApplicationForm/Operators/venue.operator";

import {
  RxApplicationForm,
  RxApplicationFormActions,
} from "../RxApplicationForm/RxApplicationForm";
import { VenueSearchResponse } from "Features/CoverageApplication/Models/venue.model";

import { quoteVersion } from "Constants/quoteVersion";

export interface RxCoverageApplicationActions
  extends CreateCoverageActions,
    DownloadDocumentsActions {
  applicationForm: RxApplicationFormActions;
}

export interface RxCoverageApplicationState {
  applicationForm: ControlModels.Form<ApplicationForm>;
  eventTypes: {
    eventTypeDropdownOptions: {
      value: EventType | EventTypeWithIcon;
      label: string;
    }[];
  } & Reducers.LoadableState<EventType[]>;
  venueCertHolders: Reducers.LoadableState<string[]>;
  quote: Reducers.LoadableState<QuoteResponse>;
  priceValues: {
    basicCoverageAmount: number;
    liquor: number;
    damageToRentedProperty: number;
    subtotal: number;
    federalSurcharge: number;
    stateSurcharge: number;
    total: number;
  };
  referral: {
    facilityName: string;
    facilityCode: string;
    venueCode: string;
    facilityCertHolders: string[];
    venueReferral: Reducers.LoadableState<VenueSearchResponse>;
  };
  purchase: Reducers.LoadableState<{
    orderNumber: string;
    email: string;
    pdfLink: string;
  }>;
  documents: Reducers.LoadableState<void>;
  quoteVersion: number;
}

export interface RxCoverageApplicationOptions {
  referralParams?: string;
  hooks?: {
    onCreateCoverageSuccess: (successAction: Action<unknown>) => void;
    onDownloadDocumentsSuccess: (
      data: string | File | Blob | Uint8Array,
      fileName: string
    ) => void;
  };
}

export const defaultInitialState: RxCoverageApplicationState = {
  applicationForm: null,
  eventTypes: {
    ...Reducers.loadableInitialState,
    loading: true,
    eventTypeDropdownOptions: null,
  },
  venueCertHolders: Reducers.loadableInitialState,
  quote: Reducers.loadableInitialState,
  priceValues: null,
  referral: {
    facilityName: "",
    venueCode: "",
    facilityCode: "",
    facilityCertHolders: [],
    venueReferral: Reducers.loadableInitialState,
  },
  purchase: Reducers.loadableInitialState,
  documents: Reducers.loadableInitialState,
  quoteVersion,
};

export type RxCoverageApplicationProp = [
  RxCoverageApplicationState,
  RxCoverageApplicationActions,
  Observable<RxCoverageApplicationState>
];

/**
 * @description Manages State of Coverage Application including
 * - handling changes from form state (RxApplicationForm)
 * - view state of pages in the application process
 * - persisting state into session storage
 * - handles venue & facility referral parameters
 * - loading state from session storage
 */
export const RxCoverageApplication = ({
  eventTypeService,
  storage,
  venueService,
  stateTaxService,
  priceService,
  paymentService,
  cyberSourceService,
  facilityNameService,
  appState$,
  options,
}: {
  eventTypeService: EventTypeService;
  storage: {
    getItem: (name: string) => unknown;
    setItem: (name, value) => void;
  };
  venueService: VenuePresetsService;
  stateTaxService: StateTaxService;
  priceService: PriceService;
  paymentService: PaymentService;
  cyberSourceService: CyberSourceService;
  facilityNameService: FacilityNameService;
  appState$: Observable<AppState>;
  options?: RxCoverageApplicationOptions;
}): Reactable<RxCoverageApplicationState, RxCoverageApplicationActions> => {
  const storedState = storage.getItem("state") as RxCoverageApplicationState;

  const eventTypes$ = from(eventTypeService.getEventTypes()) as Observable<{
    data: EventType[];
  }>;

  const venueReferral$ = of(options?.referralParams).pipe(
    handleVenueReferral(venueService, stateTaxService),
    share()
  );

  // RxApplicationForm manages form state
  const [applicationForm$, applicationFormActions] = RxApplicationForm({
    initialState:
      storedState?.applicationForm as ControlModels.Form<ApplicationForm>,
    venueService,
    stateTaxService,
    paymentService,
    cyberSourceService,
    appState$,
    options: {
      sources: [venueReferral$],
    },
  });

  const sources = [
    venueReferral$,
    eventTypes$.pipe(mapEventTypeResponse),
    applicationForm$.pipe(formChangeToAction),
    of(options?.referralParams).pipe(
      handleFacilityReferral(facilityNameService, venueService)
    ),
  ];

  const reducers = {
    ...eventTypesReducers,
    ...applicationFormChange(priceService, facilityNameService, venueService),
    ...createCoverage(paymentService, options),
    ...downloadDocuments(paymentService, options),
    facilityReferralSuccess,
    venueCodeFacilityNameSuccess,
    ...venueCertHoldersReducers,
    ...venueReferralLookup,
  };

  const [rxState$, rxActions] = RxBuilder({
    initialState: storedState ? storedState : defaultInitialState,
    name: "rxCoverageApplication",
    storeValue: true,
    sources,
    reducers,
  });

  const state$ = rxState$;

  const actions = { ...rxActions, applicationForm: applicationFormActions };

  return [state$, actions];
};
