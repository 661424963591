import React from "react";
import { Button } from "@jauntin/react-ui";

const ButtonEdit = ({ edit, show }: { edit: () => void; show: boolean }) =>
  show && (
    <div className="col-auto my-auto">
      <Button className="btn-link btn--edit" text="Edit" onClick={edit} />
    </div>
  );

export default ButtonEdit;
