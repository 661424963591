import { Action } from "@reactables/core";
import { RxCoverageApplicationState } from "../RxCoverageApplication";

export const facilityReferralSuccess = (
  state: RxCoverageApplicationState,
  {
    payload: { facilityCode, facilityName, facilityCertHolders },
  }: Action<{
    facilityCode: string;
    facilityName: string;
    facilityCertHolders: string[];
  }>
): RxCoverageApplicationState => ({
  ...state,
  referral: {
    ...state.referral,
    facilityCode,
    facilityName,
    facilityCertHolders,
  },
});
