import { group, control, array } from "@reactables/forms";
import { Configs } from "@jauntin/reactables";
import { venueContact } from "./venueContact.config";
import { VenueListItem } from "../../../Models/venueListItem.model";

const { address: addressConfig, address2Group } = Configs;

export const editVenue = (
  { name, address, additionalInsured, contacts, googlePlaceId }: VenueListItem,
  index: number
) =>
  group({
    controls: {
      index: control([index]),
      venue: group({
        controls: {
          name: control([name, "required"]),
          address: addressConfig(address),
          contacts: array({
            controls: contacts.map((contact) => venueContact(contact)),
          }),
          googlePlaceId: control([googlePlaceId]),
          additionalInsured: group({
            controls: {
              name: control([additionalInsured.name]),
              address: group({
                controls: {
                  ...address2Group(additionalInsured.address, false).controls,
                  zip: control({
                    initialValue: additionalInsured.address.zip,
                    validators: ["facilityOtherAdditionalInsuredZipCode"],
                    normalizers: ["normalizeZipPlus4"],
                  }),
                },
              }),
            },
          }),
        },
      }),
    },
  });
