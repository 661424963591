const mapProviders = (number, everyDay, providerDays, numberOfDays) => {
  const n = Number(number);
  const d = Number(numberOfDays);
  let e = everyDay === "everyday";
  let p = [];

  if (d === 1 || [0, 7].includes(n)) {
    e = null;
  }

  if (e === false) {
    p = providerDays || [];
  }
  const out = {
    number: n,
    everyDay: e,
  };
  if (p.length) {
    out.providerDays = p;
  }
  return out;
};

export const mapProvidersForQuote = (
  number = 0,
  everyDay = null,
  providerDays = [],
  numberOfDays
) => {
  const result = mapProviders(number, everyDay, providerDays, numberOfDays);
  const n = result.number;
  let e = result.everyDay ? 1 : 0;
  let p = result.providerDays || [];

  if ([0, 7].includes(n)) {
    e = 1;
  }

  if (!e) {
    // When number is 1 to 6 and when !everyDay, providerDays should have a value
    // When days are empty or 0, use 1, because that would be the minimum price possible
    p = ((p.length && p) || [...Array(Number(n)).keys()]).map(({ days }) => ({
      name: "none",
      days: days || 1,
    }));
  }
  const out = {
    number: n,
    everyDay: e,
  };
  if (p.length) {
    out.providerDays = p;
  }
  return out;
};

export const mapProvidersForSubmission = mapProviders;
