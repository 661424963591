import React from "react";
import { Field } from "@reactables/react-forms";
import RadioInput from "Features/Shared/Components/RadioInput";
import { JointVentureTypes } from "Constants/contactTypes";

const JointVentureField = ({ name }) => (
  <div className="col-sm-6 form-group mt-auto pb-2">
    <div className="custom-control custom-radio custom-control-inline">
      <Field
        id={`${name}Corp`}
        name={name}
        className="custom-control-input"
        component={RadioInput}
        value={JointVentureTypes.JointVentureCorporation}
      />
      <label className="custom-control-label label" htmlFor={`${name}Corp`}>
        Corporation
      </label>
    </div>
    <div className="custom-control custom-radio custom-control-inline">
      <Field
        id={`${name}LLC`}
        name={name}
        className="custom-control-input"
        component={RadioInput}
        value={JointVentureTypes.JointVentureLLC}
      />
      <label className="custom-control-label label" htmlFor={`${name}LLC`}>
        LLC
      </label>
    </div>
  </div>
);

export default JointVentureField;
