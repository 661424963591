import { Action, ActionMap } from "@reactables/core";
import { normalizers, validators } from "@jauntin/utilities";
import { Observable, of, from, EMPTY } from "rxjs";
import { CustomReducers, FormReducers } from "@reactables/forms";
import {
  map,
  switchMap,
  debounceTime,
  filter,
  catchError,
} from "rxjs/operators";
import {
  normalizeVenueCode,
  normalizeFacilityCode,
} from "../../../../../Helpers/normalizers";
import {
  venueAddressRequired,
  venueNameRequired,
} from "../../../../../Helpers/validators";
import VenuePresetsService from "../../../../../Services/VenuePresetsService";
import StateTaxService from "../../../../../Services/StateTaxService";
import {
  VenueCodeSearchChangePayload,
  VenueSearchResponse,
  AdditionalCoverageTypes,
  VenueSearchResults,
  TaxLookupResults,
  SelectPlaceAddressSearchPayload,
} from "../../../Models/venue.model";
import { VenueSearchTypes } from "../../../../../Constants/venueSearchTypes";
import {
  byVenueCode,
  bySearch,
  byManualAddress,
  venueMunicipalityCode,
  taxFields,
} from "../Configs/venues.config";
import { AppState } from "../../../../Shared/Rx/RxApp";
import {
  venueCodeLookup,
  selectedPlaceVenueLookup,
  taxLookup,
} from "../Operators/venue.operator";
import {
  ApplicationForm,
  KnownVenue,
} from "Features/CoverageApplication/Models/applicationForm.model";

const { zipCode: zipCodeValidator } = validators;
const { normalizeZip } = normalizers;

export interface VenueActions extends ActionMap {
  selectVenueSearchType: (payload: VenueSearchTypes) => void;
  venueCodeSearchChange: (payload: VenueCodeSearchChangePayload) => void;
  selectPlaceAddressSearch: (payload: SelectPlaceAddressSearchPayload) => void;
  manualTaxAreaChange: (payload: { city: string; state: string }) => void;
  manualZipCodeChange: (payload: string) => void;
  clearPlaceAddressSearch: () => void;
  selectKentuckyStateEntity: (payload: "yes" | "no") => void;
}

export const venueReducers = (
  venueService: VenuePresetsService,
  stateTaxService: StateTaxService,
  appState$: Observable<AppState>
): CustomReducers<VenueActions> => ({
  /**
   * @description Selects venue search type and applies the correct search controls accordingly
   */
  selectVenueSearchType: (
    formReducers,
    state,
    { payload: searchType }: Action<VenueSearchTypes>
  ) => {
    const { updateValues, removeControl, addControl } = formReducers;

    state = removeControl(state, [
      "venue",
      state.form["venue.searchType"].value as string,
    ]);

    state = updateValues(state, {
      controlRef: ["venue", "searchType"],
      value: searchType,
    });

    state = handleVenueChange(formReducers, state);

    switch (searchType) {
      case VenueSearchTypes.ByVenueCode:
        state = addControl(state, {
          controlRef: ["venue", searchType],
          config: byVenueCode,
        });
        break;
      case VenueSearchTypes.BySearch:
        state = addControl(state, {
          controlRef: ["venue", searchType],
          config: bySearch,
        });
        break;
      case VenueSearchTypes.ByManualAddress:
        state = addControl(state, {
          controlRef: ["venue", searchType],
          config: byManualAddress,
        });
        state = updateValues(state, {
          controlRef: ["venue", "venueSearchResults", "knownVenue"],
          value: false,
        });
        break;
      default:
    }

    return state;
  },
  /**
   * @description updates venue code control and performs venue lookup
   */
  venueCodeSearchChange: {
    reducer: (
      formReducers,
      state,
      { payload }: Action<VenueCodeSearchChangePayload>
    ) => {
      const { updateValues } = formReducers;

      state = updateValues(state, {
        controlRef: payload.changedField,
        value: payload.newValue,
      });

      state = handleVenueChange(formReducers, state);

      return state;
    },
    effects: [
      (actions$: Observable<Action<VenueCodeSearchChangePayload>>) =>
        actions$.pipe(
          map(({ payload: { venueCode, facilityCode } }) => ({
            venueCode: normalizeVenueCode(venueCode),
            facilityCode: normalizeFacilityCode(facilityCode),
          })),
          debounceTime(500),
          venueCodeLookup(venueService, stateTaxService)
        ),
    ],
  },
  /**
   * @description Selects google venue and performs venue lookup
   */
  selectPlaceAddressSearch: {
    reducer: (
      formReducers,
      state,
      { payload }: Action<SelectPlaceAddressSearchPayload>
    ) => {
      const { updateValues } = formReducers;

      state = updateValues(state, {
        controlRef: ["venue", "bySearch", "selectedPlace"],
        value: payload,
      });

      state = handleVenueChange(formReducers, state);

      return state;
    },
    effects: [
      (actions$: Observable<Action<SelectPlaceAddressSearchPayload>>) =>
        actions$.pipe(
          switchMap(({ payload: selectedPlace }) => {
            return appState$.pipe(
              // Only search if selection is valid
              filter((appState) => {
                const validState = appState.usStates.data.some(
                  (usState) =>
                    usState.code === selectedPlace.addressComponents.state
                );

                return (
                  validState &&
                  !venueAddressRequired(selectedPlace).venueAddressRequired &&
                  !venueNameRequired(selectedPlace).venueNameRequired
                );
              }),
              map(() => selectedPlace),
              selectedPlaceVenueLookup(venueService, stateTaxService),
              catchError(() => of({ type: "venueLookupFailure" }))
            );
          })
        ),
    ],
  },
  /**
   * @description updates tax and/or city field form manual venue and perform taxLookup
   */
  manualTaxAreaChange: {
    reducer: (
      { updateValues, resetControl, removeControl },
      state,
      {
        payload: { city, state: usState },
      }: Action<{ city: string; state: string }>
    ) => {
      state = resetControl(state, [
        "venue",
        "venueSearchResults",
        "hasTaxLookup",
      ]);

      state = resetControl(state, [
        "venue",
        "venueSearchResults",
        "taxRegions",
      ]);

      const taxFieldsRef = ["venue", "venueSearchResults", "taxFields"];
      if (state.form[taxFieldsRef.join(".")]) {
        state = removeControl(state, taxFieldsRef);
      }

      state = updateValues(state, {
        controlRef: ["venue", "byManualAddress", "address", "city"],
        value: city,
      });

      state = updateValues(state, {
        controlRef: ["venue", "byManualAddress", "address", "state"],
        value: usState,
      });

      return state;
    },
    effects: [
      (taxAreaChange$: Observable<Action<{ city: string; state: string }>>) =>
        taxAreaChange$.pipe(
          debounceTime(500),
          map(({ payload }) => payload),
          taxLookup(stateTaxService),
          map((payload) => ({
            type: "manualTaxAreaLookupSuccess",
            payload,
          })),
          catchError(() => of({ type: "venueLookupFailure" }))
        ),
    ],
  },
  manualTaxAreaLookupSuccess: (
    { updateValues, addControl },
    state,
    { payload: { hasTaxLookup, taxRegions } }: Action<TaxLookupResults>
  ) => {
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "hasTaxLookup"],
      value: hasTaxLookup,
    });

    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "taxRegions"],
      value: taxRegions,
    });

    if (hasTaxLookup) {
      state = addControl(state, {
        controlRef: ["venue", "venueSearchResults", "taxFields"],
        config: taxFields,
      });
    }

    return state;
  },
  /** @description update manual zip code and look up utcOffset */
  manualZipCodeChange: {
    reducer: (
      { updateValues, resetControl },
      state,
      { payload }: Action<string>
    ) => {
      state = resetControl(state, ["venue", "venueSearchResults", "utcOffset"]);

      state = updateValues(state, {
        controlRef: ["venue", "byManualAddress", "address", "zip"],
        value: payload,
      });
      return state;
    },
    effects: [
      (zipCodeChange$: Observable<Action<string>>) =>
        zipCodeChange$.pipe(
          debounceTime(500),
          map(({ payload }) => normalizeZip(payload)),
          switchMap((zip) => {
            if (zipCodeValidator(zip)) {
              return EMPTY;
            }

            return from(venueService.getUtcOffsetByZip(zip)).pipe(
              map(
                ({
                  data: { utcOffsetMinutes },
                }: {
                  data: { utcOffsetMinutes: number };
                }) => ({
                  type: "manualUtcOffsetLookupSuccess",
                  payload: utcOffsetMinutes || 0,
                })
              ),
              catchError(() => of({ type: "manualUtcOffsetLookupFailure" }))
            );
          })
        ),
    ],
  },
  manualUtcOffsetLookupSuccess: (
    { updateValues },
    state,
    { payload }: Action<number>
  ) => {
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "utcOffset"],
      value: payload,
    });
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "apiError"],
      value: false,
    });

    return state;
  },
  manualUtcOffsetLookupFailure: ({ updateValues }, state) =>
    updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "utcOffset"],
      value: 0,
    }),
  clearPlaceAddressSearch: (formReducers, state) => {
    const { resetControl } = formReducers;

    if (state.form["venue.bySearch"]) {
      state = resetControl(state, ["venue", "bySearch"]);
    }

    state = handleVenueChange(formReducers, state);

    return state;
  },
  invalidVenueCode: ({ resetControl }, state) => {
    return (state = resetControl(state, ["venue", "venueSearchResults"]));
  },
  knownVenueFound: (
    { updateValues },
    state,
    action: Action<VenueSearchResponse>
  ) => {
    return updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "knownVenue"],
      value: action.payload,
    });
  },
  venueLookupSuccess,
  venueCodeNotFound: ({ updateValues }, state) => {
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults"],
      value: {
        knownVenue: false,
        hasTaxLookup: false,
        taxRegions: [],
        utcOffset: null,
      },
    });
    return state;
  },
  venueLookupFailure: ({ updateValues }, state) =>
    updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "apiError"],
      value: true,
    }),
  /**
   * @description Selects if venue is KentuckyStateEntity and adds/remove venueMunicipalityCode control accordingly
   */
  selectKentuckyStateEntity: (
    { updateValues, addControl, removeControl },
    state,
    { payload }: Action<"yes" | "no">
  ) => {
    const taxFieldRef = ["venue", "venueSearchResults", "taxFields"];
    const kentuckyStateEntityRef = taxFieldRef.concat("kentuckyStateEntity");
    const venueMunicipalityCodeRef = taxFieldRef.concat(
      "venueMunicipalityCode"
    );
    state = updateValues(state, {
      controlRef: kentuckyStateEntityRef,
      value: payload,
    });

    if (payload === "no") {
      state = addControl(state, {
        controlRef: venueMunicipalityCodeRef,
        config: venueMunicipalityCode,
      });
    } else if (
      payload === "yes" &&
      state.form[venueMunicipalityCodeRef.join(".")]
    ) {
      state = removeControl(state, venueMunicipalityCodeRef);
    }
    return state;
  },
  venueReferralLookupSuccess: {
    reducer: (formReducers, state, { payload }: Action<VenueSearchResults>) => {
      const { updateValues, markControlAsTouched } = formReducers;
      const { knownVenue } = payload;

      const {
        venue: { venueCode },
        facility: { code: facilityCode },
      } = knownVenue as VenueSearchResponse;
      state = updateValues(state, {
        controlRef: ["venue", "byVenueCode"],
        value: {
          venueCode,
          facilityCode,
        },
      });

      state = venueLookupSuccess(formReducers, state, {
        payload,
      });

      const {
        venue: {
          venueSearchResults: { hasTaxLookup },
        },
      } = state.form.root.value as ApplicationForm;

      if (!hasTaxLookup && !(knownVenue as KnownVenue).venue.blockedAt) {
        state = updateValues(state, {
          controlRef: ["venue", "confirmed"],
          value: true,
        });

        state = markControlAsTouched(state, {
          controlRef: ["venue", "confirmed"],
        });
      }

      return state;
    },
  },
});

const handleVenueChange = ({ resetControl }: FormReducers, state) => {
  state = resetControl(state, ["basicCoverage", "coverage"]);
  state = resetControl(state, ["venue", "venueSearchResults"]);
  state = resetControl(state, ["additionalCoverages"]);

  return state;
};

/**
 * @description Handles venue look up result
 * - adds tax fields if applicable
 * - For known venues
 *  - updates basic coverage
 *  - updates DRP
 */
const venueLookupSuccess = (
  { updateValues, addControl },
  state,
  { payload }: Action<VenueSearchResults>
) => {
  state = updateValues(state, {
    controlRef: ["venue", "venueSearchResults"],
    value: payload,
  });

  if (
    payload.hasTaxLookup &&
    !(payload.knownVenue as VenueSearchResponse)?.venue?.blockedAt
  ) {
    state = addControl(state, {
      controlRef: ["venue", "venueSearchResults", "taxFields"],
      config: taxFields,
    });
  }

  if (payload.knownVenue) {
    const {
      venue: { glLimits, additionalCoverage },
    } = payload.knownVenue;

    if (glLimits.length) {
      state = updateValues(state, {
        controlRef: ["basicCoverage", "coverage"],
        value: glLimits[0],
      });
    }

    const drpMandatory = additionalCoverage.find(
      (ac) => ac.type === AdditionalCoverageTypes.DamageToRentedProperty
    )?.mandatory;

    if (drpMandatory) {
      state = updateValues(state, {
        controlRef: ["additionalCoverages", "damageToRentedProperty"],
        value: true,
      });
    }

    const liquorLiabilityMandatory = additionalCoverage.find(
      (ac) => ac.type === AdditionalCoverageTypes.LiquorLiability
    )?.mandatory;

    if (liquorLiabilityMandatory) {
      state = updateValues(state, {
        controlRef: ["additionalCoverages", "liquorLiability"],
        value: true,
      });
    }
  }

  return state;
};
