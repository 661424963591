import { Action } from "@reactables/core";
import { RxCoverageApplicationState } from "../RxCoverageApplication";

export const venueCodeFacilityNameSuccess = (
  state: RxCoverageApplicationState,
  { payload }: Action<{ facilityName: string; venueCode: string }>
): RxCoverageApplicationState => ({
  ...state,
  referral: {
    ...state.referral,
    ...payload,
  },
});
