import {
  defaultNumberOfIconEventTypes,
  eventTypeCustomizations,
} from "../../../../../Constants/eventTypeCustomization";
import {
  iconEventTypes,
  listEventTypes,
} from "../../../../../Helpers/EventTypes";
import { EventType, EventTypeWithIcon } from "../../../Models/event.model";
import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { getKnownVenue } from "./venue.selector";

const getCustomizedEvents = (state: RxCoverageApplicationState) => {
  const updated = [...state.eventTypes.data];
  eventTypeCustomizations.forEach((customization) => {
    const index = updated.findIndex(
      (subject) => subject.identifier === customization.identifier
    );
    updated[index] = { ...updated[index], ...customization };
  });
  return updated;
};

export const getIconEventTypes = (state: RxCoverageApplicationState) => {
  const knownVenue = getKnownVenue(state);
  const venueEventTypes = knownVenue?.eventTypes || [];
  return iconEventTypes(
    getCustomizedEvents(state),
    venueEventTypes,
    knownVenue?.eventTileShowCount || defaultNumberOfIconEventTypes
  ) as EventTypeWithIcon[];
};

export const getListEventTypes = (state: RxCoverageApplicationState) => {
  const knownVenue = getKnownVenue(state);
  const venueEventTypes = knownVenue?.eventTypes || [];
  return listEventTypes(
    getCustomizedEvents(state),
    venueEventTypes,
    knownVenue?.eventTileShowCount || defaultNumberOfIconEventTypes
  ) as EventType[];
};

export const getSelectedEvent = (state: RxCoverageApplicationState) =>
  state.applicationForm.eventType.value as EventType;

export const getEventType = (
  state: RxCoverageApplicationState,
  identifier: string
) =>
  state.eventTypes.data?.find(
    (eventType) => eventType.identifier === identifier
  );

export const getSelectedEventIcon = (state: RxCoverageApplicationState) => {
  const selectedEvent = getSelectedEvent(state);

  const customIcon = eventTypeCustomizations.find(
    ({ identifier }) => identifier === selectedEvent.identifier
  )?.selectionIcon;

  const icon =
    selectedEvent.icon === "General" ? undefined : selectedEvent.icon;

  return customIcon || icon;
};
