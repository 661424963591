import React from "react";
import { Field } from "@reactables/react-forms";
import RadioInput from "Features/Shared/Components/RadioInput";

const ContactSameField = ({
  name,
  onChange,
  disabled,
}: {
  name: string;
  onChange?;
  disabled?: boolean;
}) => (
  <>
    <div className="font-weight-bold">Is the contact address the same as </div>
    <div className="font-weight-bold mb-3">the insured?</div>
    <div className="form-group form-row mw--455">
      <div className="col-auto my-auto">
        <div className="custom-control custom-radio custom-control-inline">
          <Field
            name={name}
            disabled={disabled}
            value="yes"
            id={`${name}Yes`}
            component={RadioInput}
            className="custom-control-input"
            onChange={onChange}
          />
          <label className="custom-control-label label" htmlFor={`${name}Yes`}>
            Yes
          </label>
        </div>
      </div>
      <div className="col-auto my-auto">
        <div className="custom-control custom-radio custom-control-inline">
          <Field
            name={name}
            value="no"
            id={`${name}No`}
            component={RadioInput}
            className="custom-control-input"
            onChange={onChange}
          />
          <label className="custom-control-label label" htmlFor={`${name}No`}>
            No
          </label>
        </div>
      </div>
    </div>
  </>
);

export default ContactSameField;
