import { group, control } from "@reactables/forms";
import { VenueSearchTypes } from "Constants/venueSearchTypes";
import { address } from "./address.config";

export const byVenueCode = group({
  controls: {
    facilityCode: control({
      initialValue: "",
      validators: ["required", "facilityCode"],
      normalizers: ["normalizeFacilityCode"],
    }),
    venueCode: control({
      initialValue: "",
      validators: ["required", "venueCode"],
      normalizers: ["normalizeVenueCode"],
    }),
  },
});

export const bySearch = group({
  controls: {
    inputText: control([{ name: "" }]),
    selectedPlace: control([
      null,
      ["venueNameRequired", "venueAddressRequired"],
      ["validateUsState"],
    ]),
  },
});

export const byManualAddress = group({
  controls: {
    venueName: control(["", "required"]),
    address,
  },
});

export const venue = group({
  controls: {
    searchType: control([VenueSearchTypes.ByVenueCode, "required"]),
    byVenueCode, // can also be bySearch or byManualAddress depending on searchType
    venueSearchResults: group({
      controls: {
        knownVenue: control([null, ["notNull", "blockedVenue"]]),
        hasTaxLookup: control([null, "notNull"]),
        taxRegions: control([null, "notNull"]),
        utcOffset: control([null, "notNull"]),
        apiError: control([false, "notTrue"]),
        // taxFields controls will be added here if applicable
      },
    }),
    confirmed: control([false]),
  },
});

export const taxFields = group({
  controls: {
    federalEntity: control(["", "required"]),
    kentuckyStateEntity: control(["", "required"]),
    // venueMunicipalityCode added here if kentuckyStateEntity is 'no'
  },
});

export const venueMunicipalityCode = control([""]);
