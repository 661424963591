import React from "react";
import { format, addDays } from "date-fns";
import { dateHelpers } from "@jauntin/utilities";

const { numberedDayArrayToString, getFirstEntry, getLastEntry } = dateHelpers;

const startEnd = (dates) => (
  <>
    <li>
      {`Effective ${format(
        getFirstEntry(dates.map((date) => new Date(date))),
        "LLLL d, yyyy"
      )}, 12:01 a.m.`}
    </li>
    <li>
      {`Expires ${format(
        addDays(getLastEntry(dates.map((date) => new Date(date))), 1),
        "LLLL d, yyyy"
      )}, 12:01 a.m.`}
    </li>
  </>
);

const weeklyVariation = (dates, frequency) => (
  <>
    <li>{`Weekly: ${numberedDayArrayToString(frequency)}`}</li>
    {startEnd(dates)}
  </>
);

const continuousVariation = (dates) => <>{startEnd(dates)}</>;

const customVariation = (dates) => (
  <>
    <li>Custom dates selected</li>
    {startEnd(dates)}
  </>
);

const Dates = ({
  dates,
  type,
  frequency,
}: {
  dates: string[];
  type: string;
  frequency: number[];
}) =>
  dates !== null &&
  dates.length &&
  ((type === "weekly" && weeklyVariation(dates, frequency)) ||
    (type === "continuous" && continuousVariation(dates)) ||
    customVariation(dates));

export default Dates;
