// Ask the server for a list of all event types
class EventTypeService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getEventTypes() {
    return this.api.get({ location: "/eventType" });
  }
}

export default EventTypeService;
