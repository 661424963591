/**
 * Takes a location and provides methods related to routes and links
 */
class RouteParser {
  _routeParts = {
    eventType: null,
    formPart: null,
    queryParams: null,
  };

  _isValid = false;

  // Get an instance based on some location
  static create(location = undefined, queryParams = undefined) {
    return new RouteParser(location, queryParams);
  }

  constructor(route = "", queryParams = "") {
    this._routeParts = this._parseRouteIntoRouteParts(route, queryParams);
  }

  static formParts = {
    eventType: "home",
    eventQuestions: "event",
    application: "application",
    checkout: "checkout",
    thankyou: "thank-you",
    refunds: "refunds-cancellations",
    policy: "sample-policy",
    quote: "quote",
  };

  // Is the supplied location valid
  isValid = () => this._isValid;

  // If there is a /{eventType}/ part of the route, what is it
  selectedEventType = () => this._routeParts.eventType;

  // If there is a /basketball/{formPart} part of the route, what is it
  selectedPage = () => this._routeParts.formPart;

  // Is the page '/'
  isEventTypePage = () =>
    this._routeParts.formPart === RouteParser.formParts.eventType;

  // Is the page /{eventType}/
  isEventQuestionsPage = () =>
    this._routeParts.formPart === RouteParser.formParts.eventQuestions;

  // Is the page part one of the applications e.g. /basketball/application
  isApplicationPage = () =>
    this._routeParts.formPart === RouteParser.formParts.application;

  // Is the page part two of the application e.g. /basketball/checkout
  isCheckoutPage = () =>
    this._routeParts.formPart === RouteParser.formParts.checkout;

  // Is the page /refunds-cancellations
  isRefundsCancellationsPage = () =>
    this._routeParts.formPart === RouteParser.formParts.refunds;

  // Is the page /sample-policy
  isSamplePolicyPage = () =>
    this._routeParts.formPart === RouteParser.formParts.policy;

  // Is the page /thank-you
  isThankyouPage = () =>
    this._routeParts.formPart === RouteParser.formParts.thankyou;

  isQuotePage = () => {
    return this._routeParts.formPart === RouteParser.formParts.quote;
  };

  // Link to home (event type selection)
  linkToEventTypePage = () => `/`;

  // Link to second event select for questions about the event type
  // Note that the event type has to be supplied in the original route
  // For example if you supply /basketball/checkout, this function will provide /basketball
  linkToEventQuestionsPage = () => `/${this._routeParts.eventType}`;

  // Link to /{eventType}/application.
  linkToApplicationPage = () =>
    `${this.linkToEventQuestionsPage()}/${RouteParser.formParts.application}`;

  // Link to /{eventType}/application.
  linkToCheckoutPage = () =>
    `${this.linkToEventQuestionsPage()}/${RouteParser.formParts.checkout}`;

  _parseRouteIntoRouteParts = (route, params) => {
    this._isValid = true;
    const queryParams = params;

    const trimmed = RouteParser.trimByChar(route, "/");
    const parts = trimmed.split("/");
    parts[0] = parts[0] === undefined ? null : parts[0];
    parts[1] = parts[1] === undefined ? null : parts[1];
    const part1 = parts[0] || null;
    const part2 = parts[1] || null;

    let eventType = null;
    let formPart = null;

    // The second part being present means we are on the main form, such as
    // /basketball/application
    if (part2 && part1 && part1 === RouteParser.formParts.quote) {
      formPart = part1;
    } else if (part2) {
      const part = RouteParser.formParts[part2] || null;
      // However, if part2 isn't a known section, it is just incorrect, like
      // /basketball/sdiofjpoij
      if (!part) {
        this._isValid = false;
      } else {
        formPart = part2;
        eventType = part1;
      }
    } else if (this._isValid && part1) {
      // There is no second part, so we are either in one of the base pages, like /thank-you
      // or in /basketball/ , which is the event questions page
      if (part1 === RouteParser.formParts.thankyou) {
        formPart = RouteParser.formParts.thankyou;
      } else if (part1 === RouteParser.formParts.refunds) {
        formPart = RouteParser.formParts.refunds;
      } else if (part1 === RouteParser.formParts.policy) {
        formPart = RouteParser.formParts.policy;
      } else {
        // Otherwise, we are at the event questions page
        formPart = RouteParser.formParts.eventQuestions;
        eventType = part1;
      }
    } else if (this._isValid) {
      // There are no parts, so we are at home.
      formPart = RouteParser.formParts.eventType;
    }

    return {
      eventType,
      formPart,
      queryParams,
    };
  };

  static trimByChar(string, character) {
    let s = string;
    while (s.charAt(0) === character) s = s.substring(1);
    while (s.charAt(s.length - 1) === character)
      s = s.substring(0, s.length - 1);
    return s;
  }
}

export default RouteParser;
