import React from "react";
import { Field } from "@reactables/react-forms";
import { ProviderTypes } from "Constants/providers";
import SelectList from "Features/Shared/Components/SelectList";
import AdditionalQuestions from "./AdditionalQuestions";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import { getProviderHasFrequency } from "Features/CoverageApplication/Rx/RxCoverageApplication/Selectors/event.selector";

const ExhibitorsField = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state, actions] = rxCoverageApplication;

  const hasFrequency = getProviderHasFrequency(state, ProviderTypes.Exhibitors);

  return (
    <>
      <label className="label">
        How many exhibitors will be at your event,{" "}
        <i>that have not provided you with proof of insurance?</i>
      </label>
      <div className="label__subtext">
        Exhibitors provide information, promotions, demos or giveaways. For
        example, people running information or registration booths. A single
        booth is considered one exhibitor regardless of the number of persons
        operating the booth.
      </div>
      <div className="mw--130 mb-3">
        <Field
          name={`event.${ProviderTypes.Exhibitors}.count`}
          component={SelectList}
          options={[
            { value: 0, label: "0" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7 or more" },
          ]}
          searchable={false}
          onChange={(value) => {
            actions.applicationForm.selectProviderCount({
              providerType: ProviderTypes.Exhibitors,
              count: value,
            });
          }}
        />
      </div>

      {hasFrequency && (
        <AdditionalQuestions
          rxCoverageApplication={rxCoverageApplication}
          providerType={ProviderTypes.Exhibitors}
          providerName="Exhibitor"
        />
      )}
    </>
  );
};

export default ExhibitorsField;
