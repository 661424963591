import React from "react";
import { Field, Form } from "@reactables/react-forms";
import { apiUrl } from "../../../Constants/apiUrl";
import { StateField } from "@jauntin/react-ui";
import { useAppStore, useReactable } from "@reactables/react-helpers";
import { AppState } from "../../Shared/Rx/RxApp";
import RxSamplePolicyDocument from "../Rx/RxSamplePolicyDocument";
import customStyles from "../../../Helpers/ReactSelectStyle";

const SamplePolicyDocument = () => {
  const [appState] = useAppStore<AppState>();
  const rxForm = useReactable(RxSamplePolicyDocument);
  const [state, actions] = rxForm;

  if (!appState?.usStates.data?.length || !state) return;

  return (
    <>
      <div className="samplePolicy">
        <div className="row mr-0">
          <div className="col-lg-8 my-5">
            <div className="card samplePolicy__card">
              <div className="card-body pb-2">
                <h5 className="samplePolicy__content">
                  Insured residence location
                </h5>

                <div className="row">
                  <div className="px-0 col col-md-6">
                    <Form rxForm={[state, actions]}>
                      <Field
                        component={StateField}
                        usStates={appState.usStates.data}
                        customStyles={customStyles}
                      />
                    </Form>
                  </div>
                </div>

                {state.root.value && (
                  <>
                    <hr className="samplePolicy__border" />
                    <h5 className="my-2 samplePolicy__content">
                      View or download policy form
                    </h5>
                    <a
                      href={`${apiUrl}sample-policy-documents/${state.root.value}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-flex">
                        <div>
                          <i className="far fa-file-pdf" />
                        </div>
                        <div className="ml-2 samplePolicy__link">
                          Basic Coverage Specimen GatherGuard Event Insurance
                          Policy
                        </div>
                      </div>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SamplePolicyDocument;
