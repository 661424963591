import React from "react";
import { TextInput, StateField } from "@jauntin/react-ui";
import { Field } from "@reactables/react-forms";
import { UsState } from "../Models/usState.model";
import customStyles from "Helpers/ReactSelectStyle";

const AddressFieldGroup = ({
  groupName,
  usStates,
  disabled,
  onAddressChange,
  onAddress2Change,
  onCityChange,
  onStateChange,
  onZipChange,
  includeAddress2 = false,
}: {
  groupName: string;
  usStates: UsState[];
  disabled?: boolean;
  onAddressChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddress2Change?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCityChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onStateChange?: (value: string) => void;
  onZipChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  includeAddress2?: boolean;
}) => (
  <>
    <Field
      component={TextInput}
      name={`${groupName}.address`}
      label="Street Address"
      ariaLabel="Address"
      placeholder="Address"
      inputClassName="form-control-lg"
      subtext="Mailing address, including apartment, suite, unit, building, floor, etc.."
      onChange={onAddressChange}
      disabled={disabled}
    />
    {includeAddress2 && (
      <Field
        component={TextInput}
        name={`${groupName}.address2`}
        inputClassName="form-control-lg"
        onChange={onAddress2Change}
        disabled={disabled}
      />
    )}

    <div className="form-row">
      <Field
        component={TextInput}
        name={`${groupName}.city`}
        label="City"
        ariaLabel="City"
        placeholder="City"
        wrapperClassName="col-sm"
        inputClassName="form-control-lg"
        onChange={onCityChange}
        disabled={disabled}
      />
      <Field
        component={StateField}
        name={`${groupName}.state`}
        usStates={usStates}
        customStyles={customStyles}
        onChange={onStateChange}
        disabled={disabled}
        selectClassName="select-input"
      />
      <Field
        component={TextInput}
        name={`${groupName}.zip`}
        label="Zipcode"
        ariaLabel="Zipcode"
        placeholder="Zipcode"
        pattern="\d*"
        inputMode="numeric"
        wrapperClassName="col-sm"
        inputClassName="form-control-lg"
        onChange={onZipChange}
        disabled={disabled}
      />
    </div>
  </>
);

export default AddressFieldGroup;
