import {
  subMinutes,
  startOfDay,
  addDays,
  subDays,
  isWithinInterval,
} from "date-fns";
import { dateHelpers } from "@jauntin/utilities";

const { getFirstEntry, getLastEntry } = dateHelpers;

const firstValidDay = (today) => addDays(today, 1);
const lastValidDay = (today) => addDays(today, 548);
const dayIsInOverallValidRange = (day, start) => {
  const dayMidnight = startOfDay(day);
  const startMidnight = startOfDay(start);

  return isWithinInterval(dayMidnight, {
    start: firstValidDay(startMidnight),
    end: lastValidDay(startMidnight),
  });
};
const dayIsValidForStartAndEndDate = (day, start, end) => {
  if (!start) {
    return true;
  }

  let valid = isWithinInterval(day, { start, end: addDays(start, 89) });
  if (end) {
    valid = valid || isWithinInterval(day, { start: subDays(end, 89), end });
  }
  return valid;
};

const disabledDatesForDateRangePicker =
  ({ venueUtcOffset, blockedDays, startDate }) =>
  (focusedInput) =>
  (day) => {
    if (blockedDays?.includes(day.getDay())) return true;

    // Set available dates based on venue location date-time
    const utcOffsetDiff = new Date().getTimezoneOffset() * -1 - venueUtcOffset;
    const venueCurrentDateTime = subMinutes(new Date(), utcOffsetDiff);

    if (focusedInput === "startDate") {
      return !dayIsInOverallValidRange(day, venueCurrentDateTime);
    }

    if (focusedInput === "endDate") {
      return (
        !dayIsInOverallValidRange(day, venueCurrentDateTime) ||
        !dayIsValidForStartAndEndDate(day, startDate)
      );
    }

    return false;
  };

const disabledDatesForCustomDatePicker =
  ({ venueUtcOffset, eventDates }) =>
  (day) => {
    // Set available dates based on venue location date-time
    const utcOffsetDiff = new Date().getTimezoneOffset() * -1 - venueUtcOffset;
    const venueCurrentDateTime = subMinutes(new Date(), utcOffsetDiff);
    const selectedDates = eventDates.map((date) => new Date(date));

    return (
      !dayIsInOverallValidRange(day, venueCurrentDateTime) ||
      !dayIsValidForStartAndEndDate(
        day,
        getFirstEntry(selectedDates),
        getLastEntry(selectedDates)
      )
    );
  };

export {
  dayIsInOverallValidRange,
  dayIsValidForStartAndEndDate,
  disabledDatesForDateRangePicker,
  disabledDatesForCustomDatePicker,
};
