import { validatorFns } from "@jauntin/reactables";
import { RxFormProviders } from "@reactables/forms";
import { normalizers } from "@jauntin/utilities";
import * as GGValidators from "./validators";
import * as Normalizers from "./normalizers";

const formProviders: RxFormProviders = {
  validators: {
    ...validatorFns(),
    ...GGValidators,
  },
  normalizers: { ...normalizers, ...Normalizers },
};

export default formProviders;
