import { OperatorFunction } from "rxjs";
import { map } from "rxjs/operators";

export const mapCertHolders: OperatorFunction<
  {
    data: {
      bottomLeft: string;
      bottomRight: string;
      topLeft: string;
      topRight: string;
    };
  },
  string[]
> = (obs$) =>
  obs$.pipe(
    map(({ data }) =>
      [data.topLeft, data.topRight, data.bottomLeft].filter((e) => !!e)
    )
  );
