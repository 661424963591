import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button } from "@jauntin/react-ui";
import RouteParser from "Helpers/RouteParser";
import { Card, Alert } from "react-bootstrap";
import LossClaimsField from "./LossClaimsField";
import PromoterField from "./PromoterField";
import SecurityField from "./SecurityField";
import Breadcrumbs from "../BreadCrumbs/Breadcrumbs";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";

import * as EventTypesSelectors from "../../Rx/RxCoverageApplication/Selectors/eventTypes.selector";
import * as FormSelectors from "../../Rx/RxCoverageApplication/Selectors/form.selector";

const UnderwritingQuestions = ({
  rxCoverageApplication: [state, actions],
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const location = useLocation();

  const selectedEvent = EventTypesSelectors.getSelectedEvent(state);

  // If browser loads on this page we will select even by eventType path parameter
  const { eventType: eventTypeId } = useParams() as { eventType: string };

  useEffect(() => {
    if (!selectedEvent) {
      const eventType = EventTypesSelectors.getEventType(state, eventTypeId);
      eventType && actions.applicationForm.selectEventTypeIfNeeded(eventType);
    }
  }, [selectedEvent, eventTypeId, state, actions]);

  if (!selectedEvent) return;

  const {
    underwritingQuestions: { promoter, security, lossClaims },
  } = FormSelectors.getRootValue(state);

  const underwritingQuestionsControl = FormSelectors.getControl(
    state,
    "underwritingQuestions"
  );

  return (
    <>
      <Breadcrumbs />
      <div className="eventSelect d-flex justify-content-center">
        <div className="eventSelect card--width-lg card--main">
          <Card.Header className="border-0">
            <div className="text-center w-100">
              <h1>
                Get your{" "}
                <span className="text-lowercase">
                  {selectedEvent.headerName}
                </span>{" "}
                insurance quote.
              </h1>
              {selectedEvent.subtitle && (
                <div className="h7 pt-2">{selectedEvent.subtitle}</div>
              )}
              <p>{selectedEvent.eventCoverageNote}</p>
            </div>
          </Card.Header>
          <Card.Body>
            <>
              <Alert
                show={underwritingQuestionsControl.errors.eligibility}
                variant="primary"
                className="mw--635 mx-auto"
              >
                <i className="fal fa-info-circle alert__icon" />
                <div className="alert__text">
                  Unfortunately, we cannot provide a policy from our online
                  special event insurance program given the details provided.
                  For more information, please call{" "}
                  <span className="text-nowrap">(844) 747-6240</span>.
                </div>
              </Alert>
              {lossClaims !== undefined && (
                <LossClaimsField name="underwritingQuestions.lossClaims" />
              )}
              {security !== undefined && (
                <SecurityField name="underwritingQuestions.security" />
              )}
              {promoter !== undefined && (
                <PromoterField name="underwritingQuestions.promoter" />
              )}
              <div className="row justify-content-center mt-5">
                <div className="col-auto">
                  <Link to={new RouteParser().linkToEventTypePage()}>
                    <Button
                      onClick={() => undefined}
                      text="Back"
                      className="btn-link"
                    />
                  </Link>
                </div>
                <div className="col-auto">
                  <Link
                    to={RouteParser.create(
                      location.pathname
                    ).linkToApplicationPage()}
                  >
                    <Button
                      onClick={() => undefined}
                      disabled={!underwritingQuestionsControl.valid}
                      text="Continue"
                      className="btn-primary"
                    />
                  </Link>
                </div>
              </div>
            </>
          </Card.Body>
        </div>
      </div>
    </>
  );
};

export default UnderwritingQuestions;
