import React from "react";
import { Field } from "@reactables/react-forms";
import PopoverTrigger from "Features/Shared/Components/Popover";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import VenueCodeField from "./VenueCodeField";
import * as VenueSelectors from "../../../Rx/RxCoverageApplication/Selectors/venue.selector";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

const SearchByVenueCode = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state, actions] = rxCoverageApplication;

  const venueNotFound = VenueSelectors.getVenueNotFound(state);
  const isCheckingVenueCode = VenueSelectors.getIsCheckingVenueCode(state);
  const {
    venue: {
      byVenueCode: { venueCode, facilityCode },
    },
  } = FormSelectors.getRootValue(state);

  return (
    <>
      <div className="d-flex align-items-center">
        <span
          className={
            venueNotFound ? "label form-error__label my-auto" : "label my-auto"
          }
        >
          Venue code
        </span>
        <PopoverTrigger content="Some participating venues provide a short code to identify their location for the event holders who are renting their facility. The code can usually be found on their website, rental brochures or through the location manager who facilitates the rental contracts." />
      </div>
      {venueNotFound && (
        <p className="form-error">
          The venue location for that code could not be found.
        </p>
      )}
      <div className="d-flex align-items-center form-group">
        <Field
          component={VenueCodeField}
          name="venue.byVenueCode.facilityCode"
          className={`form-control form-control-lg text-center form-control--mw-100  ${
            venueNotFound && "venue-code-form-error"
          }`}
          onChange={(e) => {
            actions.applicationForm.venueCodeSearchChange({
              facilityCode: e.target.value,
              venueCode,
              changedField: ["venue", "byVenueCode", "facilityCode"],
              newValue: e.target.value,
            });
          }}
        />
        <span className="col-auto text-center">&mdash;</span>
        <Field
          component={VenueCodeField}
          name="venue.byVenueCode.venueCode"
          className={`form-control form-control-lg text-center form-control--mw-100 ${
            venueNotFound && "venue-code-form-error"
          }`}
          onChange={(e) => {
            actions.applicationForm.venueCodeSearchChange({
              venueCode: e.target.value,
              facilityCode,
              changedField: ["venue", "byVenueCode", "venueCode"],
              newValue: e.target.value,
            });
          }}
        />
      </div>
      {isCheckingVenueCode && <div>Checking venue code..</div>}
    </>
  );
};

export default SearchByVenueCode;
