import { Action, Reactable } from "@reactables/core";
import { RxBuilder } from "@jauntin/reactables";
import { fromEvent, combineLatest, Observable, merge } from "rxjs";
import { map, debounceTime, filter, tap } from "rxjs/operators";
import { RxToggle, RxToggleActions } from "@jauntin/reactables";
import { RxCoverageApplicationState } from "../RxCoverageApplication/RxCoverageApplication";
import { formValueChange } from "../RxCoverageApplication/Operators/form.operator";

interface RxSummaryState {
  showSaveQuoteModal: boolean;
  summaryTableOpen: boolean;
  hideSummaryTable: boolean;
  scrollPosition: number;
}

interface RxSummaryActions {
  toggleSummaryTableOpen: () => void;
  closeSummaryTable: () => void;
  saveQuoteModalToggle: RxToggleActions;
}

/**
 * @description Manages View State of Summary Section
 * - toggle state of save quote modal
 * - show and hide the summary section in mobile view
 * - open and closed state of the summary table
 */
const RxSummary = ({
  coverageApplicationState$,
  onAcConfirmed,
  onPaymentConfirmed,
}: {
  coverageApplicationState$: Observable<RxCoverageApplicationState>;
  onAcConfirmed: () => void;
  onPaymentConfirmed: () => void;
}): Reactable<RxSummaryState, RxSummaryActions> => {
  const [showSaveQuoteModal$, saveQuoteModalToggle] = RxToggle();

  const acOrPaymentConfirmed$ = merge(
    coverageApplicationState$.pipe(
      formValueChange("additionalCoverages.confirmed"),
      filter((confirmed: boolean) => confirmed),
      tap(() => {
        onAcConfirmed && onAcConfirmed();
      })
    ),
    coverageApplicationState$.pipe(
      formValueChange("payment.confirmed"),
      filter((confirmed: boolean) => confirmed),
      tap(() => {
        onPaymentConfirmed && onPaymentConfirmed();
      })
    )
  ).pipe(map(() => undefined));

  const [rxSummary$, rxSummaryActions] = RxBuilder({
    name: "rxSummary",
    initialState: {
      summaryTableOpen: false,
      hideSummaryTable: false,
      scrollPosition: window.scrollY,
    },
    sources: {
      scrollChange: fromEvent(window, "scroll").pipe(debounceTime(300)),
      showSummaryTable: acOrPaymentConfirmed$,
    },
    reducers: {
      summaryTableOpenChange: (state, { payload }: Action<boolean>) => {
        return {
          ...state,
          summaryTableOpen: payload,
        };
      },
      showSummaryTable: (state) => ({
        ...state,
        hideSummaryTable: false,
        summaryTableOpen: true,
      }),
      toggleSummaryTableOpen: (state) => ({
        ...state,
        summaryTableOpen: !state.summaryTableOpen,
      }),
      closeSummaryTable: (state) => ({
        ...state,
        summaryTableOpen: false,
      }),
      scrollChange: (state, { payload }: Action<Event>) => {
        const yPos = (payload.target as Document).scrollingElement.scrollTop;
        const distance = yPos - state.scrollPosition;
        const { scrollPosition, hideSummaryTable } = state;

        let newHideSummaryTable: boolean = state.hideSummaryTable;

        if (yPos === null || yPos === 0) {
          newHideSummaryTable = false;
        } else if (scrollPosition !== yPos && !state.summaryTableOpen) {
          if (distance > 10 && !hideSummaryTable) {
            // Down, hide summary
            newHideSummaryTable = true;
          } else if ((distance < -20 || yPos < 100) && hideSummaryTable) {
            // Up, show summary
            newHideSummaryTable = false;
          }
        }

        return {
          ...state,
          scrollPosition: yPos,
          hideSummaryTable: newHideSummaryTable,
        };
      },
    },
  });

  const state$ = combineLatest([showSaveQuoteModal$, rxSummary$]).pipe(
    map(([showSaveQuoteModal, rxSummary]) => ({
      showSaveQuoteModal,
      ...rxSummary,
    }))
  );

  const actions = {
    saveQuoteModalToggle,
    toggleSummaryTableOpen: rxSummaryActions.toggleSummaryTableOpen,
    closeSummaryTable: rxSummaryActions.closeSummaryTable,
  };

  return [state$, actions];
};

export default RxSummary;
