import { group, control } from "@reactables/forms";

export const uploadForm = group({
  validators: ["notEditing"],
  controls: {
    contact: group({
      controls: {
        firstAndLastName: control(["", "required"]),
        jobTitle: control([""]),
        email: control(["", ["required", "email"]]),
        phoneNumber: control({
          initialValue: "",
          validators: ["required", "phoneNumber"],
          normalizers: ["normalizePhone"],
        }),
      },
    }),
    defaultContact: group({
      controls: {
        email: control(["", ["email"]]),
        copyOnEmails: control([false]),
      },
    }),
    venueList: control([[], "arrayNotEmpty"]),
    // editVenue form here if user is editting a venue list item
  },
});
