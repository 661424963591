import { ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { Configs } from "@jauntin/reactables";
import { AddVenueSelection } from "../../../Models/addVenue.model";
import { VenueListItem } from "../../../Models/venueListItem.model";
import {
  manualVenueConfig,
  googleVenueConfig,
} from "../Configs/addVenueForm.config";

const { emptyAddress } = Configs;

export interface AddVenueFormActions extends ActionMap {
  addVenue: () => void;
  toggleSearchType: () => void;
  clearGoogleSearch: () => void;
}

export const addVenueReducers: CustomReducers<AddVenueFormActions> = {
  addVenue: (
    { updateValues, resetControl }, // Built in FormReducers provided by RxForms
    /**
     * Use these reducers to update form state to maintain the integrity of
     * the form tree (i.e validation states of parent and children controls).
     */
    state
  ) => {
    const { form } = state;
    const venue = form.addVenueForm.value as AddVenueSelection;

    let venueListItem: VenueListItem;

    if (venue.googleVenue) {
      const { address1, city, state, zip, companyName } =
        venue.googleVenue.address;

      const address = {
        address: address1,
        city,
        state,
        zip,
      };

      venueListItem = {
        name: companyName,
        address,
        contacts: [],
        additionalInsured: {
          name: "",
          address: { ...emptyAddress, address2: "" },
        },
        googlePlaceId: venue.googleVenue.placeId,
      };
    } else {
      const { name, address } = venue.manualVenue;
      venueListItem = {
        name: name,
        address,
        contacts: [],
        additionalInsured: {
          name: "",
          address: { ...emptyAddress, address2: "" },
        },
        googlePlaceId: null,
      };
    }

    const venueListRef = ["uploadForm", "venueList"];

    state = updateValues(state, {
      controlRef: venueListRef,
      value: (form[venueListRef.join(".")].value as VenueListItem[]).concat(
        venueListItem
      ),
    });

    state = resetControl(state, ["addVenueForm"]);

    return state;
  },
  toggleSearchType: ({ addControl, removeControl }, state) => {
    const { form } = state;

    const googleVenueRef = ["addVenueForm", "googleVenue"];
    const manualVenueRef = ["addVenueForm", "manualVenue"];
    const isGoogleSearchOn = Boolean(form[googleVenueRef.join(".")]);

    state = removeControl(
      state,
      isGoogleSearchOn ? googleVenueRef : manualVenueRef
    );

    state = addControl(state, {
      controlRef: isGoogleSearchOn ? manualVenueRef : googleVenueRef,
      config: isGoogleSearchOn ? manualVenueConfig : googleVenueConfig,
    });

    return state;
  },
  clearGoogleSearch: ({ resetControl }, state) => {
    return resetControl(state, ["addVenueForm", "googleVenue"]);
  },
};
