import { Observable, of } from "rxjs";
import { tap, map, switchMap } from "rxjs/operators";
import { resetFormControls, RxBuilder } from "@jauntin/reactables";
import { Action } from "@reactables/core";
import { RxCoverageApplicationState } from "../RxCoverageApplication/RxCoverageApplication";
import formProviders from "../../../../Helpers/formProviders";

/**
 * @description Receives state updates from RxCoverageApplication,
 * clears sensitive information and stores state in session storage
 */
const RxStorageEffect = ({
  storage,
  rxCoverageApplicationState$,
}: {
  storage: { setItem: (name: string, value: unknown) => void };
  rxCoverageApplicationState$: Observable<RxCoverageApplicationState>;
}) => {
  return RxBuilder({
    initialState: null,
    name: "rxStorageEffect",
    sources: {
      stateChange: rxCoverageApplicationState$,
    },
    reducers: {
      stateChange: {
        reducer: (state) => state,
        effects: [
          (state$: Observable<Action<RxCoverageApplicationState>>) =>
            state$.pipe(
              switchMap(({ payload: state }) => {
                return of(state.applicationForm).pipe(
                  resetFormControls([["payment"], ["purchase"]], formProviders),
                  map((rxFormState) => {
                    return {
                      payload: {
                        ...state,
                        applicationForm: rxFormState,
                      },
                    };
                  })
                );
              }),
              tap(({ payload }) => {
                storage.setItem("state", payload);
              })
            ),
        ],
      },
    },
  });
};

export default RxStorageEffect;
