import { Reducers } from "@jauntin/reactables";
import { RxCoverageApplicationState } from "../RxCoverageApplication";
import eventSelectList from "Helpers/EventSelectList";
import { getListEventTypes } from "../Selectors/eventTypes.selector";
import { EventType } from "Features/CoverageApplication/Models/event.model";

export const eventTypesReducers = {
  fetchEventTypes: (state: RxCoverageApplicationState) => ({
    ...state,
    eventTypes: Reducers.load(state.eventTypes),
  }),
  fetchEventTypesSuccess: (
    state: RxCoverageApplicationState,
    action
  ): RxCoverageApplicationState => {
    state = {
      ...state,
      eventTypes: Reducers.loadSuccess(state.eventTypes, action),
    };

    return {
      ...state,
      eventTypes: {
        ...state.eventTypes,
        eventTypeDropdownOptions: eventSelectList(getListEventTypes(state)).map(
          (eventType: EventType) => ({
            value: eventType,
            label: eventType.name,
          })
        ),
      },
    };
  },
  fetchEventTypesError: (state: RxCoverageApplicationState, action) => ({
    ...state,
    eventTypes: Reducers.loadError(state.eventTypes, action),
  }),
};
