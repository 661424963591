import { ControlModels, Field } from "@reactables/react-forms";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";
import { mapExtendedErrors } from "@jauntin/utilities";
import SelectList from "Features/Shared/Components/SelectList";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import EventRadioBtn from "./EventRadioBtn";
import { getControl } from "Features/CoverageApplication/Rx/RxCoverageApplication/Selectors/form.selector";
import * as EventTypeSelectors from "../../Rx/RxCoverageApplication/Selectors/eventTypes.selector";
import { EventType } from "Features/CoverageApplication/Models/event.model";

const EventSelect = ({
  rxCoverageApplication: [state, actions],
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const {
    value: selectedEvent,
    error,
    touched,
  } = mapExtendedErrors(getControl(state, "eventType")) as {
    error: string;
  } & ControlModels.FormControl<EventType>;

  return (
    <div className="eventSelect d-flex justify-content-center">
      <div className="eventSelect card--width-lg card--main">
        {state.referral.venueReferral.loading ? (
          <div className="d-flex align-items-center justify-content-center mh--hf container">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <>
            <Card.Header className="border-0">
              <div className="text-center w-100">
                <h1>Select your event.</h1>
                <p className="lead">
                  If you do not see your event category,
                  <br />
                  please call (844) 747-6240 to confirm event eligibility.
                </p>
              </div>
            </Card.Header>
            <Card.Body>
              <h4 className="text-center d-none d-sm-block">
                Most popular event types:
              </h4>
              <div className="form-group form-row">
                {EventTypeSelectors.getIconEventTypes(state).map((type) => (
                  <EventRadioBtn
                    icon={type.selectionIcon}
                    eventTypeRadio={type}
                    key={type.identifier}
                    text={type.name}
                    action={actions.applicationForm.selectEventType}
                  />
                ))}
              </div>
              <div className="form-group">
                <div className="mt-4">
                  <label
                    htmlFor="eventTypeSelect"
                    className={`h4 mb-4 text-center ${
                      touched && error ? "form-error__label" : ""
                    }`}
                  >
                    Other event types
                  </label>
                  {touched && error && (
                    <div className="form-error">{error}</div>
                  )}
                  <Field
                    component={SelectList}
                    name="eventType"
                    options={state.eventTypes.eventTypeDropdownOptions}
                    onChange={actions.applicationForm.selectEventType}
                    placeholder="Select from the list"
                    searchable
                  />
                </div>
              </div>
              {selectedEvent && (
                <div className="text-right">
                  <Link to={`/${selectedEvent.identifier}`}>
                    <Button
                      onClick={() => undefined}
                      text="Continue"
                      className="btn-primary"
                    />
                  </Link>
                </div>
              )}
            </Card.Body>
          </>
        )}
      </div>
    </div>
  );
};

export default EventSelect;
