import React from "react";
import { mapExtendedErrors } from "@jauntin/utilities";

const CardExpField = ({ input, meta, disabled }) => {
  meta = mapExtendedErrors(meta, {
    messages: {
      creditCardDate: "Invalid expiration date",
    },
  });

  return (
    <div className="col-auto d-flex flex-column">
      <label
        htmlFor={input.name}
        className={
          meta.touched && meta.error ? "label form-error__label" : "label"
        }
      >
        Expiration Date
      </label>
      {meta.touched && meta.error && (
        <div className="form-error mt-2 form-group--order-4">{meta.error}</div>
      )}
      <input
        {...input}
        id={input.name}
        className="form-control form-control-lg mw--130"
        aria-label="Credit Card expiration"
        placeholder="MM / YY"
        type="tel"
        disabled={disabled}
      />
    </div>
  );
};

export default CardExpField;
