class VenueUploadService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  loadFacilityInfo(id) {
    return this.api.get({
      location: `/venueUploader/facilityInfo/${id}`,
    });
  }

  uploadVenues(id, payload) {
    return this.api.post({
      location: `/venueUploader/upload/${id}`,
      body: payload,
    });
  }
}

export default VenueUploadService;
