import { Action, ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { normalizers } from "@jauntin/utilities";
import { of } from "rxjs";
import { switchMap, mergeMap, map, catchError } from "rxjs/operators";
import { ContactType } from "../../../../../Constants/contactTypes";
import PaymentService from "../../../../../Services/PaymentService";
import CyberSourceService from "../../../../../Services/CyberSourceService";
import { Observable, from } from "rxjs";
import { NormalizedCreditCard } from "../../../Models/payment.model";
import { sameAddress } from "./sameAddress.reducer";
import { contactType } from "./contactType.reducer";

export interface PaymentActions extends ActionMap {
  selectSameAddressAsContact: (payload: "yes" | "no") => void;
  selectDifferentPaymentAddressContactType: (payload: ContactType) => void;
  cvvChange: (payload: string) => void;
  confirmCreditCard: (payload: NormalizedCreditCard) => void;
}

export const paymentReducers = (
  paymentService: PaymentService,
  cyberSourceService: CyberSourceService
): CustomReducers<PaymentActions> => ({
  selectSameAddressAsContact: sameAddress(
    ["payment", "billing"],
    "sameAddressAsContact"
  ),
  selectDifferentPaymentAddressContactType: contactType([
    "payment",
    "billing",
    "differentAddressFields",
  ]),
  /**
   * @description normalize and update cvv
   */
  cvvChange: ({ updateValues }, state, { payload }: Action<string>) => {
    const value = normalizers.normalizeCVV(payload, undefined, {
      payeeCardNum: state.form["payment.billing.creditCard.cardNumber"]
        .value as string,
    });
    return updateValues(state, {
      controlRef: ["payment", "billing", "creditCard", "cvv"],
      value,
    });
  },
  /**
   * @description obtains a cybersource token for payment
   */
  confirmCreditCard: {
    reducer: ({ updateValues }, state) =>
      updateValues(state, {
        controlRef: ["payment", "token"],
        value: "pending",
      }),
    effects: [
      (action$: Observable<Action<NormalizedCreditCard>>) =>
        action$.pipe(
          switchMap(({ payload }) => {
            return from(paymentService.getFlexKey()).pipe(
              mergeMap(({ data: { keyId } }) => {
                return from(
                  cyberSourceService.tokenizeCard(payload, keyId)
                ).pipe(
                  map(({ data }) => ({
                    type: "confirmCreditCardSuccess",
                    payload: data,
                  }))
                );
              }),
              catchError(() => of({ type: "confirmCreditCardFailure" }))
            );
          })
        ),
    ],
  },
  confirmCreditCardSuccess: (
    { updateValues },
    state,
    { payload }: Action<string>
  ) => {
    state = updateValues(state, {
      controlRef: ["payment", "confirmed"],
      value: true,
    });

    state = updateValues(state, {
      controlRef: ["payment", "token"],
      value: payload,
    });

    return state;
  },
  confirmCreditCardFailure: ({ updateValues }, state) => {
    return updateValues(state, {
      controlRef: ["payment", "token"],
      value: "error",
    });
  },
});
