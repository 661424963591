import { Action } from "@reactables/core";
import { CustomReducer } from "@reactables/forms";

export const confirmFormGroup: CustomReducer = (
  { updateValues, markControlAsTouched },
  state,
  { payload: formGroupName }: Action<string>
) => {
  const controlRef = [formGroupName, "confirmed"];
  state = updateValues(state, {
    controlRef,
    value: true,
  });

  state = markControlAsTouched(state, {
    controlRef: [formGroupName, "confirmed"],
  });

  return state;
};
