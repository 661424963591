import React from "react";
import { Container } from "react-bootstrap";

const ReferralBanner = ({
  facilityName,
  hide = false,
}: {
  facilityName: string;
  hide?: boolean;
}) => {
  return (
    facilityName && (
      <div className={`referral-banner ${hide ? "referral-banner-hide" : ""}`}>
        <Container>
          <div>
            <span className="font-weight-bold">Welcome!</span> You have been
            sent to us by {facilityName}.
          </div>
        </Container>
      </div>
    )
  );
};

export default ReferralBanner;
