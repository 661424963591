import React from "react";
import { PlacesSearch } from "@jauntin/react-ui";
import { useAppStore } from "@reactables/react-helpers";
import { Field } from "@reactables/react-forms";
import AddressError from "Features/Shared/Components/AddressError";
import { lgUp } from "Helpers/screenWidth";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as VenueSelectors from "../../../Rx/RxCoverageApplication/Selectors/venue.selector";
import { getControl } from "../../../Rx/RxCoverageApplication/Selectors/form.selector";
import { AppState } from "Features/Shared/Rx/RxApp";

const SearchByAddress = ({
  rxCoverageApplication: [state, actions],
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const selectedPlace = VenueSelectors.getByAddressSearchSelection(state);
  const checkingSearch = VenueSelectors.getIsCheckingAddressSearch(state);

  const mobile = !lgUp();

  const errors = getControl(state, "venue.bySearch.selectedPlace")?.errors;

  const [appState] = useAppStore<AppState>();

  if (!appState?.googleMapsLoaded) return;

  return (
    <>
      <Field
        component={PlacesSearch}
        placeholder={
          mobile ? "Search for your venue" : "Search for your venue by name"
        }
        label="Where will the event take place?"
        name="venue.bySearch.inputText"
        readOnly={Boolean(selectedPlace)}
        hasInput
        showSearchIcon={true}
        onSelect={(
          placeId,
          address,
          addressComponents,
          utcOffset,
          placeName
        ) => {
          actions.applicationForm.selectPlaceAddressSearch({
            placeId,
            address,
            addressComponents,
            utcOffset,
            placeName,
          });
        }}
        clearForm={() => {
          actions.applicationForm.clearPlaceAddressSearch();
        }}
      />
      {selectedPlace?.address && (
        <>
          {errors?.validateUsState ? (
            <AddressError
              errorMessage="GatherGuard is not available in Hawaii, Puerto Rico or the Virgin Islands"
              address={selectedPlace.address}
            />
          ) : errors?.venueNameRequired ? (
            <AddressError
              errorMessage="A venue name is required"
              address={selectedPlace.address}
            />
          ) : errors?.venueAddressRequired ? (
            <AddressError
              errorMessage="A specific venue address is required"
              address={selectedPlace.address}
            />
          ) : (
            <></>
          )}
        </>
      )}
      {checkingSearch && <div>Checking search..</div>}
    </>
  );
};

export default SearchByAddress;
