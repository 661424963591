import React from "react";
import { Field } from "@reactables/react-forms";
import SelectList from "Features/Shared/Components/SelectList";
import { ProviderTypes } from "Constants/providers";
import AdditionalQuestions from "./AdditionalQuestions";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import { getProviderHasFrequency } from "Features/CoverageApplication/Rx/RxCoverageApplication/Selectors/event.selector";

const FoodField = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state, actions] = rxCoverageApplication;

  const hasFrequency = getProviderHasFrequency(
    state,
    ProviderTypes.FoodVendors
  );

  return (
    <>
      <label className="label">
        How many people selling food and/or beverages will be at your event,{" "}
        <i>that have not provided you with proof of insurance?</i>
      </label>
      <div className="label__subtext">
        For example, a concession stand or a hot dog cart run by a third party
        earning a profit. Caterers are not considered food vendors.
      </div>
      <div className="mw--130 mb-3">
        <Field
          name={`event.${ProviderTypes.FoodVendors}.count`}
          component={SelectList}
          options={[
            { value: 0, label: "0" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7 or more" },
          ]}
          searchable={false}
          onChange={(value) => {
            actions.applicationForm.selectProviderCount({
              providerType: ProviderTypes.FoodVendors,
              count: value,
            });
          }}
        />
      </div>

      {hasFrequency && (
        <AdditionalQuestions
          rxCoverageApplication={rxCoverageApplication}
          providerType={ProviderTypes.FoodVendors}
          providerName="Food Vendor"
          providerText="vendor selling food and/or beverages"
        />
      )}
    </>
  );
};

export default FoodField;
