import { Effect } from "@reactables/core";
import { from, of } from "rxjs";
import { mergeMap, map, catchError, tap } from "rxjs/operators";
import PaymentService from "../../../../../Services/PaymentService";
import { CreateCoveragePayload } from "../../../Models/purchase.model";
import {
  RxCoverageApplicationOptions,
  RxCoverageApplicationState,
} from "../RxCoverageApplication";
import { Reducers } from "@jauntin/reactables";

export interface CreateCoverageActions {
  createCoverage: (payload: CreateCoveragePayload) => void;
}

export const createCoverage = (
  paymentService: PaymentService,
  options?: RxCoverageApplicationOptions
) => ({
  createCoverage: {
    reducer: (state: RxCoverageApplicationState) => ({
      ...state,
      purchase: Reducers.load(state.purchase),
    }),
    effects: [
      (actions$) =>
        actions$.pipe(
          mergeMap(({ payload }) => {
            return from(paymentService.createCoverage(payload)).pipe(
              map(({ data }) => ({
                type: "createCoverageSuccess",
                payload: data,
              })),
              tap((action) => {
                options?.hooks?.onCreateCoverageSuccess &&
                  options.hooks.onCreateCoverageSuccess(action);
              }),
              catchError((error) =>
                of({ type: "createCoverageFailure", payload: error })
              )
            );
          })
        ),
    ] as Effect<CreateCoveragePayload, boolean>[],
  },
  createCoverageSuccess: {
    reducer: (state: RxCoverageApplicationState, action) => ({
      ...state,
      purchase: Reducers.loadSuccess(state.purchase, action),
    }),
  },
  createCoverageFailure: {
    reducer: (state: RxCoverageApplicationState, action) => ({
      ...state,
      purchase: Reducers.loadError(state.purchase, action),
    }),
  },
});
