import { Observable, of } from "rxjs";

export const maintenanceModeCheck =
  (errorHandler: (error: unknown) => Observable<unknown>) => (error) => {
    if (
      error?.response?.status === 503 &&
      error?.response?.data?.message === "Maintenance"
    ) {
      return of({ type: "maintenanceModeOn", payload: error });
    }

    return errorHandler(error);
  };
