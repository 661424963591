import { Action } from "@reactables/core";
import {
  FormReducers,
  FormControlConfig,
  group,
  control,
} from "@reactables/forms";
import { EventType } from "../../../Models/event.model";

/**
 * @description Selects event types and adds relevant underwriting questions
 */
export const selectEventType = (
  { updateValues, removeControl, addControl }: FormReducers,
  state,
  { payload: eventType }: Action<EventType>
) => {
  state = updateValues(state, {
    controlRef: ["eventType"],
    value: eventType,
  });

  // Clear previous underwriting questions
  if (state.form.underwritingQuestions) {
    state = removeControl(state, ["underwritingQuestions"]);
  }

  // Adds underwriting question controls based on event selected
  const underwritingQuestionsControls = ["lossClaims", "security", "promoter"]
    .filter((name) => eventType[name])
    .reduce(
      (acc: { [key: string]: FormControlConfig<string> }, name) => ({
        ...acc,
        [name]: control(["", "required"]),
      }),
      {}
    );

  state = addControl(state, {
    controlRef: ["underwritingQuestions"],
    config: group({
      controls: underwritingQuestionsControls,
      validators: ["eligibility"],
    }),
  });

  return state;
};
