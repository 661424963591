import { Action } from "@reactables/core";
import { OperatorFunction, of, from, forkJoin, concat } from "rxjs";
import { switchMap, mergeMap, map, catchError, filter } from "rxjs/operators";
import {
  facilityCode as facilityCodeValidator,
  venueCode as venueCodeValidator,
} from "Helpers/validators";
import VenuePresetsService from "Services/VenuePresetsService";
import StateTaxService from "Services/StateTaxService";
import {
  VenueSearchResponse,
  Tax,
  VenueSearchResults,
} from "../../../Models/venue.model";
import { SelectPlaceAddressSearchPayload } from "../../../Models/venue.model";

const VENUE_CODE_PARAM = "v";

const knownVenueTaxLookup: (
  stateTaxService: StateTaxService
) => OperatorFunction<
  { data: VenueSearchResponse },
  { knownVenue: VenueSearchResponse; hasTaxLookup: boolean; taxRegions: Tax[] }
> = (stateTaxService: StateTaxService) => (obs$) =>
  obs$.pipe(
    map(({ data: knownVenue }: { data: VenueSearchResponse }) => knownVenue),
    mergeMap((knownVenue) => {
      const { state, city } = knownVenue.venue;
      return of({ state, city }).pipe(
        taxLookup(stateTaxService),
        map((taxLookupResult) => ({
          knownVenue,
          ...taxLookupResult,
        }))
      );
    })
  );

/**
 * @description Provided a venue code:
 * Operator checks for known venue and looks up tax and utcOffset if known venue exists
 */
export const venueCodeLookup =
  (
    venueService: VenuePresetsService,
    stateTaxService: StateTaxService
  ): OperatorFunction<
    { venueCode: string; facilityCode: string },
    Action<VenueSearchResults | VenueSearchResponse>
  > =>
  (obs$) =>
    obs$.pipe(
      switchMap(({ venueCode, facilityCode }) => {
        const invalidVenueCode =
          !facilityCode ||
          facilityCodeValidator(facilityCode).facilityCode ||
          !venueCode ||
          venueCodeValidator(venueCode).venueCode;

        if (invalidVenueCode) {
          return of({ type: "invalidVenueCode" });
        }

        return from(
          venueService.getPresetsByVenueCode(facilityCode, venueCode)
        ).pipe(
          mergeMap((venueSearchResp: { data: VenueSearchResponse }) => {
            const knownVenueTaxLookup$ = of(venueSearchResp).pipe(
              knownVenueTaxLookup(stateTaxService)
            );

            const utcOffsetLookup$ = of(venueSearchResp).pipe(
              mergeMap(({ data }) => {
                if (data.places.length) {
                  return from(
                    venueService.getUtcOffsetByPlaceId(data.places[0].placeId)
                  );
                } else {
                  return from(venueService.getUtcOffsetByZip(data.venue.zip));
                }
              }),
              map(({ data: { utcOffsetMinutes } }) => utcOffsetMinutes),
              catchError(() => of(0))
            );
            return concat(
              of({
                type: "knownVenueFound",
                payload: venueSearchResp.data,
              }),
              forkJoin([knownVenueTaxLookup$, utcOffsetLookup$]).pipe(
                map(([knownVenueTaxLookup, utcOffset]) => ({
                  type: "venueLookupSuccess",
                  payload: {
                    ...knownVenueTaxLookup,
                    utcOffset: utcOffset || 0,
                  },
                }))
              )
            );
          }),
          catchError((error) => {
            if (error.response?.status === 404) {
              return of({ type: "venueCodeNotFound" });
            } else return of({ type: "venueLookupFailure" });
          })
        );
      })
    );

/**
 * @description Provided a selected google place:
 * Operator looks up if venue is known or not, then looks up taxes
 */
export const selectedPlaceVenueLookup =
  (
    venueService: VenuePresetsService,
    stateTaxService: StateTaxService
  ): OperatorFunction<
    SelectPlaceAddressSearchPayload,
    Action<VenueSearchResults>
  > =>
  (obs$) =>
    obs$.pipe(
      mergeMap((selectedPlace) => {
        return from(venueService.getPresets(selectedPlace.placeId)).pipe(
          knownVenueTaxLookup(stateTaxService),
          map((payload) => ({
            type: "venueLookupSuccess",
            payload: {
              ...payload,
              utcOffset: selectedPlace.utcOffset,
              apiError: false,
            },
          })),
          catchError((response) => {
            if (response.response.status === 404) {
              const { state, city } = selectedPlace.addressComponents;
              return of({ state, city }).pipe(
                taxLookup(stateTaxService),
                map((payload) => ({
                  type: "venueLookupSuccess",
                  payload: {
                    knownVenue: false,
                    ...payload,
                    utcOffset: selectedPlace.utcOffset,
                    apiError: false,
                  },
                }))
              );
            } else return of({ type: "venueLookupFailure" });
          })
        );
      })
    );

/**
 * @description Given a valid venue url param:
 * Operator checks for known venue and looks up tax and utcOffset if known venue exists
 */
export const handleVenueReferral =
  (
    venueService: VenuePresetsService,
    stateTaxService: StateTaxService
  ): OperatorFunction<
    string,
    Action<VenueSearchResults | VenueSearchResponse>
  > =>
  (referralParams$) =>
    referralParams$.pipe(
      filter((referralParams) => Boolean(referralParams)),
      map((referralParams) => {
        const rawParams = referralParams.toLowerCase();
        const urlParams = new URLSearchParams(rawParams);
        const venueCodeParam = urlParams.get(VENUE_CODE_PARAM);

        return venueCodeParam;
      }),
      filter((venueCodeParam) => {
        return (
          venueCodeParam && /^[a-z0-9]{3,4}-[0-9]{3,5}$/i.test(venueCodeParam)
        );
      }),
      map((venueCodeParam) => {
        const [facilityCode, venueCode] = venueCodeParam.split("-");
        return { facilityCode, venueCode };
      }),
      mergeMap((code) => {
        return concat(
          of({ type: "venueReferralLookup" }),
          of(code).pipe(
            venueCodeLookup(venueService, stateTaxService),
            map((action) => {
              switch (action.type) {
                case "knownVenueFound":
                  return action;
                case "venueLookupSuccess":
                  return {
                    type: "venueReferralLookupSuccess",
                    payload: action.payload,
                  };
                default:
                  return { type: "venueReferralLookupFailure" };
              }
            })
          )
        );
      })
    );

/**
 * @description Provided a state and city:
 * Operator looks up tax info (if any)
 */
export const taxLookup =
  (
    stateTaxService: StateTaxService
  ): OperatorFunction<
    { state: string; city: string },
    { hasTaxLookup: boolean; taxRegions: Tax[] }
  > =>
  (obs$) =>
    obs$.pipe(
      switchMap(({ state, city }: { state: string; city: string }) => {
        if (!state || !city)
          return of({
            hasTaxLookup: null,
            taxRegions: null,
          });
        return from(stateTaxService.confirmRegionalTax(state)).pipe(
          mergeMap(
            (confirmResponse: { data: { shouldLookupTax: boolean } }) => {
              if (confirmResponse.data.shouldLookupTax) {
                return from(stateTaxService.getTaxRegions(state, city)).pipe(
                  map((taxResponse: { data: Tax[] }) => ({
                    hasTaxLookup: true,
                    taxRegions: taxResponse.data,
                  }))
                );
              } else {
                return of({
                  hasTaxLookup: false,
                  taxRegions: [],
                });
              }
            }
          )
        );
      })
    );
