import { group } from "@reactables/forms";
import { addVenueForm } from "./addVenueForm.config";
import { uploadForm } from "./uploadForm.config";

export const formConfig = group({
  controls: {
    addVenueForm,
    uploadForm,
  },
});
