import React from "react";
import { Field } from "@reactables/react-forms";
import { TextInput } from "@jauntin/react-ui";
import { useAppStore } from "@reactables/react-helpers";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import { AppState } from "../../../../Shared/Rx/RxApp";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

const ManualVenueAddress = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state, actions] = rxCoverageApplication;
  const [appState] = useAppStore<AppState>();

  const {
    venue: {
      byManualAddress: { address },
    },
  } = FormSelectors.getRootValue(state);

  if (!appState?.usStates.data?.length) {
    return;
  }

  return (
    <>
      <div className="form-group">
        <Field
          component={TextInput}
          name="venue.byManualAddress.venueName"
          placeholder="Venue Name"
          label="Venue Name"
          inputClassName="form-control-lg"
        />
      </div>
      <div className="form-group">
        <AddressFieldGroup
          groupName="venue.byManualAddress.address"
          usStates={appState.usStates.data}
          onCityChange={(e) => {
            actions.applicationForm.manualTaxAreaChange({
              city: e.target.value,
              state: address.state as string,
            });
          }}
          onStateChange={(usState) => {
            actions.applicationForm.manualTaxAreaChange({
              city: address.city,
              state: usState,
            });
          }}
          onZipChange={(e) => {
            actions.applicationForm.manualZipCodeChange(e.target.value);
          }}
        />
      </div>
    </>
  );
};

export default ManualVenueAddress;
