import React, { Fragment } from "react";
import { RxToggle } from "@jauntin/reactables";
import { useReactable } from "@reactables/react-helpers";
import ButtonCollapse from "Features/Shared/Components/ButtonCollapse";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import Dates from "Features/Shared/Components/Dates";
import CurrencyHelper from "Helpers/CurrencyHelper";
import { ProviderTypes } from "Constants/providers";
import { listToStringDays } from "Helpers/ListToString";
import ViewDateList from "./ViewDateList";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as EventSelectors from "../../../Rx/RxCoverageApplication/Selectors/event.selector";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

const View = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state] = rxCoverageApplication;
  const eventDates = EventSelectors.getEventDates(state);
  const totalGuestText = EventSelectors.getGuestsText(state);
  const daysOfWeek = EventSelectors.getNumberedDaysOfWeek(state);

  const {
    event: { eventName, eventFrequencyField: eventFrequency, continuous },
  } = FormSelectors.getRootValue(state);

  const { priceValues } = state;

  const [expandDateList, expandDateListToggle] = useReactable(RxToggle);

  return (
    <div className="form-row">
      <div className="col-auto w--70 font-weight-bold">
        <DollarsAndCents
          currency={new CurrencyHelper()
            .setValue(priceValues.basicCoverageAmount)
            .convertFromInt()}
          cents={false}
        />
      </div>
      <div className="col">
        <div>{eventName}</div>
        {continuous ? (
          <div>{EventSelectors.getDurationText(state)}</div>
        ) : (
          eventDates.length > 0 && (
            <>
              <ul className="p-0 m-0 list-unstyled">
                <Dates
                  dates={eventDates}
                  type={eventFrequency}
                  frequency={daysOfWeek}
                />
              </ul>
              <ButtonCollapse
                clickFunction={expandDateListToggle.toggle}
                showList={expandDateList}
                title={expandDateList ? "Hide all dates" : "See all dates"}
              />
              {expandDateList && <ViewDateList dates={eventDates} />}
            </>
          )
        )}
        <div>{totalGuestText}</div>
        <div>
          {[
            ProviderTypes.Performers,
            ProviderTypes.GoodsVendors,
            ProviderTypes.FoodVendors,
            ProviderTypes.Exhibitors,
          ].map((providerType, index) => {
            return (
              <Fragment key={index}>
                {Number(
                  FormSelectors.getValue(state, `event.${providerType}.count`)
                ) > 0 && (
                  <div>
                    {providerText(
                      providerType,
                      Number(
                        FormSelectors.getValue(
                          state,
                          `event.${providerType}.count`
                        )
                      ),
                      FormSelectors.getValue(
                        state,
                        `event.${providerType}.frequency`
                      ),
                      FormSelectors.getValue(
                        state,
                        `event.${providerType}.providerList`
                      )
                    )}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const providerText = (type, count, frequency, list) => {
  let provider = type;
  const plural = count > 1 ? "s" : "";

  if (type === ProviderTypes.Performers) provider = `performer${plural}`;
  if (type === ProviderTypes.GoodsVendors)
    provider = `vendor${plural} selling goods`;
  if (type === ProviderTypes.FoodVendors)
    provider = `vendor${plural} selling food or beverages`;
  if (type === ProviderTypes.Exhibitors) provider = `exhibitor${plural}`;

  // Check if count is 7:
  if (count === 7) return `There will be 7 or more ${provider}.`;

  // Check if 'everyday' is selected
  if (frequency === "everyday")
    return `There will be ${count} ${provider} at the event everyday.`;

  // Show detailed provider list
  return `There will be ${count} ${provider} at the event: ${listToStringDays(
    list
  )}.`;
};
export default View;
