// Event types come from the server, but the server doesn't know which events have some frontend
// customized properties, ie, whether there is an icon here in the app.
// Add entries here with { identifier: '', ..., ... } to add or override info that came in from the server
export const eventTypeCustomizations = [
  {
    identifier: "wedding",
    selectionIcon: "icon-wedding.svg",
  },
  {
    identifier: "birthday-party",
    selectionIcon: "icon-birthday.svg",
  },
  {
    identifier: "meeting-indoors",
    selectionIcon: "icon-meeting_indoor.svg",
  },
  {
    identifier: "festival-and-cultural-event-outdoors",
    selectionIcon: "icon-tent.svg",
  },
  {
    identifier: "charity-benefit",
    selectionIcon: "icon-charity.svg",
  },
  {
    identifier: "baby-shower",
    selectionIcon: "icon-baby_shower.svg",
  },
  {
    identifier: "church-service-meeting",
    selectionIcon: "icon-church_services.svg",
  },
  {
    identifier: "social-reception-indoors",
    selectionIcon: "icon-social_receptions.svg",
  },
  {
    identifier: "quinceanera",
    selectionIcon: "icon-quinceaneras.svg",
  },
  {
    identifier: "anniversary-party",
    selectionIcon: "icon-anniversary-party.svg",
  },
  {
    identifier: "award-presentation",
    selectionIcon: "icon-award-presentation.svg",
  },
  {
    identifier: "banquet",
    selectionIcon: "icon-banquet.svg",
  },
  {
    identifier: "bar-mitzvah-bat-mitzvah",
    selectionIcon: "icon-bar-mitzvah.svg",
  },
  {
    identifier: "block-party-street-fair",
    selectionIcon: "icon-block-party.svg",
  },
  {
    identifier: "bridal-shower",
    selectionIcon: "icon-bridal-shower.svg",
  },
  {
    identifier: "club-event",
    selectionIcon: "icon-club-event.svg",
  },
  {
    identifier: "concerts-indoors",
    selectionIcon: "icon-indoor-concert.svg",
  },
  {
    identifier: "fundraiser",
    selectionIcon: "icon-fundraiser.svg",
  },
  {
    identifier: "funeral-or-memorial-service",
    selectionIcon: "icon-funeral.svg",
  },
  {
    identifier: "holiday-event-indoors",
    selectionIcon: "icon-holiday.svg",
  },
  {
    identifier: "holiday-event-outdoors",
    selectionIcon: "icon-holiday.svg",
  },
  {
    identifier: "reunion-indoors",
    selectionIcon: "icon-reunion.svg",
  },
  {
    identifier: "reunion-outdoors",
    selectionIcon: "icon-reunion.svg",
  },
];

export const defaultNumberOfIconEventTypes = 9;
