import { ControlRef } from "@reactables/forms";

export interface VenueCodeSearchChangePayload {
  venueCode: string;
  facilityCode: string;
  changedField: ControlRef;
  newValue: string;
}

export interface VenueSearchResponse {
  venue: Venue;
  facility: Facility;
  places: Place[];
  eventTypes: VenueEventType[];
  eventTileShowCount: number;
}

export interface VenueSearchResults extends TaxLookupResults {
  knownVenue: VenueSearchResponse | false;
  utcOffset: number;
  apiError: unknown;
}

export interface TaxLookupResults {
  hasTaxLookup: boolean;
  taxRegions: Tax[];
}

export interface Venue {
  id: number;
  venueCode: string;
  blockedAt: string;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  glLimits: number[];
  additionalCoverage: AdditionalCoverage[];
  additionalInsuredAddress: AdditionalInsuredAddress;
}

export interface AdditionalCoverage {
  type: string;
  name: string;
  description: string;
  mandatory: number;
}

export interface AdditionalInsuredAddress {
  id: number;
  contactType: string;
  placeId: string;
  contactFirstName: string;
  contactLastName: string;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  utcOffset: number;
  created_at: string;
  updated_at: string;
}

export interface Facility {
  code: string;
  address: Address;
  producerName: string;
}

export interface Address {
  id: number;
  contactType: string;
  placeId: string;
  contactFirstName: string;
  contactLastName: string;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  utcOffset: number;
  created_at: string;
  updated_at: string;
}

export interface Place {
  placeId: string;
}

export interface VenueEventType {
  id: number;
  identifier: string;
  name: string;
  subtitle: string;
}
export interface Tax {
  state: string;
  rate: number;
  code: string;
  name: string;
}

export interface AddressComponents {
  address1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  companyName: string;
  addressType: string[];
}

export enum AdditionalCoverageTypes {
  DamageToRentedProperty = "damage-to-rented-property",
  LiquorLiability = "liquor-liability",
  Terrorism = "terrorism",
  HostLiquor = "host-liquor",
  PersonalAndAdvertisingLimit = "personal-and-advertising-limit",
  ProductsAndCompletedOperations = "products-and-completed-operations",
}

export interface SelectPlaceAddressSearchPayload {
  placeId: string;
  address: string;
  addressComponents: AddressComponents;
  utcOffset: number;
  placeName: string;
}
