import { useRef } from "react";
import { useLocation, Link, Route } from "react-router-dom";
import { Button } from "@jauntin/react-ui";
import { useReactable } from "@reactables/react-helpers";
import iconInsurance from "../../../../Assets/Images/icon-insurance.svg";
import RouteParser from "Helpers/RouteParser";
import CurrencyHelper from "Helpers/CurrencyHelper";
import ButtonCollapse from "Features/Shared/Components/ButtonCollapse";
import Icon from "Features/Shared/Components/Icon";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import { lgUp } from "Helpers/screenWidth";
import { sectionScroll } from "Helpers/sectionScroll";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import SummaryTable from "./SummaryTable";
import Purchase from "./Purchase";
import ModalSaveQuote from "../SaveQuote/ModalSaveQuote";
import RxSummary from "../../Rx/RxSummary/RxSummary";
import ReferralBanner from "../ReferralBanner/ReferralBanner";

import * as ReferralSelectors from "../../Rx/RxCoverageApplication/Selectors/referral.selector";
import * as QuoteSelectors from "../../Rx/RxCoverageApplication/Selectors/quote.selector";
import * as ConfirmationSelectors from "../../Rx/RxCoverageApplication/Selectors/confirmation.selector";
import * as EventTypeSelectors from "../../Rx/RxCoverageApplication/Selectors/eventTypes.selector";

const Summary = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const summaryRef = useRef(null);
  const location = useLocation();
  const [coverageApplicationState, , coverageApplicationState$] =
    rxCoverageApplication;

  const [summaryState, summaryActions] = useReactable(RxSummary, {
    coverageApplicationState$,
    onAcConfirmed: () => {
      sectionScroll(summaryRef);
    },
    onPaymentConfirmed: () => {
      setTimeout(() => {
        // Scrolling to bottom of summary when payment is confirmed
        const mainFooterDiv = document.getElementById("main-footer");
        const scrollPosition =
          mainFooterDiv.offsetTop -
          window.innerHeight +
          mainFooterDiv.clientHeight;

        window.scrollTo({
          top: scrollPosition,
        });

        window.scrollTo({
          top: window.scrollY - mainFooterDiv.clientHeight,
        });
      }, 300);
    },
  });

  const { toggleSummaryTableOpen, saveQuoteModalToggle, closeSummaryTable } =
    summaryActions;

  if (!summaryState) return;

  const { showSaveQuoteModal, summaryTableOpen, hideSummaryTable } =
    summaryState;

  const eventType = EventTypeSelectors.getSelectedEvent(
    coverageApplicationState
  );
  const showCheckoutCta = ConfirmationSelectors.getApplicationPageConfirmed(
    coverageApplicationState
  );
  const facilityName = ReferralSelectors.getFacilityName(
    coverageApplicationState
  );

  const { priceValues } = coverageApplicationState;

  const icon = EventTypeSelectors.getSelectedEventIcon(
    coverageApplicationState
  );

  return (
    <div
      ref={summaryRef}
      className={`col-lg-auto order-0 order-lg-1 summary__col ${
        hideSummaryTable ? "summary-hide" : ""
      } ${facilityName ? "summary__col--referral" : ""}`}
    >
      {facilityName && (
        <ReferralBanner facilityName={facilityName} hide={hideSummaryTable} />
      )}

      <div className="container-main__inner summary__container">
        <div className="summary card mt-3 mt-lg-0">
          <div className="summary__logo">
            <Icon image={icon} lg />
          </div>
          <div className="card-body summary__card-body">
            <div className="d-lg-none">
              <ButtonCollapse
                clickFunction={toggleSummaryTableOpen}
                showList={summaryTableOpen}
                title={`${eventType.name} Insurance Policy`}
              />
            </div>
            <div className="form-row mt-lg-4 d-none d-lg-flex">
              <div className="col-auto">
                <img
                  src={iconInsurance}
                  alt="Insurance icon"
                  className="icon--insurance"
                />
              </div>
              <div className="col">
                <h4 className="summary__title">{eventType.name}</h4>
                <h5>Event Insurance Policy</h5>
              </div>
            </div>

            {(summaryTableOpen || lgUp()) && (
              <SummaryTable rxCoverageApplication={rxCoverageApplication} />
            )}

            <Route path="/:eventType/application">
              <div className="form-row mb-2 pt-2 mt-2 border-top">
                <div className="col font-weight-bold">Total</div>
                <div className="col-auto font-weight-bold">
                  <DollarsAndCents
                    currency={new CurrencyHelper()
                      .setValue(priceValues.total)
                      .convertFromInt()}
                  />
                </div>
              </div>
              {showCheckoutCta && (
                <>
                  <Button
                    onClick={saveQuoteModalToggle.toggleOn}
                    text="Save quote"
                    className="d-block mt-3 w-100 btn-primary"
                  />
                  <Link
                    className="no-hover-underline"
                    to={RouteParser.create(
                      location.pathname
                    ).linkToCheckoutPage()}
                  >
                    <Button
                      onClick={closeSummaryTable}
                      text="Checkout"
                      className="d-block mt-3 w-100 btn--summary"
                    />
                  </Link>
                </>
              )}
              {showSaveQuoteModal && (
                <ModalSaveQuote
                  show={showSaveQuoteModal}
                  handleClose={saveQuoteModalToggle.toggleOff}
                  data={{
                    formData: QuoteSelectors.getSaveQuoteData(
                      coverageApplicationState
                    ),
                    state: coverageApplicationState,
                  }}
                />
              )}
            </Route>
            <Route path="/:eventType/checkout">
              <Purchase rxCoverageApplication={rxCoverageApplication} />
            </Route>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
