import React from "react";
import { Alert } from "react-bootstrap";
import { Field, FormArray } from "@reactables/react-forms";
import { ProviderTypes, ProviderFrequency } from "Constants/providers";
import RadioInput from "Features/Shared/Components/RadioInput";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import ProviderNameField from "./ProviderNameField";
import ProviderDaysField from "./ProviderDaysField";
import { getProviderListMeta } from "../../../Rx/RxCoverageApplication/Selectors/event.selector";
import { getValue } from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

const AdditionalQuestions = ({
  providerType,
  providerName,
  rxCoverageApplication,
  providerText = providerName,
}: {
  providerType: ProviderTypes;
  providerName: string;
  rxCoverageApplication: RxCoverageApplicationProp;
  providerText?: string;
}) => {
  const [state, actions] = rxCoverageApplication;

  const frequencyRef = `event.${providerType}.frequency`;
  const providerListRef = `event.${providerType}.providerList`;

  const providerListMeta = getProviderListMeta(
    state,
    providerType,
    providerName
  );

  return (
    <div className="mb-2">
      <div className="label mb-2">
        {`How many days of the event will each uninsured ${providerText} be on-site?`}
      </div>

      <div className="form-group form-row">
        <div className="col-auto my-auto">
          <div className="btn-group btn-group-toggle mr-3">
            <label
              className={`btn btn-outline-primary ${
                getValue(state, frequencyRef) === ProviderFrequency.Everyday
                  ? "active"
                  : ""
              }`}
              htmlFor={`${frequencyRef}.${ProviderFrequency.Everyday}`}
            >
              <Field
                name={frequencyRef}
                id={`${frequencyRef}.${ProviderFrequency.Everyday}`}
                component={RadioInput}
                value={ProviderFrequency.Everyday}
                onChange={() =>
                  actions.applicationForm.selectProviderFrequency({
                    providerType,
                    frequency: ProviderFrequency.Everyday,
                  })
                }
              />
              Everyday
            </label>
          </div>
          <div className="btn-group btn-group-toggle">
            <label
              className={`btn btn-outline-primary ${
                getValue(state, frequencyRef) === ProviderFrequency.Somedays
                  ? "active"
                  : ""
              }`}
              htmlFor={`${frequencyRef}.${ProviderFrequency.Somedays}`}
            >
              <Field
                name={frequencyRef}
                id={`${frequencyRef}.${ProviderFrequency.Somedays}`}
                component={RadioInput}
                value={ProviderFrequency.Somedays}
                onChange={() => {
                  actions.applicationForm.selectProviderFrequency({
                    providerType,
                    frequency: ProviderFrequency.Somedays,
                  });
                }}
              />
              Only some days
            </label>
          </div>
        </div>
      </div>

      {}

      {getValue(state, providerListRef) && (
        <div className="mb-4">
          <div className="d-flex">
            <div className="col-sm-6 p-0 mr-3 mb-1 font-weight-bold">
              {providerName}
            </div>
            <div className="col-sm-2 p-0 mb-1 font-weight-bold">Days</div>
          </div>
          <FormArray name={providerListRef}>
            {({ items }) =>
              items.map((item, i) => {
                return (
                  <div className="form-group mb-1 d-flex" key={i}>
                    <Field
                      name={item.controlRef.concat("name").join(".")}
                      component={ProviderNameField}
                    />
                    <Field
                      name={item.controlRef.concat("days").join(".")}
                      component={ProviderDaysField}
                      onChange={(e) =>
                        actions.applicationForm.updateProviderListItemDay({
                          index: i,
                          providerType,
                          days: e.target.value,
                        })
                      }
                    />
                  </div>
                );
              })
            }
          </FormArray>
          {providerListMeta &&
            providerListMeta.touched &&
            providerListMeta.error && (
              <Alert variant="danger" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div>{providerListMeta.error}</div>
              </Alert>
            )}
        </div>
      )}
    </div>
  );
};
export default AdditionalQuestions;
