import React from "react";
import { Field } from "@reactables/react-forms";
import { Button } from "@jauntin/react-ui";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";
import CoverageField from "./CoverageField";
import * as VenueSelectors from "../../../Rx/RxCoverageApplication/Selectors/venue.selector";
import CurrencyHelper from "Helpers/CurrencyHelper";
import ButtonEdit from "Features/Shared/Components/ButtonEdit";

const BasicCoverage = ({
  rxCoverageApplication,
  onConfirm,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
  onConfirm: () => void;
}) => {
  const [state, actions] = rxCoverageApplication;
  const enabled = FormSelectors.getControl(state, "venue.confirmed").touched;

  const {
    basicCoverage: { confirmed, coverage },
    additionalCoverages: { liquorLiability },
  } = FormSelectors.getRootValue(state);

  const fieldOptions = VenueSelectors.getGlLimits(state);

  return (
    <div className={`component ${enabled ? "enabled" : "disabled"}`}>
      <div className="form-row justify-content-between">
        <div className="col-auto my-auto">
          <h3>Basic Coverage</h3>
        </div>
        <ButtonEdit
          edit={() => {
            actions.applicationForm.updateValues({
              controlRef: ["basicCoverage", "confirmed"],
              value: false,
            });
          }}
          show={confirmed}
        />
      </div>
      <div className="card">
        <div className="card-body">
          {confirmed ? (
            <>
              <div className="row justify-content-between mt-2 pt-1">
                <div className="col-auto my-auto">
                  <p className="mb-0">Limit for each incident (Occurrence)</p>
                </div>
                <div className="col-auto my-auto">
                  {new CurrencyHelper().setValue(coverage).dollars()}
                </div>
              </div>
              {!liquorLiability && (
                <div className="row pt-1">
                  <p className="col-auto mb-0">Includes host liquor</p>
                </div>
              )}
              <div className="row justify-content-between mt-3 pt-1">
                <p className="col-auto mb-0 font-weight-bold">
                  Damage to Rented Premises
                </p>
                <div className="col-auto my-auto">
                  {new CurrencyHelper().setValue(250000).dollars()}
                </div>
              </div>
            </>
          ) : (
            <>
              <label className="mb-0 font-weight-bold" htmlFor="fieldCoverage">
                General Liability Coverage
              </label>
              <>
                <div className="form-row">
                  <Field
                    disabled={!enabled}
                    component={CoverageField}
                    name="basicCoverage.coverage"
                    fieldOptions={fieldOptions}
                  />
                </div>
                {!state.applicationForm["additionalCoverages.liquorLiability"]
                  .value && (
                  <div className="form-row d-flex">
                    <div className="col-auto">
                      <i className="fas fa-check" />
                    </div>
                    <div className="d-flex flex-column col">
                      <h6>Includes Host Liquor</h6>
                      <p className="text-dark font-italic font-weight-normal">
                        Provides coverage when your venue serves alcoholic
                        beverages or allows alcoholic beverages to be consumed
                        on the premises.
                      </p>
                    </div>
                  </div>
                )}
                <hr />
                <div className="d-flex justify-content-between">
                  <h5>Damage to Rented Premises</h5>
                  <div className="my-auto">
                    <span className="font-weight-bold">Limit</span>{" "}
                    {new CurrencyHelper().setValue(250000).dollars()}
                  </div>
                </div>
                <div className="text-dark font-italic font-weight-normal mw--455">
                  Basic protection for damage that you or your guests may cause
                  to the event space.
                </div>
                <hr />
                <div className="form-row d-flex">
                  <div className="col form-group my-auto">
                    <a
                      href={`${process.env.REACT_APP_COVERAGE_DETAILS_LINK}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="span-link"
                      tabIndex={!enabled ? -1 : 0}
                    >
                      Coverage Details
                    </a>
                  </div>
                  <div className="col text-right">
                    <Button
                      disabled={!enabled}
                      onClick={() => {
                        actions.applicationForm.confirmFormGroup(
                          "basicCoverage"
                        );
                        onConfirm();
                      }}
                      text="Next"
                      className="btn-primary"
                    />
                  </div>
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicCoverage;
