import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { CreditCard } from "../../../Models/payment.model";
import cc from "card-validator";
import {
  extractMonthYear,
  normalizeYear,
} from "../../../../../Helpers/normalizers";
import { cyberSourceIdFromCardTypeName } from "../../../../../Helpers/CyberSourceCardTypes";

export const getIsCheckingCreditCard = (state: RxCoverageApplicationState) =>
  state.applicationForm["payment.token"].value === "pending";

export const getCreditCardCheckError = (state: RxCoverageApplicationState) =>
  state.applicationForm["payment.token"].value === "error";

export const getNormalizedCreditCard = (state: RxCoverageApplicationState) => {
  const { cardNumber, expiryDate } = state.applicationForm[
    "payment.billing.creditCard"
  ].value as CreditCard;
  const { month, year } = extractMonthYear(expiryDate);
  const fourDigitYear = normalizeYear(year);
  const cardValidator = cc.number(cardNumber);
  const cardValidatorCard = cardValidator.card || { type: null };
  const cardType = cyberSourceIdFromCardTypeName(cardValidatorCard.type);
  const creditCard = {
    cardNumber,
    cardExpirationMonth: month,
    cardExpirationYear: fourDigitYear,
    cardType,
  };

  return creditCard;
};
