import {
  SelectList as DefaultSelectList,
  SelectListProps,
} from "@jauntin/react-ui";
import customStyles from "Helpers/ReactSelectStyle";

const SelectList = (props: SelectListProps) => (
  <DefaultSelectList {...props} customStyles={customStyles} />
);

export default SelectList;
