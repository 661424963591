import React from "react";
import { Field } from "@reactables/react-forms";
import {
  CardNumberInput,
  CardCVVInput,
  TextInput,
  Button,
} from "@jauntin/react-ui";
import { useAppStore } from "@reactables/react-helpers";
import RadioInput from "Features/Shared/Components/RadioInput";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import ButtonEdit from "Features/Shared/Components/ButtonEdit";
import { AppState } from "Features/Shared/Rx/RxApp";
import { ContactType } from "Constants/contactTypes";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import CardExpField from "./CardExpField";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";
import * as PaymentSelectors from "../../../Rx/RxCoverageApplication/Selectors/payment.selector";

const ccView = (ccNumber) => {
  const cleaned = (ccNumber || "").replace(/.(?=.{4})/g, "X");
  return cleaned;
};

const PaymentInformation = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [appState] = useAppStore<AppState>();
  const usStates = appState?.usStates.data;
  if (!usStates) return;

  const [state, actions] = rxCoverageApplication;
  const hasError = PaymentSelectors.getCreditCardCheckError(state);
  const checkingCreditCard = PaymentSelectors.getIsCheckingCreditCard(state);
  const billingRef = `payment.billing`;
  const creditCardRef = `${billingRef}.creditCard`;
  const differentAddressRef = `${billingRef}.differentAddressFields`;

  const { payment, insuranceContact, insured } =
    FormSelectors.getRootValue(state);

  const enabled = FormSelectors.getControl(
    state,
    "insuranceContact.confirmed"
  ).touched;

  const cvvControl = FormSelectors.getControl(state, `${creditCardRef}.cvv`);
  const ccGroupControl = FormSelectors.getControl(state, `${creditCardRef}`);
  const ccBillingControl = FormSelectors.getControl(state, billingRef);

  return (
    <div className={`component ${enabled ? "enabled" : "disabled"}`}>
      <div className="form-row justify-content-between">
        <div className="col-auto my-auto">
          <h3>Payment Information</h3>
        </div>
        <ButtonEdit
          edit={() => {
            actions.applicationForm.updateValues({
              controlRef: ["payment", "confirmed"],
              value: false,
            });
          }}
          show={payment.confirmed}
        />
      </div>
      <div className="card">
        <div className="card-body">
          {payment.confirmed ? (
            <>
              <p className="font-weight-bold">Credit Card</p>
              <div>{payment.billing.creditCard.cardName}</div>
              <div>{ccView(payment.billing.creditCard.cardNumber)}</div>

              <p className="font-weight-bold mt-3">Billing to</p>
              {payment.billing.sameAddressAsContact && (
                <>
                  <p>
                    {insuranceContact.firstName} {insuranceContact.lastName}
                  </p>
                  {insuranceContact.differentAddressFields ? (
                    <>
                      {insuranceContact.differentAddressFields.companyName && (
                        <p>
                          {insuranceContact.differentAddressFields.companyName}
                        </p>
                      )}
                      <p>
                        {
                          insuranceContact.differentAddressFields.address
                            .address
                        }
                      </p>
                      <p>{`${insuranceContact.differentAddressFields.address.city}, ${insuranceContact.differentAddressFields.address.state}, ${insuranceContact.differentAddressFields.address.zip}, US`}</p>
                    </>
                  ) : (
                    <>
                      <p>{insured.address.address}</p>
                      <p>
                        {`${insured.address.city}, ${insured.address.state}, ${insured.address.zip}, US`}
                      </p>
                    </>
                  )}
                </>
              )}
              {payment.billing.differentAddressFields && (
                <>
                  {payment.billing.differentAddressFields.type ===
                  ContactType.Person ? (
                    <p>{`${payment.billing.differentAddressFields.firstName} ${payment.billing.differentAddressFields.lastName}`}</p>
                  ) : (
                    <p>{payment.billing.differentAddressFields.companyName}</p>
                  )}
                  <p>
                    {payment.billing.differentAddressFields.address.address}
                  </p>
                  <p>{`${payment.billing.differentAddressFields.address.city}, ${payment.billing.differentAddressFields.address.state}, ${payment.billing.differentAddressFields.address.zip}, US`}</p>
                </>
              )}
            </>
          ) : (
            <>
              <div className="mb-1">
                We accept Visa, Mastercard, American Express or Discover.
              </div>
              <Field
                component={CardNumberInput}
                name={`${creditCardRef}.cardNumber`}
                errorClassName="d-inline ml-2"
                inputClassName="form-control-lg"
                disabled={!enabled}
              />
              <div className="form-group form-row">
                <Field
                  component={TextInput}
                  name={`${creditCardRef}.cardName`}
                  label="Name on Card"
                  ariaLabel="Credit Card Name"
                  wrapperClassName="col-md mb-3 d-flex flex-column"
                  errorClassName="form-group--order-4 mt-2"
                  inputClassName="form-control-lg"
                  disabled={!enabled}
                />
                <Field
                  component={CardExpField}
                  name={`${creditCardRef}.expiryDate`}
                  disabled={!enabled}
                />
                <Field
                  component={CardCVVInput}
                  name={`${creditCardRef}.cvv`}
                  label="CVV"
                  placeholder="XXX"
                  wrapperClassName="col-auto d-flex flex-column"
                  errorClassName="form-group--order-4 mt-2"
                  inputClassName="form-control-lg mw--130"
                  disabled={!enabled}
                  // Pass in cc form group credit card error into component meta
                  meta={{
                    ...cvvControl,
                    errors: {
                      ...cvvControl.errors,
                      creditCardCvv: ccGroupControl.errors.creditCardCvv,
                    },
                  }}
                  onChange={(e) => {
                    actions.applicationForm.cvvChange(e.currentTarget.value);
                  }}
                />
              </div>
              <div className="form-group">
                <div className="form-group form-row">
                  <div className="col-auto">
                    <label htmlFor="fieldPayeeSame" className="label">
                      Billing address same as contact
                    </label>
                  </div>
                  <div className="col-auto my-auto">
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        name={`${billingRef}.sameAddressAsContact`}
                        component={RadioInput}
                        value="yes"
                        id={`${billingRef}.sameAddressAsContactYes`}
                        className="custom-control-input"
                        disabled={!enabled}
                        onChange={(e) => {
                          actions.applicationForm.selectSameAddressAsContact(
                            e.target.value
                          );
                        }}
                      />
                      <label
                        className="custom-control-label label"
                        htmlFor={`${billingRef}.sameAddressAsContactYes`}
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                  <div className="col-auto my-auto">
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        name={`${billingRef}.sameAddressAsContact`}
                        component={RadioInput}
                        value="no"
                        id={`${billingRef}.sameAddressAsContactNo`}
                        className="custom-control-input"
                        onChange={(e) => {
                          actions.applicationForm.selectSameAddressAsContact(
                            e.target.value
                          );
                        }}
                        disabled={!enabled}
                      />
                      <label
                        className="custom-control-label label"
                        htmlFor={`${billingRef}.sameAddressAsContactNo`}
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {payment.billing.sameAddressAsContact === "no" && (
                <div id="newPayeeInfo">
                  <div className="form-group mt-4">
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        id={`${differentAddressRef}.typeInd`}
                        name={`${differentAddressRef}.type`}
                        className="custom-control-input"
                        component={RadioInput}
                        value={ContactType.Person}
                        disabled={!enabled}
                        onChange={() =>
                          actions.applicationForm.selectDifferentPaymentAddressContactType(
                            ContactType.Person
                          )
                        }
                      />
                      <label
                        className="custom-control-label label"
                        htmlFor={`${differentAddressRef}.typeInd`}
                      >
                        an individual
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        id={`${differentAddressRef}.typeCorp`}
                        name={`${differentAddressRef}.type`}
                        className="custom-control-input"
                        component={RadioInput}
                        value={ContactType.Company}
                        disabled={!enabled}
                        onChange={() =>
                          actions.applicationForm.selectDifferentPaymentAddressContactType(
                            ContactType.Company
                          )
                        }
                      />
                      <label
                        className="custom-control-label label"
                        htmlFor={`${differentAddressRef}.typeCorp`}
                      >
                        a company/organization
                      </label>
                    </div>
                  </div>
                  {payment.billing.differentAddressFields.type ===
                    ContactType.Person && (
                    <div id="payeeIndividual" className="form-row">
                      <Field
                        component={TextInput}
                        name={`${differentAddressRef}.firstName`}
                        label="First name"
                        ariaLabel="First name"
                        placeholder="First name"
                        wrapperClassName="col-sm"
                        inputClassName="form-control-lg"
                        disabled={!enabled}
                      />
                      <Field
                        component={TextInput}
                        name={`${differentAddressRef}.lastName`}
                        label="Last name"
                        ariaLabel="Last name"
                        placeholder="Last name"
                        wrapperClassName="col-sm"
                        inputClassName="form-control-lg"
                        disabled={!enabled}
                      />
                    </div>
                  )}
                  {payment.billing.differentAddressFields.type ===
                    ContactType.Company && (
                    <div id="payeeCompany" className="form-row">
                      <Field
                        component={TextInput}
                        name={`${differentAddressRef}.companyName`}
                        label="Company name"
                        ariaLabel="Company name"
                        placeholder="Company name"
                        errorClassName="d-inline ml-2"
                        wrapperClassName="col-sm"
                        inputClassName="form-control-lg"
                        disabled={!enabled}
                      />
                    </div>
                  )}
                  <AddressFieldGroup
                    groupName={`${differentAddressRef}.address`}
                    usStates={usStates}
                    disabled={!enabled}
                  />
                </div>
              )}
              <hr />

              <div className="float-right">
                {hasError && (
                  <div className="form-error">
                    There was an error processing your credit card.
                  </div>
                )}
                <Button
                  disabled={
                    !ccBillingControl.valid || checkingCreditCard || !enabled
                  }
                  onClick={() => {
                    actions.applicationForm.confirmCreditCard(
                      PaymentSelectors.getNormalizedCreditCard(state)
                    );
                  }}
                  text="Confirm Payment Information"
                  className="btn-primary"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentInformation;
