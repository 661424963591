// Ask the server for the name of a facility from the facility code
class FacilityNameService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getFacilityNameFromFacilityCode(facilityCode) {
    return this.api.get({
      location: "/facilityName",
      params: {
        facilityCode,
      },
    });
  }
}

export default FacilityNameService;
