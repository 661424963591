import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { ApplicationForm } from "../../../Models/applicationForm.model";

export const getControl = (state: RxCoverageApplicationState, key = "root") =>
  state.applicationForm[key];

export const getRootValue = (state: RxCoverageApplicationState) => {
  return state.applicationForm.root.value as ApplicationForm;
};

export const getValue = (
  state: RxCoverageApplicationState,
  key: string
): unknown => {
  return state.applicationForm[key]?.value;
};
