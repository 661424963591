import { Action, ActionMap } from "@reactables/core";
import { CustomReducers, group, control } from "@reactables/forms";
import { Configs } from "@jauntin/reactables";
import { VenueListItem } from "../../../Models/venueListItem.model";
import { editVenue } from "../Configs/editVenue.config";
import { VenueContact } from "../../../Models/uploadForm.model";

const { emptyAddress2 } = Configs;

export interface EditVenueActions extends ActionMap {
  addVenueContact: () => void;
  editVenue: (index: number) => void;
  saveVenue: () => void;
  clearAdditionalInsured: () => void;
  cancelEdit: () => void;
}

export const editVenueReducers: CustomReducers<EditVenueActions> = {
  addVenueContact: ({ pushControl }, state) => {
    const contactsRef = ["uploadForm", "editVenue", "venue", "contacts"];

    if (
      (state.form[contactsRef.join(".")].value as VenueContact[]).length < 5
    ) {
      state = pushControl(state, {
        controlRef: contactsRef,
        config: group({
          controls: {
            email: control(["", ["required", "email"]]),
            copyOnEmails: control([false]),
          },
        }),
      });
    }

    return state;
  },
  editVenue: ({ addControl }, state, { payload: index }: Action<number>) => {
    const venue = (state.form["uploadForm.venueList"].value as VenueListItem[])[
      index
    ];

    state = addControl(state, {
      controlRef: ["uploadForm", "editVenue"],
      config: editVenue(venue, index),
    });

    return state;
  },
  saveVenue: ({ removeControl, updateValues }, state) => {
    const { form } = state;
    const venueListRef = ["uploadForm", "venueList"];

    const { venue: newVenue, index } = form["uploadForm.editVenue"].value as {
      index: number;
      venue: VenueListItem;
    };

    const newVenueList = (
      state.form[venueListRef.join(".")].value as VenueListItem[]
    ).map((venue, i) => {
      if (i === index) {
        return newVenue;
      } else {
        return venue;
      }
    });

    state = updateValues(state, {
      controlRef: venueListRef,
      value: newVenueList,
    });

    state = removeControl(state, ["uploadForm", "editVenue"]);

    return state;
  },
  clearAdditionalInsured: ({ updateValues }, state) =>
    updateValues(state, {
      controlRef: ["uploadForm", "editVenue", "venue", "additionalInsured"],
      value: {
        name: "",
        address: emptyAddress2,
      },
    }),
  cancelEdit: ({ removeControl }, state) =>
    removeControl(state, ["uploadForm", "editVenue"]),
};
