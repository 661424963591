import axios from "axios";
import param from "./param";
import { apiUrl } from "../Constants/apiUrl";

/**
 * Base abstraction for making an axios call to our servers
 * Provides some defaults such as headers and API base url.
 *
 * It is recommended to use this class with a 'Service' class that represents a set of calls
 * e.g. PriceService.getQuote - has an internal reference to an API object and prepares a call
 *
 * Calls return axios objects, so they can be used the same as axios.
 */
class API {
  static base = apiUrl;

  static defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  _instance = null;

  constructor(URL = API.base, credentials = true) {
    this._instance = axios.create({
      baseURL: URL,
      headers: API.defaultHeaders,
      withCredentials: credentials,
    });
  }

  get = ({ location, params }) => {
    return this._instance.get(location, {
      params,
      paramsSerializer: param,
    });
  };

  getDownload = ({ location, config }) => {
    return this._instance.get(location, config);
  };

  post = ({ location, body }) => {
    return this._instance.post(location, body);
  };
}

export default API;
