import React from "react";
import { Link } from "react-router-dom";
import Icon from "Features/Shared/Components/Icon";

const EventRadioBtn = ({ eventTypeRadio, icon, text, action }) => (
  <div
    className="event-radio-btn btn-group-toggle col-sm-6 col-md-4 my-2"
    data-toggle="button"
  >
    <Link
      to={`/${eventTypeRadio.identifier}`}
      className={`btn btn-outline-secondary btn--radio`}
    >
      <label className="mb-0" htmlFor={`${eventTypeRadio.identifier}`}>
        <input
          name="eventTypeRadio"
          value={eventTypeRadio}
          id={`${eventTypeRadio.identifier}`}
          hidden
          type="radio"
          onClick={() => {
            action(eventTypeRadio);
          }}
        />
        <Icon image={icon} />
        <span className="ml-3 text-left">{text}</span>
      </label>
    </Link>
  </div>
);

export default EventRadioBtn;
