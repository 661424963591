import { Action, ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { ContactType, CompanyTypes } from "Constants/contactTypes";
import {
  personName,
  companyDetails,
  jointVentureType,
} from "../Configs/insured.config";

export interface InsuredActions extends ActionMap {
  selectInsuredType: (payload: ContactType) => void;
  selectInsuredCompanyType: (payload: CompanyTypes) => void;
}

export const insuredReducers: CustomReducers<InsuredActions> = {
  /**
   * @description toggles contact type and adds/remove fields accordingly
   */
  selectInsuredType: (
    { addControl, removeControl, updateValues },
    state,
    { payload }: Action<ContactType>
  ) => {
    state = updateValues(state, {
      controlRef: ["insured", "type"],
      value: payload,
    });

    const isPerson = payload === ContactType.Person;

    state = addControl(
      state,
      isPerson
        ? { controlRef: ["insured", "personName"], config: personName }
        : { controlRef: ["insured", "companyDetails"], config: companyDetails }
    );

    state = removeControl(
      state,
      isPerson ? ["insured", "companyDetails"] : ["insured", "personName"]
    );

    return state;
  },
  selectInsuredCompanyType: (
    { addControl, removeControl, updateValues },
    state,
    { payload }: Action<CompanyTypes>
  ) => {
    state = updateValues(state, {
      controlRef: ["insured", "companyDetails", "type"],
      value: payload,
    });

    const isJointVenture = payload === CompanyTypes.JointVenture;
    const jointVentureRef = ["insured", "companyDetails", "jointVentureType"];

    if (isJointVenture) {
      state = addControl(state, {
        controlRef: jointVentureRef,
        config: jointVentureType,
      });
    } else if (state.form[jointVentureRef.join(".")]) {
      state = removeControl(state, jointVentureRef);
    }

    return state;
  },
};
