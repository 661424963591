import React from "react";

const AddressError = ({
  errorMessage,
  address = "",
}: {
  errorMessage: string;
  address: string;
}) => (
  <>
    <div className="font-weight-bold text-danger">{errorMessage}</div>
    <div>
      <i className="fal fa-times-circle text-danger" />
      &nbsp;
      <span className="font-italic text-danger">{address}</span>
    </div>
  </>
);

export default AddressError;
