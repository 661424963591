import { useReactable } from "@reactables/react-helpers";
import { Form, Field } from "@reactables/react-forms";
import { RxVenueUploader } from "../Rx/RxVenueUploader/RxVenueUploader";
import { Spinner, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  EmailInput,
  CheckboxInput,
  Button,
  AsyncButton,
} from "@jauntin/react-ui";
import VenueUploadService from "../../../Services/VenueUploadService";
import API from "../../../Helpers/API";
import AddVenueForm from "./AddVenueForm";
import EditVenueForm from "./EditVenueForm";
import ContactForm from "./ContactForm";
import VenueCard from "./VenueCard";
import pluralize from "../../../Helpers/Pluralize";

const VenueUploader = () => {
  const { uploadId } = useParams() as { uploadId: string };

  const rxVenueUploader = useReactable(
    RxVenueUploader,
    new VenueUploadService(new API()),
    {
      uploadId,
    }
  );
  const [state, actions] = rxVenueUploader;

  if (!state) return;

  const { form } = state;
  const {
    uploadForm: { venueList, editVenue },
  } = form.root.value;

  if (state.facilityInfo.loading)
    return (
      <div className="venue-uploader__loading-spinner mh--hf container">
        <Spinner animation="border" role="status" />
        <span className="ml-3">Loading your facility...</span>
      </div>
    );

  if (state.facilityInfo.error)
    return (
      <div className="d-flex align-items-center justify-content-center mh--hf container">
        <Alert variant="error" className="mt-3">
          <i className="far fa-exclamation-circle alert__icon" />
          <div className="alert__text">
            Sorry, your link for the venue upload has expired or is no longer
            valid. Contact us at{" "}
            <span className="text-nowrap">(844) 747-6240</span> or
            gatherguard@intactinsurance.com for further assistance.{" "}
          </div>
        </Alert>
      </div>
    );

  if (state.submission.success) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center mh--hf container">
        <h4>Thank you for submitting the form!</h4>
        <h5>Submitted venues were sent for review.</h5>
      </div>
    );
  }

  return (
    <div className="venue-uploader container">
      <h4>
        Please add venues for{" "}
        <span className="c-teal">{state.facilityInfo.data?.facility.name}</span>{" "}
        facility
      </h4>
      <div className="row">
        <Form rxForm={[form, actions.form]}>
          <div className="col-7 venue-uploader__venues">
            <ContactForm rxVenueUploader={rxVenueUploader} />
            <Field
              name="uploadForm.defaultContact.email"
              component={EmailInput}
              label="Contact email for all venues (leave blank if the contact email is the same as the facility contact email)"
              inputClassName="form-control-lg"
              labelClassName="my-3"
            />
            <Field
              name="uploadForm.defaultContact.copyOnEmails"
              component={CheckboxInput}
              label="Copy on emails containing event coverage information"
              inputClassName="form-control-lg"
            ></Field>
            <div className="d-flex venue-uploader__venues-header">
              <h4>Venues</h4>
              <h4 className="ml-auto">
                ({venueList.length}{" "}
                {pluralize(venueList.length, "venue", "venues")})
              </h4>
            </div>
            {form["uploadForm.venueList"].errors.arrayNotEmpty && (
              <p>No Venues Added.</p>
            )}

            <br />
            {venueList.map((venue, index) => {
              return (
                <div className="card mb-3" key={index}>
                  <div className="card-header p-3 bg-transparent d-flex align-items-center">
                    <strong>Venue #{index + 1}</strong>
                    <div className="ml-auto">
                      {(index === editVenue?.index ||
                        !form["uploadForm.editVenue"]) && (
                        <Button
                          className="btn-link c-danger"
                          onClick={() => {
                            actions.form.removeVenue(index);
                          }}
                          text="Remove"
                        />
                      )}
                      {!form["uploadForm.editVenue"] && (
                        <Button
                          className="btn-link"
                          onClick={() => {
                            actions.form.editVenue(index);
                          }}
                          text="Edit/Add Venue Contact/Additional Insured"
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="mb-3">
                      Venue will automatically be added as an Additional Insured
                      to the policy
                    </p>
                    {editVenue?.index === index ? (
                      <EditVenueForm rxVenueUploader={rxVenueUploader} />
                    ) : (
                      <VenueCard venue={venue} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-5">
            <div className="card">
              <p className="card-header bg-transparent">
                <strong>Venue Information</strong>
              </p>
              <div className="card-body">
                <AddVenueForm rxVenueUploader={rxVenueUploader} />
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <AsyncButton
                  onClick={() => {
                    actions.submit(form.uploadForm.value);
                  }}
                  spinning={state.submission.loading}
                  spinningText="Submitting..."
                  disabled={!form.uploadForm.valid || state.editingContact}
                  className="w-100 btn btn-primary"
                >
                  {!state.submission.loading && "Submit Venues"}
                </AsyncButton>
                {state.submission.error && (
                  <>
                    <Alert variant="error" className="mt-3">
                      <i className="far fa-exclamation-circle alert__icon" />
                      <div className="alert__text">
                        Sorry, we were unable to process your request. Please
                        try again or contact us at{" "}
                        <span className="text-nowrap">(844) 747-6240</span> or
                        gatherguard@intactinsurance.com for further assistance.{" "}
                      </div>
                    </Alert>
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default VenueUploader;
