import React from "react";

const Footer = () => (
  <footer id="main-footer" className="footer container-fluid mt-auto">
    <div className="container h-100 py-4">
      <div className="row h-50 align-items-center">
        <div className="col-lg-3 col-sm-12 text-center text-lg-left">
          <a
            href={`${process.env.REACT_APP_MARKETING_SITE_HOST}refunds-and-cancellations`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Refunds and Cancellations
          </a>
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-lg-left">
          <a
            href={`${process.env.REACT_APP_MARKETING_SITE_HOST}sample-policy`}
            target="_blank"
            rel="noreferrer noopener"
          >
            View Sample Policy and Certificate
          </a>
        </div>
        <div className="col-lg-5 col-sm-12 text-center text-lg-right">
          Need Assistance?
          <a href="tel:+1-844-747-6240" className="pl-1 footer__help-num">
            (844) 747-6240
          </a>
        </div>
      </div>
      <div className="row h-50">
        <div className="col-auto mx-auto my-1 footer__text">
          Intact Entertainment is a marketing brand for the insurance company
          subsidiaries of Intact Insurance Group USA LLC. Coverages are
          underwritten by Atlantic Specialty Insurance Company with its
          principal place of business at 605 Highway 169 N, Plymouth, MN 55441.
          This material is intended as a general description of certain types of
          insurance coverages and services. Coverages and availability vary by
          state; exclusions and deductibles may apply. Please refer to your
          insurance policy or consult with your independent insurance advisor
          for information about coverages, terms and conditions.
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
