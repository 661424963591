import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { Reducers } from "@jauntin/reactables";

export const venueCertHoldersReducers = {
  fetchVenueCertHolders: {
    reducer: (
      state: RxCoverageApplicationState
    ): RxCoverageApplicationState => ({
      ...state,
      venueCertHolders: Reducers.load(state.venueCertHolders),
    }),
    effects: [],
  },
  fetchVenueCertHoldersSuccess: {
    reducer: (state: RxCoverageApplicationState, action) => ({
      ...state,
      venueCertHolders: Reducers.loadSuccess(state.venueCertHolders, action),
    }),
  },
  fetchVenueCertHoldersFailure: {
    reducer: (state: RxCoverageApplicationState, action) => ({
      ...state,
      venueCertHolders: Reducers.loadError(state.venueCertHolders, action),
    }),
  },
  clearVenueCertHolders: {
    reducer: (state: RxCoverageApplicationState) => ({
      ...state,
      venueCertHolders: Reducers.loadableInitialState,
    }),
  },
};
