import React from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { useReactable } from "@reactables/react-helpers";
import { RxToggle, RxTimer } from "@jauntin/reactables";
import { Button } from "react-bootstrap";
import RouteParser from "Helpers/RouteParser";
import * as InsuredSelectors from "../../Rx/RxCoverageApplication/Selectors/insured.selector";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";

const DOCUMENT_DOWNLOAD_WINDOW = 7200; // seconds, or 2 hours

const ThankYou = ({
  rxCoverageApplication: [state, actions],
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [downloadedAlert, downloadedAlertToggle] = useReactable(RxToggle);

  const [timerState] = useReactable(RxTimer, {
    time: DOCUMENT_DOWNLOAD_WINDOW,
  });

  const { documents, purchase } = state;
  const purchaseData = purchase.data;

  const fileName =
    InsuredSelectors.getInsuredName(state) + "-Event-Insurance-Documents.zip";

  if (!timerState) return;
  const { timesUp: showTimeoutMessage } = timerState;

  return (
    <>
      {!showTimeoutMessage ? (
        <>
          <div className="container-main">
            <div className="col-lg order-1 order-lg-0">
              <div className="container-main__inner">
                {purchaseData?.orderNumber && purchaseData?.email ? (
                  <div className="component">
                    <h2 className="display-4">Thank you!</h2>
                    <h3 className="h7">
                      Your order was completed successfully.
                    </h3>
                    <h5 className="h8">{`Order number ${purchaseData?.orderNumber}`}</h5>
                    <p>
                      {`You will receive an email shortly at ${purchaseData?.email} with a copy of your insurance documents.`}
                    </p>
                    <br />

                    {!documents.success && !isMobile && (
                      <Button
                        className="btn btn-primary btn--lg mt-5 py-2 px-4 d-flex align-items-center justify-content-center col-sm-auto"
                        onClick={() =>
                          actions.downloadDocuments({
                            orderNumber: purchaseData.orderNumber,
                            pdfLink: purchaseData.pdfLink,
                            fileName,
                          })
                        }
                      >
                        {documents.loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm text-light align-middle"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="mx-2">Downloading...</span>
                          </>
                        ) : (
                          "Download Policy Documents"
                        )}
                      </Button>
                    )}
                    {documents.success && !isMobile && (
                      <>
                        <Button
                          className="btn btn-secondary btn--downloaded btn--lg mt-5 py-2 px-4 d-flex align-items-center justify-content-center col-sm-auto"
                          onClick={downloadedAlertToggle.toggleOn}
                        >
                          <>
                            <i className="fal fa-check-circle fa-2x mr-2" />
                            Download Successful!
                          </>
                        </Button>
                        {downloadedAlert ? (
                          <div className="label__subtext text-danger mt-2">
                            You have already downloaded your policy document.
                          </div>
                        ) : (
                          <div className="label__subtext text-muted mt-2">
                            Can&apos;t find your policy download on your
                            computer? Check your downloads folder.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="component">
                    <h2 className="display-4 pt-3">Oops!</h2>
                    <h6 className="h7">Are you looking for an expired page?</h6>
                    <p className="mt-4">
                      If you recently purchased insurance, check your email for
                      your policy document, otherwise, click here to purchase
                      insurance for your event:
                    </p>

                    <Link
                      to={RouteParser.create().linkToEventTypePage()}
                      className="btn btn-primary btn--lg my-3"
                    >
                      Get Insurance!
                    </Link>

                    <h3 className="h3 mt-5">Questions?</h3>
                    <p>
                      If you have any questions please call us at (844)
                      747-6240.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="downloadExpired__content">
          <img
            src="/images/expired-icon.svg"
            alt="computer"
            className="downloadExpired__img"
          />
          <h1 className="downloadExpired__title">
            Your download link has expired.
          </h1>
          <p className="text-dark">
            Good news, though! Your policy documents have been
          </p>
          <p className="text-dark">emailed to the address you have provided.</p>
        </div>
      )}
    </>
  );
};

export default ThankYou;
