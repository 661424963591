import React from "react";
import { useReactable } from "@reactables/react-helpers";
import { RxToggle } from "@jauntin/reactables";
import { Button } from "@jauntin/react-ui";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import Dates from "Features/Shared/Components/Dates";
import CurrencyHelper from "Helpers/CurrencyHelper";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import BasicCoverage from "./BasicCoverage";
import GuestsVendors from "./GuestsVendors";
import ModalCertificateHolders from "./ModalCertificateHolders";

import * as EventSelectors from "../../Rx/RxCoverageApplication/Selectors/event.selector";
import * as FormSelectors from "../../Rx/RxCoverageApplication/Selectors/form.selector";
import * as QuoteSelectors from "../../Rx/RxCoverageApplication/Selectors/quote.selector";

const SummaryTable = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state] = rxCoverageApplication;
  const [showCertsModal, certsModalToggle] = useReactable(RxToggle);

  const priceValues = state.priceValues;

  const taxType = QuoteSelectors.getTaxType(state);

  const eventTotalGuests = EventSelectors.getTotalEventAttendees(state);
  const eventDates = EventSelectors.getEventDates(state);
  const daysOfWeek = EventSelectors.getNumberedDaysOfWeek(state);

  const {
    basicCoverage: { confirmed: basicCoverageConfirmed, coverage },
    additionalCoverages: { liquorLiability, damageToRentedProperty },
    event: {
      confirmed: aboutEventConfirmed,
      eventFrequencyField,
      performers,
      goodsVendors,
      foodVendors,
      exhibitors,
    },
  } = FormSelectors.getRootValue(state);

  return (
    <div className="summary__table">
      <div className="summary__additionalCertHolders">
        <div>This policy has additional certificate holders.</div>
        <br />
        {state.venueCertHolders.loading ? (
          <>
            <span>
              <i className="fas fa-circle-notch fa-spin text-secondary spinner" />
            </span>
            <span className="text-secondary mx-2">
              loading certificate holders...
            </span>
          </>
        ) : (
          <Button
            className="summary__link"
            text="Preview my certificate language"
            onClick={certsModalToggle.toggleOn}
          />
        )}
        <ModalCertificateHolders
          show={showCertsModal}
          handleClose={certsModalToggle.toggleOff}
          rxCoverageApplication={rxCoverageApplication}
        />
      </div>
      <div className="form-row">
        <div className="col">
          <div className="d-flex justify-content-between">
            <div className="font-weight-bold">Basic Coverage</div>
            <div className="font-weight-bold">
              <DollarsAndCents
                currency={new CurrencyHelper()
                  .setValue(priceValues.basicCoverageAmount)
                  .convertFromInt()}
              />
            </div>
          </div>
          <div className="col p-0 m-0 list-unstyled list-font">
            <BasicCoverage
              confirmed={basicCoverageConfirmed}
              coverage={coverage.toString()}
            />
          </div>
          <div className="mb-3">
            Includes:
            {!liquorLiability && <div>Host Liquor</div>}
            <div>Damage to Rented Premises up to $250,000</div>
          </div>
          <ul className="p-0 m-0 list-unstyled list-font">
            {aboutEventConfirmed && (
              <>
                <Dates
                  dates={eventDates}
                  type={eventFrequencyField}
                  frequency={daysOfWeek}
                />
              </>
            )}
            <GuestsVendors
              aboutEventConfirmed={aboutEventConfirmed}
              eventTotalGuests={eventTotalGuests.toString()}
              eventPerformersCount={performers.count.toString()}
              eventGoods={goodsVendors.count.toString()}
              eventFood={foodVendors.count.toString()}
              eventExhibition={exhibitors.count.toString()}
            />
          </ul>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <div className="font-weight-bold mb-0">Additional Coverages</div>
          <ul className="p-0 m-0 list-unstyled list-font">
            <li className="d-flex justify-content-between">
              <span>Terrorism Coverage (included)</span>
              <span className="font-weight-bold">
                <DollarsAndCents currency={new CurrencyHelper().setValue(0)} />
              </span>
            </li>
            {liquorLiability && (
              <li className="d-flex justify-content-between">
                <span>Liquor Liability</span>
                <span className="font-weight-bold">
                  <DollarsAndCents
                    currency={new CurrencyHelper()
                      .setValue(priceValues.liquor)
                      .convertFromInt()}
                  />
                </span>
              </li>
            )}
            {damageToRentedProperty && (
              <li className="d-flex justify-content-between">
                <span>
                  Damage to Rented Premises limit&nbsp;increase to $1 million
                </span>
                <span className="font-weight-bold">
                  <DollarsAndCents
                    currency={new CurrencyHelper()
                      .setValue(priceValues.damageToRentedProperty)
                      .convertFromInt()}
                  />
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="form-row border-top list-font pt-2 mt-1 mb-1">
        <div className="col">Subtotal</div>
        <div className="col-auto font-weight-bold">
          <DollarsAndCents
            currency={new CurrencyHelper()
              .setValue(priceValues.subtotal)
              .convertFromInt()}
          />
        </div>
      </div>
      {priceValues.federalSurcharge > 0 && (
        <div className="form-row list-font mb-1">
          <div className="col">
            <span className="font-italic">State Surcharge</span>
          </div>
          <div className="col-auto font-weight-bold">
            <DollarsAndCents
              currency={new CurrencyHelper()
                .setValue(priceValues.federalSurcharge)
                .convertFromInt()}
            />
          </div>
        </div>
      )}
      {priceValues.stateSurcharge > 0 && (
        <div className="form-row list-font mb-1">
          <div className="col font-italic">
            <span>{taxType}</span>
          </div>
          <div className="col-auto font-weight-bold">
            <DollarsAndCents
              currency={new CurrencyHelper()
                .setValue(priceValues.stateSurcharge)
                .convertFromInt()}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryTable;
