import { Observable } from "rxjs";
import {
  ControlModels,
  RxFormActions,
  RxFormProviders,
  RxFormOptions,
} from "@reactables/forms";
import { Reactable, Action } from "@reactables/core";
import { FormBuilders } from "@jauntin/reactables";

import providers from "Helpers/formProviders";
import { validateUsState } from "Helpers/asyncValidators";

import VenuePresetsService from "Services/VenuePresetsService";
import StateTaxService from "Services/StateTaxService";
import PaymentService from "Services/PaymentService";
import CyberSourceService from "Services/CyberSourceService";

import { AppState } from "Features/Shared/Rx/RxApp";
import { form as formConfig } from "./Configs/form.config";

import { eventReducers, EventActions } from "./Reducers/event.reducer";
import { venueReducers, VenueActions } from "./Reducers/venue.reducer";
import { confirmFormGroup } from "./Reducers/confirmFormGroup.reducer";
import { insuredReducers, InsuredActions } from "./Reducers/insured.reducer";
import {
  insuranceContactReducers,
  InsuranceContactActions,
} from "./Reducers/insuranceContact.reducer";
import { paymentReducers, PaymentActions } from "./Reducers/payment.reducer";
import { ApplicationForm } from "../../Models/applicationForm.model";

const { load, build } = FormBuilders;

export interface RxApplicationFormActions
  extends EventActions,
    VenueActions,
    InsuredActions,
    InsuranceContactActions,
    PaymentActions,
    RxFormActions {
  confirmFormGroup: (payload: string) => void;
}

/**
 * @description Manages form state of the Coverage Application
 */
export const RxApplicationForm = ({
  initialState,
  venueService,
  stateTaxService,
  paymentService,
  cyberSourceService,
  appState$,
  options,
}: {
  initialState: ControlModels.Form<ApplicationForm>;
  venueService: VenuePresetsService;
  stateTaxService: StateTaxService;
  paymentService: PaymentService;
  cyberSourceService: CyberSourceService;
  appState$: Observable<AppState>;
  options?: { sources?: Observable<Action<unknown>>[] };
}): Reactable<
  ControlModels.Form<ApplicationForm>,
  RxApplicationFormActions
> => {
  // Provide required validators, asyncValidators, and normalizer functions
  const extendedProviders: RxFormProviders = {
    ...providers,
    asyncValidators: {
      validateUsState: validateUsState(appState$),
    },
  };

  const formOptions: RxFormOptions = {
    name: "rxApplicationForm",
    sources: options?.sources || [],
    providers: extendedProviders,
    reducers: {
      ...eventReducers,
      ...venueReducers(venueService, stateTaxService, appState$),
      ...insuredReducers,
      ...insuranceContactReducers,
      ...paymentReducers(paymentService, cyberSourceService),
      confirmFormGroup,
    },
  };

  if (initialState) {
    return load(initialState, formOptions) as Reactable<
      ControlModels.Form<ApplicationForm>,
      RxApplicationFormActions
    >;
  } else {
    return build(formConfig, formOptions) as Reactable<
      ControlModels.Form<ApplicationForm>,
      RxApplicationFormActions
    >;
  }
};
