import React from "react";
import { CheckboxInput } from "@jauntin/react-ui";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import CurrencyHelper from "Helpers/CurrencyHelper";

const SellingLiquorField = ({ input, meta, liquorPrice, disabled }) => (
  <>
    <div className="col-auto w--70 label">
      <DollarsAndCents
        currency={new CurrencyHelper().setValue(liquorPrice).convertFromInt()}
        cents={false}
      />
      <br />
    </div>
    <div className="col">
      <div
        className={
          meta.touched && meta.error ? "label form-error__label" : "label"
        }
      >
        <p className="mb-2">
          Liquor Liability &mdash;
          <span className="font-italic"> not common</span>
        </p>
        <div className="font-14 text-dark font-weight-normal">
          Host liquor liability, which includes coverage for hosted or cash
          bars, is part of your General Liability coverage. However, if you are
          providing alcohol at your event as part of your business of
          manufacturing, distributing, selling, serving or furnishing alcoholic
          beverages, select this Liquor Liability coverage instead. The need for
          Liquor Liability may be pre-selected and required by certain event
          locations.
        </div>
      </div>
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
    <div className="col-auto">
      <CheckboxInput
        input={input}
        meta={meta}
        showErrors={() => false}
        disabled={disabled}
        label={<>&nbsp;</>}
      />
    </div>
  </>
);

export default SellingLiquorField;
