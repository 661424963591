import React from "react";
import { listToString } from "Helpers/ListToString";
import pluralize from "Helpers/Pluralize";

const guestsText = (guestCount) =>
  `${guestCount.toString()}\xa0${pluralize(
    guestCount,
    "person",
    "people"
  )} will attend`;

const vendors = (guests, performers, goodsVendors, foodVendors, exhibitors) => {
  const performersArray = [];
  if (performers !== "0")
    performersArray.push(
      `${Number(performers) > 1 ? "performers" : "a perfomer"}`
    );
  if (goodsVendors !== "0")
    performersArray.push(
      `${Number(goodsVendors) > 1 ? "goods vendors" : "a goods vendor"}`
    );
  if (foodVendors !== "0")
    performersArray.push(
      `${Number(foodVendors) > 1 ? "food vendors" : "a food vendor"}`
    );
  if (exhibitors !== "0")
    performersArray.push(
      `${Number(exhibitors) > 1 ? "exhibitors" : "an exhibitor"}`
    );

  return `${guestsText(guests)}.${
    performersArray.length > 0
      ? ` There will be ${listToString(performersArray)}.`
      : ""
  }`;
};

const GuestsVendors = ({
  aboutEventConfirmed,
  eventTotalGuests,
  eventPerformersCount,
  eventGoods,
  eventFood,
  eventExhibition,
}: {
  aboutEventConfirmed: boolean;
  eventTotalGuests: string;
  eventPerformersCount: string;
  eventGoods: string;
  eventFood: string;
  eventExhibition: string;
}) =>
  aboutEventConfirmed && (
    <li>
      {vendors(
        eventTotalGuests,
        eventPerformersCount,
        eventGoods,
        eventFood,
        eventExhibition
      )}
    </li>
  );

export default GuestsVendors;
