import { group, control } from "@reactables/forms";
import {
  ContactType,
  CompanyTypes,
  JointVentureTypes,
} from "Constants/contactTypes";
import { address } from "./address.config";

export const personName = group({
  controls: {
    firstName: control(["", "required"]),
    lastName: control(["", "required"]),
  },
});

export const insured = group({
  controls: {
    type: control([ContactType.Person]),
    personName, // companyDetails here if type is company
    address,
    confirmed: control([false]),
  },
});

export const companyDetails = group({
  controls: {
    name: control(["", "required"]),
    type: control([CompanyTypes.Individual, "required"]),
    // jointVentureType added here if type is jointVenture
  },
});

export const jointVentureType = control([
  JointVentureTypes.JointVentureCorporation,
  "required",
]);
