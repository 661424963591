import { dateHelpers, mapExtendedErrors } from "@jauntin/utilities";
import { ControlModels, ControlRef } from "@reactables/forms";
import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { DateRange } from "../../../Models/event.model";
import {
  ProviderTypes,
  ProviderFrequency,
} from "../../../../../Constants/providers";
import { EventFrequency } from "../../../../../Constants/eventFrequency";
import pluralize from "../../../../../Helpers/Pluralize";
import { getRootValue, getControl } from "./form.selector";

import { isAfter, addDays } from "date-fns";

const { pickedToBlockedDays, sortDays, filterToUniqueDays } = dateHelpers;

export const getIsEventSectionEnabled = (state: RxCoverageApplicationState) => {
  return getControl(state, "basicCoverage.confirmed").touched;
};

export const getRangeStartDate = (state: RxCoverageApplicationState) => {
  const continousStartDate = (
    state.applicationForm["event.continuous.eventDateRange"]?.value as DateRange
  )?.startDate;

  const weeklyStartDate = (
    state.applicationForm["event.weekly.eventDateRange"]?.value as DateRange
  )?.startDate;

  return continousStartDate || weeklyStartDate;
};

export const getRangeBlockedDays = (state: RxCoverageApplicationState) => {
  if (
    state.applicationForm["event.eventFrequencyField"].value ===
    EventFrequency.Weekly
  ) {
    const pickedDays = state.applicationForm["event.weekly.daysOfWeek"]
      .value as Day[];
    return pickedToBlockedDays(pickedDays);
  } else {
    return [];
  }
};

export const getEventDates = (state: RxCoverageApplicationState) => {
  return getEventDatesFromForm(state.applicationForm);
};

export const getDurationText = (state: RxCoverageApplicationState) => {
  const eventDates = getEventDates(state);
  const days = eventDates.length;
  let firstDay;
  let lastDay;
  if (days) {
    firstDay = new Date(eventDates[0]);
    lastDay = new Date(eventDates[eventDates.length - 1]);
  } else {
    firstDay = new Date();
    lastDay = new Date();
  }
  if (days === 1)
    return `1 day long - ${dateHelpers.lettersAndNumbers(firstDay)}`;
  return `${days} days long - ${dateHelpers.lettersAndNumbers(
    firstDay
  )} to ${dateHelpers.lettersAndNumbers(lastDay)}`;
};

export const getGuestsText = (state: RxCoverageApplicationState) => {
  const guestCount = getTotalEventAttendees(state);

  return `${guestCount.toString()}\xa0${pluralize(
    guestCount,
    "person",
    "people"
  )} will attend.`;
};

export const calculateEventDates = ({
  daysOfWeekField,
  eventDateRangeField,
}: {
  daysOfWeekField?: Day[];
  eventDateRangeField: DateRange;
}) => {
  let { startDate, endDate } = { ...eventDateRangeField };
  if (!startDate && !endDate) {
    return [];
  }

  const ignoredDays =
    (daysOfWeekField?.length && pickedToBlockedDays(daysOfWeekField)) || [];

  if (!startDate) {
    startDate = endDate;
  } else if (!endDate) {
    endDate = startDate;
  }
  const days = [];
  let currentDate = new Date(startDate);
  while (!isAfter(currentDate, new Date(endDate))) {
    if (!ignoredDays.includes(currentDate.getDay())) {
      days.push(currentDate);
    }
    currentDate = addDays(currentDate, 1);
  }
  return sortDays(filterToUniqueDays(days)).map((date) => date.toISOString());
};

export const getTotalEventAttendees = (state: RxCoverageApplicationState) => {
  return (
    (state.applicationForm["event.eventDailyGuests"].value as number) *
    getEventDates(state).length
  );
};

export const getEventDatesFromForm = (
  form: ControlModels.BaseForm<unknown> | ControlModels.Form<unknown>
) => {
  switch (form["event.eventFrequencyField"].value) {
    case EventFrequency.Continuous:
      return calculateEventDates({
        eventDateRangeField: form[
          `event.${EventFrequency.Continuous}.eventDateRange`
        ].value as DateRange,
      });
    case EventFrequency.Weekly:
      return calculateEventDates({
        daysOfWeekField: form[`event.${EventFrequency.Weekly}.daysOfWeek`]
          .value as Day[],
        eventDateRangeField: form[
          `event.${EventFrequency.Weekly}.eventDateRange`
        ].value as DateRange,
      });
    case EventFrequency.Custom:
      return form[`event.${EventFrequency.Custom}.eventDates`]
        .value as string[];
    default:
      return [];
  }
};

export const getProviderListMeta = (
  state: RxCoverageApplicationState,
  providerType: ProviderTypes,
  providerName: string
) => {
  const frequencyRef: ControlRef = ["event", providerType, "frequency"];
  const providerListRef: ControlRef = ["event", providerType, "providerList"];
  const arrayControlExtendedErrors =
    state.applicationForm[frequencyRef.join(".")].value ===
      ProviderFrequency.Somedays &&
    state.applicationForm[providerListRef.join(".")]
      ? mapExtendedErrors(
          state.applicationForm[
            providerListRef.join(".")
          ] as ControlModels.FormControl<unknown>,
          {
            messages: {
              atLeastOneProviderName: `Please enter a name for each ${providerName}.`,
              atLeastOneProviderDays: `Please enter at least 1 day for each ${providerName}.`,
            },
          }
        )
      : null;

  return arrayControlExtendedErrors;
};

export const getShowDateList = (state: RxCoverageApplicationState) =>
  state.applicationForm["event.eventFrequencyField"].value !==
    EventFrequency.Continuous && getEventDates(state).length > 0;

export const getNumberedDaysOfWeek = (state: RxCoverageApplicationState) => {
  const {
    event: { weekly },
  } = getRootValue(state);

  return (weekly?.daysOfWeek || [])
    .map((value, index) => (value ? index : null))
    .filter((value) => value !== null);
};

export const getProviderHasFrequency = (
  state: RxCoverageApplicationState,
  providerType: ProviderTypes
) => Boolean(getControl(state, `event.${providerType}.frequency`));
