import React from "react";
import { Button } from "@jauntin/react-ui";
import { Alert } from "react-bootstrap";
import { Field } from "@reactables/react-forms";
import { VenueSearchTypes } from "Constants/venueSearchTypes";
import ButtonEdit from "Features/Shared/Components/ButtonEdit";
import PopoverTrigger from "Features/Shared/Components/Popover";
import RadioBtn from "./RadioBtn";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import SpecialTaxFields from "./SpecialTaxFields";
import SearchByVenueCode from "./SearchByVenueCode";
import SearchByAddress from "./SearchByAddress";
import ManualVenueAddress from "./ManualVenueAddress";
import * as VenueSelectors from "../../../Rx/RxCoverageApplication/Selectors/venue.selector";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

const Venue = ({
  rxCoverageApplication,
  onConfirm,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
  onConfirm: () => void;
}) => {
  const [state, actions] = rxCoverageApplication;
  const showTaxFields = VenueSelectors.getShowTaxFields(state);
  const showManualAddressOption =
    VenueSelectors.getShowManualAddressOption(state);
  const parsedVenueAddress = VenueSelectors.getParsedVenueAddress(state);
  const knownVenue = VenueSelectors.getKnownVenue(state);

  const {
    venue: { confirmed, byVenueCode, bySearch, byManualAddress },
  } = FormSelectors.getRootValue(state);

  const apiError = FormSelectors.getControl(
    state,
    "venue.venueSearchResults.apiError"
  ).errors.notTrue;

  const isBlocked = FormSelectors.getControl(
    state,
    "venue.venueSearchResults.knownVenue"
  ).errors.blockedVenue;

  return (
    <>
      <div className={`component enabled'}`}>
        <div className="form-row justify-content-between">
          <div className="col-auto my-auto">
            <h3 className="d-inline-block">Venue</h3>
            <PopoverTrigger
              styleClass="text-dark p-1 pb-2 d-inline popover-trigger"
              content="For events with locations that span multiple addresses (parades, festivals, and walks or runs) use the address of the town or city hall where your location and permit is filed."
            />
          </div>
          <ButtonEdit
            edit={() => {
              actions.applicationForm.updateValues({
                controlRef: ["venue", "confirmed"],
                value: false,
              });
            }}
            show={confirmed}
          />
        </div>
        <div className="card">
          <div className="card-body">
            {confirmed ? (
              <>
                <>
                  {knownVenue && (
                    <p className="venueCode">
                      {knownVenue.facility.code} - {knownVenue.venue.venueCode}
                    </p>
                  )}
                  <p>{parsedVenueAddress.addressComponents.companyName}</p>
                  <p>{parsedVenueAddress.address}</p>
                </>
              </>
            ) : (
              <>
                <div className="form-group form-row">
                  <Field
                    name="venue.searchType"
                    component={RadioBtn}
                    text="Use a venue code"
                    value={VenueSearchTypes.ByVenueCode}
                    cols={showManualAddressOption ? "4" : "6"}
                    onChange={() =>
                      actions.applicationForm.selectVenueSearchType(
                        VenueSearchTypes.ByVenueCode
                      )
                    }
                  />
                  <Field
                    name="venue.searchType"
                    component={RadioBtn}
                    text="Search for a venue"
                    value={VenueSearchTypes.BySearch}
                    cols={showManualAddressOption ? "4" : "6"}
                    onChange={() =>
                      actions.applicationForm.selectVenueSearchType(
                        VenueSearchTypes.BySearch
                      )
                    }
                  />
                  {showManualAddressOption && (
                    <Field
                      name="venue.searchType"
                      component={RadioBtn}
                      text="Enter my own venue information"
                      value={VenueSearchTypes.ByManualAddress}
                      cols="4"
                      onChange={() =>
                        actions.applicationForm.selectVenueSearchType(
                          VenueSearchTypes.ByManualAddress
                        )
                      }
                    />
                  )}
                </div>

                <>
                  {byVenueCode && (
                    <SearchByVenueCode
                      rxCoverageApplication={rxCoverageApplication}
                    />
                  )}

                  {bySearch && (
                    <SearchByAddress
                      rxCoverageApplication={rxCoverageApplication}
                    />
                  )}
                  {byManualAddress && (
                    <ManualVenueAddress
                      rxCoverageApplication={rxCoverageApplication}
                    />
                  )}
                  {FormSelectors.getControl(state, "venue").errors
                    .kentuckyCityNotFound && (
                    <Alert variant="danger" className="mt-3">
                      <i className="far fa-exclamation-circle alert__icon" />
                      <div>Kentucky city not found.</div>
                    </Alert>
                  )}

                  {isBlocked && (
                    <Alert variant="info" className="mt-3">
                      <i className="far fa-exclamation-circle alert__icon" />
                      <div className="alert__text">
                        Unfortunately, we cannot provide a policy from our
                        online special event insurance program for the location
                        provided. For more information, please call{" "}
                        <span className="text-nowrap">844-747-6240</span>.
                      </div>
                    </Alert>
                  )}
                  {apiError && (
                    <Alert variant="danger" className="mt-3">
                      <i className="far fa-exclamation-circle alert__icon" />
                      <div className="alert__text">
                        Something went wrong. Please try again.
                      </div>
                    </Alert>
                  )}

                  {showTaxFields && (
                    <>
                      <SpecialTaxFields
                        rxCoverageApplication={rxCoverageApplication}
                      />
                    </>
                  )}

                  {parsedVenueAddress.addressComponents.companyName &&
                    !byManualAddress &&
                    !isBlocked &&
                    !VenueSelectors.getIsCheckingAddressSearch(state) &&
                    !apiError && (
                      <>
                        <div className="font-weight-bold">
                          Please verify the following address before confirming
                          the venue
                        </div>
                        <div>
                          <i className="fal fa-check-circle text-primary" />
                          &nbsp;
                          <span className="font-italic">
                            {parsedVenueAddress.addressComponents.companyName},
                            {parsedVenueAddress.address}
                          </span>
                        </div>
                      </>
                    )}
                  <hr />
                  <div className="float-right">
                    <Button
                      disabled={!FormSelectors.getControl(state, "venue").valid}
                      onClick={() => {
                        actions.applicationForm.confirmFormGroup("venue");
                        onConfirm();
                      }}
                      text="Next"
                      className="btn-primary"
                    />
                  </div>
                </>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Venue;
