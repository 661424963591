import { Action } from "@reactables/core";
import { OperatorFunction, from, EMPTY, Observable } from "rxjs";
import { mergeMap, map, catchError, filter } from "rxjs/operators";
import { mapCertHolders } from "./mapCertholders.operator";
import FacilityNameService from "Services/FacilityNameService";
import VenuePresetsService from "Services/VenuePresetsService";

const FACILITY_CODE_PARAM = "f";

/**
 * @description Provided a query string:
 * Operator looks for a facility code param and looks up Facility Name
 */
export const handleFacilityReferral =
  (
    facilityNameService: FacilityNameService,
    venuePresetsService: VenuePresetsService
  ): OperatorFunction<
    string,
    Action<{ facilityCode: string; facilityName: string }>
  > =>
  (referralParams$) =>
    referralParams$.pipe(
      filter((referralParams) => Boolean(referralParams)),
      map((referralParams) => {
        const rawParams = referralParams.toLowerCase();
        const urlParams = new URLSearchParams(rawParams);
        const facilityCode = urlParams.get(FACILITY_CODE_PARAM);

        return facilityCode;
      }),
      facilityNameLookUp(facilityNameService),
      mergeMap(({ facilityCode, facilityName }) =>
        from(venuePresetsService.getAdditionalCertHolders(facilityCode)).pipe(
          mapCertHolders,
          map((facilityCertHolders: string[]) => ({
            type: "facilityReferralSuccess",
            payload: {
              facilityCode,
              facilityName,
              facilityCertHolders,
            },
          }))
        )
      )
    );

/**
 * @description Provided a valid facility code:
 * Operator looks looks up Facility Name
 */
export const facilityNameLookUp =
  (facilityNameService: FacilityNameService) =>
  (facilityCode$: Observable<string>) =>
    facilityCode$.pipe(
      filter((facilityCode) => {
        return facilityCode && /^([a-z0-9]){3,4}$/i.test(facilityCode);
      }),
      mergeMap((facilityCode) =>
        from(
          facilityNameService.getFacilityNameFromFacilityCode(facilityCode)
        ).pipe(
          map(({ data }: { data: { facility_name: string } }) => ({
            facilityCode,
            facilityName: data.facility_name,
          })),
          catchError(() => EMPTY)
        )
      )
    );
