import React from "react";
import GatherGuard from "../../../Assets/Images/gatherguard-lockup.svg";

const MaintenanceMode = () => (
  <div className="container text-center pt-2 mt-2">
    <img
      src={GatherGuard}
      alt="Logo"
      className="img-fluid my-4 pt-5 col-md-8 col-lg-6"
    />
    <div className="card col-lg-8 mx-auto my-5">
      <div className="card-body mx-auto text-center">
        <div className="col mx-auto">
          <h2 className="m-0 font-weight-bold">Site under maintenance.</h2>
          <div className="mb-4 mt-2 mx-auto">
            Site is currently under maintenance. Please check again in a few
            minutes.
          </div>
          <div className="mb-4 mt-2 col-lg-8 mx-auto">
            Thank you for your patience.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MaintenanceMode;
