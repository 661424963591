import React from "react";
import { Modal } from "react-bootstrap";

const HelpModal = ({ show, handleClose }) => (
  <Modal
    show={show}
    onHide={handleClose}
    dialogClassName="modal--width-lg px-md-5"
  >
    <Modal.Header closeButton>
      <h2 className="modal-title h3">Performers, Vendors and Exhibitors</h2>
    </Modal.Header>
    <Modal.Body>
      <div className="mb-3">
        <div className="m-0 font-weight-bold">Is this vendor insurance?</div>
        No. This policy does not cover the performer, vendor or exhibitor. The
        coverage protects you, for the added risk of having them at your event.
        If they are a professional company, they should buy and provide their
        own insurance specific to their business, and provide you with proof of
        insurance.
      </div>
      <div className="mb-3">
        <div className="m-0 font-weight-bold">
          I am having a photographer. Where do I add them?
        </div>
        Since a photographer is not a performer, exhibitor or selling good or
        food at your event, you don’t need to include them in this section. They
        should be included in the total attendance for the event if they will be
        on-site at the venue during your event days.
      </div>
      <div className="mb-3">
        <div className="m-0 font-weight-bold">
          I am having a florist. Where do I add them?
        </div>
        Since a florist is not a performer, exhibitor or selling good or food at
        your event, you don’t need to include them in this section. They should
        be included in the total attendance for the event only if they will be
        at the event during any of the event dates (for example, set up and take
        down days).
      </div>
      <div className="mb-3">
        <div className="m-0 font-weight-bold">
          Do I need to include service providers hired by the venue I am
          renting?
        </div>
        No. The intention of this section is to capture any non-professionals
        you have personally hired who could increase the risk associated with
        your event. Caterers or bartenders employed by the venue hired for your
        event do not need to be counted here. If your uncle plays in a band on
        the weekends, and is playing at your event for a fee, but does not carry
        band insurance, count him as 1 performer here. If your uncle is
        volunteering his musical services, he would not be counted as a
        performer.
      </div>
    </Modal.Body>
  </Modal>
);

export default HelpModal;
