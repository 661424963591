import { Modal } from "react-bootstrap";
import { lgUp } from "Helpers/screenWidth";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import {
  getCertHolders,
  getShowVenueCertPendingMsg,
} from "Features/CoverageApplication/Rx/RxCoverageApplication/Selectors/venue.selector";

const ModalCertificateHolders = ({
  show,
  handleClose,
  rxCoverageApplication: [state],
}: {
  show: boolean;
  handleClose: () => void;
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const certHolders = getCertHolders(state);
  const showVenueCertPendingMsg = getShowVenueCertPendingMsg(state);

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      dialogClassName="modal--certificateHolders"
    >
      <Modal.Header
        className={`w-100 border-0 p-0 mb-5 ${
          !lgUp() ? "d-flex flex-column-reverse" : ""
        }`}
        closeButton
      >
        <h4 className="font-weight-bold m-0">
          Preview Additional Certificate Holders
        </h4>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="container p-0">
          <p className="mb-3">
            Review the information displayed below. If you need to add someone
            else, please contact customer service.
          </p>
          <div className="row p-1">
            {certHolders.map((address, i) => (
              <div key={i} className="col-sm-12 col-md-6 modal--certHolders">
                {address.split("\n").map((line, j) => (
                  <div key={j} className={j === 0 ? "font-weight-bold" : ""}>
                    {line}
                  </div>
                ))}
              </div>
            ))}
            {!certHolders.length && (
              <div className={`col-sm-12 col-md modal--certHolders`}>
                <em>
                  Additional insured information may show here once a venue is
                  selected
                </em>
              </div>
            )}
            {showVenueCertPendingMsg && (
              <div className="col-sm-12 col-md-6 modal--certHolders">
                <em>Venue information will show here when selected</em>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCertificateHolders;
