import React from "react";
import { CheckboxInput } from "@jauntin/react-ui";
import DollarsAndCents from "Features/Shared/Components/DollarsAndCents";
import CurrencyHelper from "Helpers/CurrencyHelper";

const DamageToRentedPropertyField = ({
  input,
  meta,
  damageToRentedPropertyPrice,
  disabled,
}) => (
  <>
    <div className="col-auto w--70 label">
      <DollarsAndCents
        currency={new CurrencyHelper()
          .setValue(damageToRentedPropertyPrice)
          .convertFromInt()}
        cents={false}
      />
    </div>
    <div className="col">
      <div
        className={
          meta.touched && meta.error ? "label form-error__label" : "label"
        }
      >
        <p className="mb-2">Damage to Rented Premises</p>
        <div className="font-14 text-dark font-weight-normal">
          $750,000 increase in limit to total $1 million. Protection for damage
          that you or your guests may cause to the event space.
        </div>
      </div>
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
    <div className="col-auto">
      <CheckboxInput
        input={input}
        meta={meta}
        showErrors={() => false}
        disabled={disabled}
        label={<>&nbsp;</>}
      />
    </div>
  </>
);

export default DamageToRentedPropertyField;
