// Helper functions that can identify or filter event types

// Event types that have icons for
const iconEventTypes = (eventTypes, sortedEventTypes, numberOfIconEventTypes) =>
  sortEventTypes(eventTypes, sortedEventTypes)
    .slice(0, numberOfIconEventTypes)
    .map((event) => ({
      ...event,
      name: event.name.replace(/\//g, "/ "),
    }));

// Event types that are in the dropdown list (don't have icons)
const listEventTypes = (eventTypes, sortedEventTypes, numberOfIconEventTypes) =>
  sortEventTypes(eventTypes, sortedEventTypes).slice(numberOfIconEventTypes);

// Sort event types depending on venue settings
const sortEventTypes = (eventTypes, sortedEventTypes) => {
  if (sortedEventTypes?.length > 0) {
    return sortedEventTypes
      .map((se) => eventTypes.find((et) => et.identifier === se.identifier))
      .concat(
        eventTypes.filter(
          (et) =>
            !sortedEventTypes.some((se) => se.identifier === et.identifier)
        )
      );
  }

  return eventTypes;
};

// From all eventTypes, get the one with id
const getById = (id, eventTypes) => eventTypes.find((m) => m.identifier === id);

export { iconEventTypes, listEventTypes, getById };
