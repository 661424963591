import { ApplicationForm } from "../../../Models/applicationForm.model";
import { RxCoverageApplicationState } from "../RxCoverageApplication";

export const getInsuredName = (state: RxCoverageApplicationState) => {
  const { insured } = state.applicationForm.root.value as ApplicationForm;

  if (insured.personName) {
    return `${insured.personName.firstName}-${insured.personName.firstName}`;
  } else if (insured.companyDetails) {
    return insured.companyDetails.name;
  }

  return "";
};
