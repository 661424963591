import { group, control } from "@reactables/forms";

export const additionalCoverages = group({
  controls: {
    terrorism: control([true]),
    liquorLiability: control([false]),
    damageToRentedProperty: control([false]),
    confirmed: control([false]),
  },
});
