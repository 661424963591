import React from "react";
import { Modal } from "react-bootstrap";
import {
  RxQuoteSaver,
  SaveQuoteData,
  SaveQuotePayload,
} from "@jauntin/reactables";
import { Field, Form } from "@reactables/react-forms";
import { Alert } from "react-bootstrap";
import { useReactable } from "@reactables/react-helpers";
import { Button, AsyncButton, EmailInput } from "@jauntin/react-ui";
import PriceService from "Services/PriceService";
import API from "Helpers/API";
import providers from "Helpers/formProviders";
import { RxCoverageApplicationState } from "../../Rx/RxCoverageApplication/RxCoverageApplication";

const ModalSaveQuote = ({
  show,
  handleClose,
  data,
}: {
  show: boolean;
  handleClose: () => void;
  data: SaveQuoteData<RxCoverageApplicationState>;
}) => {
  const [state, actions] = useReactable(
    RxQuoteSaver<RxCoverageApplicationState>,
    new PriceService(new API()),
    {
      resetControls: [
        ["insured"],
        ["insuranceContact"],
        ["payment"],
        ["purchase"],
      ],
      postFormReset: (
        payload: SaveQuotePayload<RxCoverageApplicationState>
      ) => ({
        ...payload,
        data: {
          ...payload.data,
          state: {
            ...payload.data.state,
            eventTypes: {
              ...payload.data.state.eventTypes,
              data: null,
            },
          },
        },
      }),
      providers,
    }
  );

  if (!state) return;

  const { success, loading, error, emailForm } = state;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      {success ? (
        <>
          <Modal.Body className="pb-4">
            <Modal.Header className="w-100 border-0 pb-0" />
            <div className="mt-4 text-center">
              A link for the quote has been sent to your email.
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4">
            <Button
              className="btn-primary"
              type="button"
              onClick={handleClose}
              text="Done"
            />
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body className="pb-4">
            <Modal.Header className="w-100 border-0 pb-0" />
            <div className="mt-4">
              A link for the quote will be sent to your email.
            </div>
            <Form rxForm={[emailForm, actions.emailForm]}>
              <Field
                name="root"
                component={EmailInput}
                inputClassName="form-control-lg"
                className="mb-0"
              />
            </Form>
            {error && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  Sorry, we were unable to process your request. Please try
                  again or contact us at{" "}
                  <span className="text-nowrap">(844) 747-6240</span> or
                  gatherguard@intactinsurance.com for further assistance.{" "}
                </div>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4">
            <Button
              onClick={handleClose}
              className="btn btn-outline-primary"
              text="Cancel"
            />
            <AsyncButton
              onClick={() => {
                actions.saveQuote({
                  email: emailForm.root.value as string,
                  data,
                });
              }}
              spinning={loading}
              disabled={!emailForm.root.valid}
              className="btn btn-primary d-flex"
            >
              Save Quote
            </AsyncButton>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
export default ModalSaveQuote;
