import React from "react";
import { Col } from "react-bootstrap";
import { mapExtendedErrors } from "@jauntin/utilities";

const ProviderDaysField = ({ input, meta, onChange }) => {
  meta = mapExtendedErrors(meta);
  return (
    <div className="form-row">
      <Col xs="auto">
        <input
          {...input}
          className={`form-control form-control-lg w--100 ${
            meta.touched && meta.error ? "border-danger" : ""
          }`}
          aria-label="Provider days"
          maxLength="3"
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            } else {
              input?.onChange && input.onChange(e);
            }
          }}
        />
      </Col>
      {meta.touched && meta.error && (
        <Col className="form-error my-auto">{meta.error}</Col>
      )}
    </div>
  );
};

export default ProviderDaysField;
