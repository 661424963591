import { NavLink } from "react-router-dom";
import { Breadcrumb as IBreadcrumb } from "../../Models/breadcrumb.model";

const Body = ({
  step,
  text,
  onUnderwritingPage,
}: {
  step: number;
  text: string;
  onUnderwritingPage: boolean;
}) => (
  <>
    <div className="breadcrumb__entry">
      <div className="breadcrumb-content">
        <div
          className={`breadcrumb-content__step ${
            step === 1 && onUnderwritingPage
              ? "breadcrumb-content__step--fill"
              : ""
          }`}
        >
          <div className="breadcrumb-content__step-number">{step}</div>
        </div>
        <div className={`breadcrumb-content__text`}>{text}</div>
      </div>
    </div>
  </>
);

const Breadcrumb = ({
  step,
  text,
  disabled,
  link,
  onUnderwritingPage,
  isActive = false,
  disableNavigation = false,
}: {
  disabled: boolean;
  currentStep: number;
  onUnderwritingPage: boolean;
  isActive?: boolean;
  disableNavigation?: boolean;
} & IBreadcrumb) => {
  const wrapperClassName = `breadcrumb ${disabled ? "disabled" : ""} ${
    disableNavigation ? "cursor-default" : ""
  } `;

  return (
    <div className={`breadcrumb`}>
      {disabled || disableNavigation ? (
        <div className={wrapperClassName + ` ${isActive ? "active" : ""}`}>
          <Body
            step={step}
            text={text}
            onUnderwritingPage={onUnderwritingPage}
          />
        </div>
      ) : (
        <>
          <NavLink
            to={link}
            className={wrapperClassName}
            isActive={() => isActive}
            activeClassName="active"
          >
            <Body
              step={step}
              text={text}
              onUnderwritingPage={onUnderwritingPage}
            />
          </NavLink>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
