import React from "react";
import { useCallback } from "react";
import { TextInput, Button, useTimeTravel } from "@jauntin/react-ui";
import { useReactable } from "@reactables/react-helpers";
import { Field } from "@reactables/react-forms";
import { RxToggle } from "@jauntin/reactables";
import { DateRangePicker, CustomDatePicker } from "@jauntin/react-ui";
import {
  disabledDatesForDateRangePicker,
  disabledDatesForCustomDatePicker,
} from "Helpers/DateHelpers";
import ButtonEdit from "Features/Shared/Components/ButtonEdit";
import { lgUp } from "Helpers/screenWidth";
import { EventFrequency } from "Constants/eventFrequency";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import EventFrequencyField from "./EventFrequencyField";
import DaysOfWeekField from "./DaysOfWeekField";
import GuestsField from "./GuestsField";
import PerformersField from "./PerformersField";
import GoodsField from "./GoodsField";
import FoodField from "./FoodField";
import ExhibitorsField from "./ExhibitorsField";
import DateList from "./DateList";
import EventView from "./View";
import HelpModal from "./HelpModal";
import * as VenueSelectors from "../../../Rx/RxCoverageApplication/Selectors/venue.selector";
import * as EventSelectors from "../../../Rx/RxCoverageApplication/Selectors/event.selector";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";

export const Event = ({
  rxCoverageApplication,
  onConfirm,
  enableTimeTravel = false,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
  onConfirm: () => void;
  enableTimeTravel: boolean;
}) => {
  const [state, actions] = rxCoverageApplication;

  const {
    event: { eventFrequencyField: eventFrequency, confirmed },
  } = FormSelectors.getRootValue(state);

  const utcOffset = VenueSelectors.getUtcOffset(state);
  const enabled = EventSelectors.getIsEventSectionEnabled(state);
  const rangeStartDate = EventSelectors.getRangeStartDate(state);
  const rangeBlockedDays = EventSelectors.getRangeBlockedDays(state);
  const eventDates = EventSelectors.getEventDates(state);
  const eventTotalGuests = EventSelectors.getTotalEventAttendees(state);
  const showDateList = EventSelectors.getShowDateList(state);
  const mobileDisplay = !lgUp();

  useTimeTravel(enableTimeTravel && !confirmed);

  const [showHelpModal, helpModalToggle] = useReactable(RxToggle);

  const disabledDatesRange = useCallback(
    (day: Date) => {
      return disabledDatesForDateRangePicker({
        blockedDays: rangeBlockedDays,
        startDate: new Date(rangeStartDate),
        venueUtcOffset: utcOffset,
      })(day);
    },
    [rangeStartDate, utcOffset, rangeBlockedDays]
  );

  const disabledDatesCustom = useCallback(
    (day: Date) =>
      disabledDatesForCustomDatePicker({
        venueUtcOffset: utcOffset,
        eventDates,
      })(day),
    [utcOffset, eventDates]
  );

  return (
    <>
      <div className={`component ${enabled ? "enabled" : "disabled"}`}>
        <div className="form-row justify-content-between">
          <div className="col-auto my-auto">
            <h3>About Your Event</h3>
          </div>
          <ButtonEdit
            edit={() => {
              actions.applicationForm.updateValues({
                controlRef: ["event", "confirmed"],
                value: false,
              });
            }}
            show={confirmed}
          />
        </div>
        <div className="card">
          <div className="card-body">
            {confirmed ? (
              <EventView rxCoverageApplication={rxCoverageApplication} />
            ) : (
              <>
                <Field
                  component={TextInput}
                  disabled={!enabled}
                  name="event.eventName"
                  label="Please add a name for your event"
                  ariaLabel="Event name"
                  placeholder="Event name"
                  maxLength={50}
                  wrapperClassName="mb-1"
                  inputClassName="form-control-lg"
                  lengthClassName="eventName--charCount"
                />
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 col-xl-6">
                      <Field
                        component={EventFrequencyField}
                        name="event.eventFrequencyField"
                        disabled={!enabled}
                        onChange={(eventFrequency: EventFrequency) =>
                          actions.applicationForm.selectEventFrequency(
                            eventFrequency
                          )
                        }
                      />
                    </div>
                    {eventFrequency === EventFrequency.Weekly && (
                      <div className="col-12 col-xl-6">
                        <DaysOfWeekField name="event.weekly.daysOfWeek" />
                      </div>
                    )}
                  </div>
                  <div className="mb-2">
                    In your selection, include the days required for setup and
                    take down for the event.{" "}
                    <span className="font-weight-bold">
                      If your event will extend past midnight, include the
                      following day.
                    </span>
                  </div>
                  {eventFrequency === EventFrequency.Continuous && (
                    <>
                      <Field
                        component={DateRangePicker}
                        name={`event.${EventFrequency.Continuous}.eventDateRange`}
                        disabledDates={disabledDatesRange}
                        numberOfMonths={mobileDisplay ? 1 : 2}
                        inputClassName="form-control-lg"
                        onChange={
                          actions.applicationForm.selectContinuousEventDates
                        }
                      />
                    </>
                  )}
                  {eventFrequency === EventFrequency.Weekly &&
                    FormSelectors.getControl(state, "event.weekly.daysOfWeek")
                      .valid && (
                      <>
                        <Field
                          component={DateRangePicker}
                          name={`event.${EventFrequency.Weekly}.eventDateRange`}
                          disabledDates={disabledDatesRange}
                          numberOfMonths={mobileDisplay ? 1 : 2}
                          inputClassName="form-control-lg"
                          onChange={
                            actions.applicationForm.selectWeeklyEventDates
                          }
                        />
                      </>
                    )}
                  {eventFrequency === EventFrequency.Custom && (
                    <div className="d-inline-flex">
                      <Field
                        component={CustomDatePicker}
                        name={`event.${EventFrequency.Custom}.eventDates`}
                        disabledDates={disabledDatesCustom}
                      />
                    </div>
                  )}

                  {showDateList && (
                    <div className="mb-2">
                      <DateList
                        dates={eventDates}
                        deleteDate={actions.applicationForm.deleteDate}
                      />
                    </div>
                  )}

                  <Field
                    component={GuestsField}
                    name="event.eventDailyGuests"
                    disabled={false}
                    eventTotalGuests={eventTotalGuests}
                    eventDates={eventDates}
                    mobileDisplay={mobileDisplay}
                    hasMaxAttendeesError={
                      FormSelectors.getControl(state, "event").errors
                        .maxAttendees
                    }
                  />
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="m-0">Performers, Vendors and Exhibitors</h4>
                    <span
                      tabIndex={!enabled ? -1 : 0}
                      className="span-link ml-2 small"
                      role="button"
                      onClick={helpModalToggle.toggleOn}
                      onKeyPress={(e) => {
                        if (
                          e.key === "Enter" ||
                          e.key === " " ||
                          e.key === "Spacebar"
                        ) {
                          helpModalToggle.toggleOn();
                        }
                      }}
                    >
                      Help with this section.
                    </span>
                  </div>
                  <div className="mb-4">
                    Leave at zero if there will be none, or, if they will
                    provide their own insurance. This coverage offers protection
                    for <span className="font-weight-bold">you</span>, should a
                    claim arise as a result of uninsured performer, vendor, or
                    exhibitor negligence. Depending on your event, only some or
                    none of these categories will apply.
                  </div>

                  <PerformersField
                    rxCoverageApplication={rxCoverageApplication}
                  />
                  <GoodsField rxCoverageApplication={rxCoverageApplication} />
                  <FoodField rxCoverageApplication={rxCoverageApplication} />
                  <ExhibitorsField
                    rxCoverageApplication={rxCoverageApplication}
                  />
                  <hr />
                  <div className="float-right">
                    <Button
                      disabled={
                        !FormSelectors.getControl(state, "event").valid ||
                        !enabled
                      }
                      onClick={() => {
                        actions.applicationForm.confirmFormGroup("event");
                        onConfirm && onConfirm();
                      }}
                      text="Next"
                      className="btn-primary"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <HelpModal show={showHelpModal} handleClose={helpModalToggle.toggleOff} />
    </>
  );
};
