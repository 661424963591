import React, { useEffect, useState } from "react";

const Icon = ({ image = "icon-default.svg", lg = false, alt = "" }) => {
  const [IconImage, setIconImage] = useState(() => () => <></>);
  useEffect(() => {
    setIconImage(() => () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const icon = require(`../../../Assets/Images/${image}`);
      return <img className="icon-wrapper__icon-image" alt={alt} src={icon} />;
    });
  }, [image, alt]);
  return (
    <div className={`icon-wrapper ${lg ? "icon-wrapper--lg" : ""}`}>
      <IconImage />
    </div>
  );
};

export default Icon;
