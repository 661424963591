import { RxCoverageApplicationState } from "../RxCoverageApplication";

export const getApplicationPageConfirmed = (
  state: RxCoverageApplicationState
) => {
  const form = state.applicationForm;

  const controls = [
    "venue.confirmed",
    "basicCoverage.confirmed",
    "event.confirmed",
    "additionalCoverages.confirmed",
  ];

  return controls.every((control) => form[control].value);
};

export const getCheckoutPageConfirmed = (state: RxCoverageApplicationState) => {
  const form = state.applicationForm;

  const controls = [
    "insured.confirmed",
    "insuranceContact.confirmed",
    "payment.confirmed",
  ];

  return controls.every((control) => form[control].value);
};
