// The ONEBEACON API URL formatted to match the local address when in dev
// used to avoid issues accessing hosts from the network
// e.g. if localhost, but testing from a phone using local IP such as 192.168.1.1, assume the API is also the same IP
const regex = /(localhost|127.0.0.1)/;
const url = new URL(process.env.REACT_APP_ONEBEACON_API_LOCATION);

if (regex.test(url.hostname)) {
  url.hostname = window.location.hostname;
}
export const apiUrl = url.href;
