export enum ContactType {
  Person = "Person",
  Company = "company",
}

export enum CompanyTypes {
  Individual = "Individual",
  Corporation = "Corporation",
  JointVenture = "Joint Venture",
  Partnership = "Partnership",
  LLC = "LLC",
  Other = "Other",
}

export enum JointVentureTypes {
  JointVentureCorporation = "Joint Venture Corporation",
  JointVentureLLC = "Joint Venture LLC",
}

export type AddressContactType =
  | ContactType.Person
  | CompanyTypes.Individual
  | CompanyTypes.Corporation
  | CompanyTypes.Partnership
  | CompanyTypes.LLC
  | CompanyTypes.Other
  | JointVentureTypes.JointVentureCorporation
  | JointVentureTypes.JointVentureLLC
  | "none";
