import React from "react";
import { Field } from "@reactables/react-forms";
import PopoverTrigger from "Features/Shared/Components/Popover";
import RadioInput from "Features/Shared/Components/RadioInput";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as VenueSelectors from "../../../Rx/RxCoverageApplication/Selectors/venue.selector";

const SpecialTaxFields = ({
  rxCoverageApplication: [state, actions],
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const isNotKentuckyEntity = VenueSelectors.getIsNotKentuckyEntity(state);
  const taxRegions = VenueSelectors.getTaxRegions(state);

  const taxFields = "venue.venueSearchResults.taxFields";
  return (
    <>
      <>
        <div className="form-group">
          <div className="mb-1">
            Is the insured a government entity, resident education, or
            charitable institution qualifying under 501(c)(3)?
            <span className="ml-1">
              <PopoverTrigger content="Some qualifying organizations, like schools, may be exempt from certain charges." />
            </span>
          </div>

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.federalEntity`}
              value="yes"
              id="federalEntityYes"
              component={RadioInput}
              className="custom-control-input"
            />
            <label
              className="custom-control-label label"
              htmlFor="federalEntityYes"
            >
              Yes
            </label>
          </div>

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.federalEntity`}
              value="no"
              id="federalEntityNo"
              component={RadioInput}
              className="custom-control-input"
            />
            <label
              className="custom-control-label label"
              htmlFor="federalEntityNo"
            >
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="mb-1">
            Is the insured the State of Kentucky, one of its agencies or
            political subdivisions?
          </div>

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.kentuckyStateEntity`}
              value="yes"
              id={`${taxFields}.kentuckyStateEntityYes`}
              component={RadioInput}
              type="radio"
              className="custom-control-input"
              onChange={() => {
                actions.applicationForm.selectKentuckyStateEntity("yes");
              }}
            />
            <label
              className="custom-control-label label"
              htmlFor={`${taxFields}.kentuckyStateEntityYes`}
            >
              Yes
            </label>
          </div>

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.kentuckyStateEntity`}
              value="no"
              id={`${taxFields}.kentuckyStateEntityNo`}
              component={RadioInput}
              className="custom-control-input"
              onChange={() => {
                actions.applicationForm.selectKentuckyStateEntity("no");
              }}
            />
            <label
              className="custom-control-label label"
              htmlFor={`${taxFields}.kentuckyStateEntityNo`}
            >
              No
            </label>
          </div>
        </div>

        {isNotKentuckyEntity && taxRegions.length > 0 && (
          <>
            <div className="mb-1">
              In which district is this address located?
              <span className="ml-1">
                <PopoverTrigger content="Kentucky tax and insurance regulations require payment from insurers for certain risk areas in KY. We need to know which municipality to pay when more than one could apply." />
              </span>
            </div>
            <div className="custom-control custom-radio">
              {taxRegions.map((region) => {
                return (
                  <div key={`${region.name.replace(/ /g, "")}`}>
                    <Field
                      id={`${region.name.replace(/ /g, "")}`}
                      name={`${taxFields}.venueMunicipalityCode`}
                      component={RadioInput}
                      className="custom-control-input"
                      value={region.code}
                    />
                    <label
                      className="custom-control-label label"
                      htmlFor={`${region.name.replace(/ /g, "")}`}
                    >
                      {region.name}
                    </label>
                  </div>
                );
              })}
              <div>
                <Field
                  type="radio"
                  id="noEventRegion"
                  name={`${taxFields}.venueMunicipalityCode`}
                  className="custom-control-input"
                  component={RadioInput}
                  value=""
                />
                <label
                  className="custom-control-label label"
                  htmlFor="noEventRegion"
                >
                  None
                </label>
              </div>
            </div>
            <br />
          </>
        )}
      </>
    </>
  );
};

export default SpecialTaxFields;
