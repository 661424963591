import React, { useRef } from "react";
import { useAppStore } from "@reactables/react-helpers";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import { Permissions } from "Features/Shared/Models/permissions.model";
import { sectionScroll } from "Helpers/sectionScroll";
import { getHasPermissions } from "Features/Shared/Rx/Selectors/auth.selector";
import { AppState } from "Features/Shared/Rx/RxApp";
import Venue from "./Venue/Venue";
import BasicCoverage from "./BasicCoverage/BasicCoverage";
import { Event } from "./Event/Event";
import AdditionalCoverages from "./AdditionalCoverages/AdditionalCoverages";
import { getIsEventSectionEnabled } from "../../Rx/RxCoverageApplication/Selectors/event.selector";

const ApplicationPage = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const basicCoverageRef = useRef(null);
  const eventRef = useRef(null);
  const additionalCoverageRef = useRef(null);
  const [state] = rxCoverageApplication;
  const [appState] = useAppStore<AppState>();

  if (!appState) return;

  const enableTimeTravel =
    getIsEventSectionEnabled(state) &&
    process.env.REACT_APP_ENV !== "production" &&
    getHasPermissions(appState, [Permissions.ManageSettings]);

  return (
    <>
      <Venue
        rxCoverageApplication={rxCoverageApplication}
        onConfirm={() => sectionScroll(basicCoverageRef)}
      />
      <div ref={basicCoverageRef}>
        <BasicCoverage
          rxCoverageApplication={rxCoverageApplication}
          onConfirm={() => sectionScroll(eventRef)}
        />
      </div>
      <div ref={eventRef}>
        <Event
          rxCoverageApplication={rxCoverageApplication}
          onConfirm={() => sectionScroll(additionalCoverageRef)}
          enableTimeTravel={enableTimeTravel}
        />
      </div>
      <div ref={additionalCoverageRef}>
        <AdditionalCoverages rxCoverageApplication={rxCoverageApplication} />
      </div>
    </>
  );
};

export default ApplicationPage;
