const eventSelectList = (types) => {
  const eventsList = types
    .map((type) => {
      return {
        ...type,
        name: type.name,
      };
    })
    .sort((a, b) => (a.name > b.name) * 2 - 1);
  return eventsList;
};

export default eventSelectList;
