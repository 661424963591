import { Action } from "@reactables/core";
import { OperatorFunction, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { EventType } from "Features/CoverageApplication/Models/event.model";

export const mapEventTypeResponse: OperatorFunction<
  { data: EventType[] },
  Action<EventType[]>
> = (eventTypes$) =>
  eventTypes$.pipe(
    map(({ data }: { data: EventType[] }) => ({
      type: "fetchEventTypesSuccess",
      payload: data,
    })),
    catchError((error) =>
      of({ type: "fetchEventTypesFailure", payload: error })
    )
  );
