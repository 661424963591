import React, { useRef } from "react";
import { sectionScroll } from "Helpers/sectionScroll";
import { RxCoverageApplicationProp } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import Insured from "./Insured/Insured";
import InsuranceContact from "./InsuranceContact/InsuranceContact";
import PaymentInformation from "./PaymentInformation/PaymentInformation";

const CheckoutPage = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const insuranceContactRef = useRef(null);
  const paymentInfoRef = useRef(null);

  return (
    <>
      <Insured
        rxCoverageApplication={rxCoverageApplication}
        onConfirm={() => sectionScroll(insuranceContactRef)}
      />
      <div ref={insuranceContactRef}>
        <InsuranceContact
          rxCoverageApplication={rxCoverageApplication}
          onConfirm={() => sectionScroll(paymentInfoRef)}
        />
      </div>
      <div ref={paymentInfoRef}>
        <PaymentInformation rxCoverageApplication={rxCoverageApplication} />
      </div>
    </>
  );
};

export default CheckoutPage;
