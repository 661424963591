import React from "react";
import { Button } from "@jauntin/react-ui";
import { Field } from "@reactables/react-forms";
import ButtonEdit from "Features/Shared/Components/ButtonEdit";
import TerrorismField from "./TerrorismField";
import SellingLiquorField from "./SellingLiquorField";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import DamageToRentedPropertyField from "./DamageToRentedPropertyField";
import * as VenueSelectors from "../../../Rx/RxCoverageApplication/Selectors/venue.selector";
import { AdditionalCoverageTypes } from "../../../Models/venue.model";
import View from "./View";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";
import { ApplicationForm } from "../../../Models/applicationForm.model";

const AdditionalCoverages = ({
  rxCoverageApplication,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
}) => {
  const [state, actions] = rxCoverageApplication;
  const enabled = FormSelectors.getControl(state, "event.confirmed").touched;

  const {
    additionalCoverages: { confirmed },
  } = state.applicationForm.root.value as ApplicationForm;

  const { priceValues } = state;

  const liquorLiabilityMandatory = VenueSelectors.getAcMandatory(
    state,
    AdditionalCoverageTypes.LiquorLiability
  );
  const drpMandatory = VenueSelectors.getAcMandatory(
    state,
    AdditionalCoverageTypes.DamageToRentedProperty
  );

  return (
    <div className={`component ${enabled ? "enabled" : "disabled"}`}>
      <div className="form-row justify-content-between">
        <div className="col-auto my-auto">
          <h3>Additional Coverages</h3>
        </div>
        <ButtonEdit
          edit={() => {
            actions.applicationForm.updateValues({
              controlRef: ["additionalCoverages", "confirmed"],
              value: false,
            });
          }}
          show={confirmed}
        />
      </div>
      <div className="card">
        <div className="card-body">
          {confirmed ? (
            <View rxCoverageApplication={rxCoverageApplication} />
          ) : (
            <>
              <div className="form-row mb-3">
                <Field
                  component={TerrorismField}
                  name="additionalCoverages.terrorism"
                  disabled={true}
                  enabled={enabled}
                />
              </div>
              <div className="form-row mb-3">
                <Field
                  component={SellingLiquorField}
                  name="additionalCoverages.liquorLiability"
                  liquorPrice={priceValues?.liquor}
                  disabled={liquorLiabilityMandatory || !enabled}
                />
              </div>
              <div className="form-row">
                <Field
                  component={DamageToRentedPropertyField}
                  name="additionalCoverages.damageToRentedProperty"
                  damageToRentedPropertyPrice={
                    priceValues?.damageToRentedProperty
                  }
                  disabled={drpMandatory || !enabled}
                />
              </div>
              <hr />
              <div className="float-right">
                <Button
                  disabled={!enabled}
                  onClick={() => {
                    actions.applicationForm.confirmFormGroup(
                      "additionalCoverages"
                    );
                  }}
                  text="Next"
                  className="btn-primary"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalCoverages;
