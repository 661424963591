import React from "react";
const RadioBtn = ({ input, meta, cols = "6", text, ...props }) => {
  return (
    <div
      className={`btn-group-toggle col-sm-12 col-md-${cols} my-2`}
      data-toggle="button"
    >
      <label
        className={`btn btn-outline-secondary btn--radio font-weight-bold ${
          input.value === props.value ? "active" : ""
        }`}
        htmlFor={`${input.name}${props.value}`}
      >
        <input
          id={`${input.name}${props.value}`}
          type="radio"
          {...input}
          {...props}
          checked={input.value === props.value}
        />
        <span className="w-100 text-center">{text}</span>
      </label>
    </div>
  );
};

export default RadioBtn;
