class RefundCancelService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  submitCancelPolicy({ policyNumber, creditCardNumber, venueZip }) {
    return this.api.post({
      location: "/cancelPolicy",
      body: {
        policyNumber,
        creditCardNumber,
        venueZip,
      },
    });
  }
}

export default RefundCancelService;
