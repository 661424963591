import { group, control } from "@reactables/forms";
import { VenueContact } from "../../../Models/uploadForm.model";

export const venueContact = (
  contact: VenueContact = { email: "", copyOnEmails: false }
) =>
  group({
    controls: {
      email: control([contact.email, ["required", "email"]]),
      copyOnEmails: control([contact.copyOnEmails]),
    },
  });
