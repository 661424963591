import React from "react";
import { useReactable } from "@reactables/react-helpers";
import { RxQuoteLoader, EXPIRED_QUOTE_VERSION } from "@jauntin/reactables";
import { Spinner } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import API from "Helpers/API";
import RouteParser from "Helpers/RouteParser";
import PriceService from "Services/PriceService";
import Storage from "Helpers/Storage";
import { EventType } from "../../Models/event.model";
import { RxCoverageApplicationState } from "../../Rx/RxCoverageApplication/RxCoverageApplication";
import { quoteVersion } from "Constants/quoteVersion";

const QuoteLoader = () => {
  const { quoteId } = useParams() as { quoteId: string };
  const history = useHistory();
  const eventTypeUrl = new RouteParser().linkToEventTypePage();

  const [state] = useReactable(
    RxQuoteLoader<RxCoverageApplicationState>,
    new PriceService(new API()),
    {
      quoteId,
      onLoadSuccess: ({ data }) => {
        if (data.state.quoteVersion !== quoteVersion) {
          throw EXPIRED_QUOTE_VERSION;
        }

        const eventType = (
          data.state.applicationForm.eventType.value as EventType
        ).identifier;
        Storage.setItem("state", data.state);

        history.push(
          new RouteParser(`/${eventType}/application`).linkToApplicationPage()
        );
      },
    }
  );

  if (!state) return;

  const { loading, error } = state;

  return (
    <div className="quote-loader d-flex align-items-center justify-content-center mh--hf container">
      <div className="d-flex align-items-center">
        {loading ? (
          <>
            <Spinner animation="border" role="status" />
            <span className="ml-3">Loading your quote...</span>
          </>
        ) : (
          <>
            {error === "genericServerError" && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  Sorry, we were unable to process your request. Please try
                  again or contact us at{" "}
                  <span className="text-nowrap">(844) 747-6240</span> or
                  gatherguard@intactinsurance.com for further assistance.{" "}
                </div>
              </Alert>
            )}
            {error === "invalidQuote" && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  Sorry, your link for the quote has expired or is no longer
                  valid. Please apply again{" "}
                  <Link to={eventTypeUrl}>
                    <u>here</u>
                  </Link>
                  . Or contact us at{" "}
                  <span className="text-nowrap">(844) 747-6240</span> or
                  gatherguard@intactinsurance.com for further assistance.{" "}
                </div>
              </Alert>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuoteLoader;
