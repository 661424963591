import React from "react";
import { Switch } from "react-router-dom";
import PageNotFoundRedirect from "./PageNotFoundRedirect";

const CustomSwitch = ({ notFoundRedirectUrl = "", children }) => {
  return (
    <Switch>
      {children}
      <PageNotFoundRedirect notFoundRedirectUrl={notFoundRedirectUrl} />
    </Switch>
  );
};

export default CustomSwitch;
