import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { SelectPlaceAddressSearchPayload } from "../../../Models/venue.model";
import {
  VenueSearchResponse,
  Tax,
  AdditionalCoverageTypes,
} from "../../../Models/venue.model";
import { ApplicationForm } from "../../../Models/applicationForm.model";
import { ControlModels } from "@reactables/forms";

export const getVenueNotFound = (state: RxCoverageApplicationState) =>
  state.applicationForm["venue.venueSearchResults.knownVenue"]?.value === false;

export const getKnownVenue = (
  state: RxCoverageApplicationState
): VenueSearchResponse =>
  state.applicationForm["venue.venueSearchResults.knownVenue"]
    ?.value as VenueSearchResponse;

export const getIsCheckingVenueCode = (state: RxCoverageApplicationState) => {
  return (
    state.applicationForm["venue.byVenueCode"]?.valid &&
    state.applicationForm["venue.venueSearchResults.hasTaxLookup"].value ===
      null &&
    (!state.applicationForm["venue.venueSearchResults.apiError"]
      .value as boolean)
  );
};

export const getIsCheckingAddressSearch = (
  state: RxCoverageApplicationState
) => {
  return (
    state.applicationForm["venue.bySearch"]?.valid &&
    getKnownVenue(state) === null &&
    (!state.applicationForm["venue.venueSearchResults.apiError"]
      .value as boolean)
  );
};

export const getKnownVenueAddressComponents = (
  state: RxCoverageApplicationState
) => {
  const knownVenue = getKnownVenue(state);
  if (!knownVenue) return undefined;
  return parseAddress(knownVenue.venue);
};

const parseAddress = (data: {
  address1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  companyName: string;
}) => {
  const addressKeys = ["address1", "city", "state", "zip", "country"];

  const addressComponents: { companyName?: string } = {};
  let address = "";
  addressKeys.forEach((key) => {
    addressComponents[key] = data[key];
    address += `${data[key]}, `;
  });
  addressComponents.companyName = data.companyName;
  address = address.slice(0, -2);

  return { address, addressComponents };
};

export const getIsNotKentuckyEntity = (state: RxCoverageApplicationState) =>
  state.applicationForm[
    "venue.venueSearchResults.taxFields.kentuckyStateEntity"
  ]?.value === "no";

export const getTaxRegions = (state: RxCoverageApplicationState) =>
  state.applicationForm["venue.venueSearchResults.taxRegions"]?.value as Tax[];

export const getShowManualAddressOption = (state: RxCoverageApplicationState) =>
  Boolean(state.referral.facilityCode);

export const getFacilityReferralCode = (state: RxCoverageApplicationState) =>
  state.referral.facilityCode;

export const getShowTaxFields = (state: RxCoverageApplicationState) => {
  return Boolean(state.applicationForm["venue.venueSearchResults.taxFields"]);
};

export const getByAddressSearchSelection = (
  state: RxCoverageApplicationState
) =>
  state.applicationForm["venue.bySearch.selectedPlace"]
    ?.value as SelectPlaceAddressSearchPayload;

export const getUtcOffset = (state: RxCoverageApplicationState) =>
  state.applicationForm["venue.venueSearchResults.utcOffset"]?.value as number;

export const getGlLimits = (state: RxCoverageApplicationState) => {
  const knownVenue = getKnownVenue(state);

  return knownVenue?.venue?.glLimits || [1000000, 2000000];
};

export const getAcMandatory = (
  state: RxCoverageApplicationState,
  additionalCoverageType: AdditionalCoverageTypes
) => {
  const knownVenue = getKnownVenue(state);
  if (knownVenue) {
    return Boolean(
      knownVenue.venue.additionalCoverage.find(
        (ac) => ac.type === additionalCoverageType && ac.mandatory
      )
    );
  }

  return false;
};

export const getVenueState = (state: RxCoverageApplicationState): string => {
  const knownVenue = getKnownVenue(state);

  if (knownVenue) {
    return knownVenue.venue.state;
  }

  if (state.applicationForm["venue.bySearch"]) {
    return getByAddressSearchSelection(state)?.addressComponents?.state;
  }

  if (state.applicationForm["venue.byManualAddress"]) {
    return (
      state.applicationForm["venue.byManualAddress"].value as {
        venueName: string;
        address: {
          address: string;
          city: string;
          state: string;
          zip: string;
        };
      }
    ).address.state;
  }

  return "";
};

export const getVenueAddressFromForm = (
  form: ControlModels.Form<ApplicationForm>
) => {
  const { venue } = form.root.value;
  const knownVenue = venue.venueSearchResults.knownVenue;
  if (knownVenue) {
    const { address1, companyName, city, state, zip, country } =
      knownVenue.venue;
    return {
      address1,
      companyName,
      city,
      state,
      zip,
      country,
    };
  }

  if (venue.bySearch?.selectedPlace?.addressComponents) {
    const { address1, companyName, city, state, zip, country } =
      venue.bySearch?.selectedPlace?.addressComponents;

    return { address1, companyName, city, state, zip, country };
  }

  if (venue.byManualAddress) {
    const {
      address: { address: address1, city, state, zip },
      venueName,
    } = venue.byManualAddress;

    return {
      address1,
      companyName: venueName,
      city,
      state,
      zip,
      country: "US",
    };
  }

  return {
    address1: "",
    companyName: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };
};

export const getVenueAddress = (state: RxCoverageApplicationState) => {
  return getVenueAddressFromForm(state.applicationForm);
};

export const getParsedVenueAddress = (state: RxCoverageApplicationState) =>
  parseAddress(getVenueAddress(state));

export const getCertHolders = (state: RxCoverageApplicationState) => {
  const knownVenue = getKnownVenue(state);

  if (knownVenue) {
    return state.venueCertHolders.data || [];
  }

  return state.referral.facilityCertHolders.concat(
    state.venueCertHolders.data || []
  );
};

export const getShowVenueCertPendingMsg = (state: RxCoverageApplicationState) =>
  Boolean(
    state.referral.facilityCertHolders.length &&
      getCertHolders(state).length === 1
  );
