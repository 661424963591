import { Reducer } from "@reactables/core";
import { Reducers } from "@jauntin/reactables";
import { RxCoverageApplicationState } from "../RxCoverageApplication";

export const venueReferralLookup: Record<
  string,
  Reducer<RxCoverageApplicationState>
> = {
  venueReferralLookup: (state: RxCoverageApplicationState, action) => ({
    ...state,
    referral: {
      ...state.referral,
      venueReferral: Reducers.load(state.referral.venueReferral),
    },
  }),
  knownVenueFound: (state: RxCoverageApplicationState, action) => ({
    ...state,
    referral: {
      ...state.referral,
      facilityCode: "",
      venueReferral: Reducers.loadSuccess(state.referral.venueReferral, action),
    },
  }),
  venueReferralLookupFailure: (state: RxCoverageApplicationState, action) => ({
    ...state,
    referral: {
      ...state.referral,
      venueReferral: Reducers.loadError(state.referral.venueReferral, action),
    },
  }),
};
