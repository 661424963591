import React from "react";
import CurrencyHelper from "Helpers/CurrencyHelper";
import SelectList from "Features/Shared/Components/SelectList";

const CoverageField = ({ input, meta, fieldOptions, disabled }) => (
  <>
    <div className="col my-auto">
      <p className="mb-0">Limit for each incident (Occurrence)</p>
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
    <div className="col-auto my-auto">
      <SelectList
        options={fieldOptions.map((option) => ({
          value: option,
          label: new CurrencyHelper().setValue(option).dollars(),
        }))}
        searchable={false}
        input={input}
        meta={meta}
        disabled={disabled}
      />
    </div>
  </>
);

export default CoverageField;
