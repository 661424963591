import { Action } from "@reactables/core";
import { from, of } from "rxjs";
import { mergeMap, map, catchError, tap } from "rxjs/operators";
import PaymentService from "../../../../../Services/PaymentService";
import { Reducers, pollWhile } from "@jauntin/reactables";
import {
  RxCoverageApplicationState,
  RxCoverageApplicationOptions,
} from "../RxCoverageApplication";

export interface DownloadDocumentsActions {
  downloadDocuments: (payload: {
    orderNumber: string;
    pdfLink: string;
    fileName: string;
  }) => void;
}

/**
 * @description download documents via polling
 */
export const downloadDocuments = (
  paymentService: PaymentService,
  options: RxCoverageApplicationOptions
) => ({
  downloadDocuments: {
    reducer: (state: RxCoverageApplicationState) => ({
      ...state,
      documents: Reducers.load(state.documents),
    }),
    effects: [
      (download$) =>
        download$.pipe(
          mergeMap(
            ({
              payload: { orderNumber, pdfLink, fileName },
            }: Action<{
              orderNumber: string;
              pdfLink: string;
              fileName: string;
            }>) =>
              of({}).pipe(
                pollWhile({
                  source: () =>
                    from(paymentService.getDocumentsStatus(orderNumber)),
                  isPollingActive: ({ data }: { data: boolean }) => {
                    return !data;
                  },
                  emitOnlyLast: true,
                }),
                mergeMap(() => from(paymentService.getPolicyDocs(pdfLink))),
                tap(({ data }: { data: string | File | Blob | Uint8Array }) => {
                  options.hooks.onDownloadDocumentsSuccess &&
                    options.hooks.onDownloadDocumentsSuccess(data, fileName);
                }),
                map(() => ({ type: "downloadSuccess" })),
                catchError(() => of({ type: "downloadFailure" }))
              )
          )
        ),
    ],
  },
  downloadSuccess: {
    reducer: (state: RxCoverageApplicationState) => ({
      ...state,
      documents: Reducers.loadSuccess(state.documents),
    }),
  },
  downloadFailure: {
    reducer: (state: RxCoverageApplicationState, action) => ({
      ...state,
      documents: Reducers.loadError(state.documents, action),
    }),
  },
});
