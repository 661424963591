import { RxCoverageApplicationState } from "../RxCoverageApplication";
import { ApplicationForm } from "../../../Models/applicationForm.model";
import {
  CompanyTypes,
  ContactType,
  AddressContactType,
} from "Constants/contactTypes";
import { getPriceValues } from "./quote.selector";
import { CreateCoveragePayload } from "../../../Models/purchase.model";
import { getBaseData } from "./quote.selector";

export const getCreateCoveragePayload = (
  state: RxCoverageApplicationState
): CreateCoveragePayload => {
  const {
    eventType,
    underwritingQuestions,
    insured,
    insuranceContact,
    payment,
    purchase,
  } = state.applicationForm["root"].value as ApplicationForm;

  const insuredContactType: AddressContactType = (() => {
    if (insured.type === ContactType.Person) {
      return insured.type;
    }

    if (insured.companyDetails.type === CompanyTypes.JointVenture) {
      return insured.companyDetails.jointVentureType;
    } else {
      return insured.companyDetails.type;
    }
  })();

  return {
    ...getBaseData(state),
    eventType: eventType.identifier,
    lossClaims: underwritingQuestions.lossClaims === "yes",
    security: underwritingQuestions.security === "yes",
    promoter: underwritingQuestions.promoter === "yes",
    renterAddress: (() => {
      const renterAddress = {
        address1: insured.address.address,
        city: insured.address.city,
        state: insured.address.state,
        country: "US",
        zip: insured.address.zip,
        contactType: insuredContactType,
        ...(() => {
          if (insured.type === ContactType.Person) {
            return {
              contactFirstName: insured.personName.firstName,
              contactLastName: insured.personName.lastName,
            };
          } else {
            return {
              companyName: insured.companyDetails.name,
            };
          }
        })(),
      };

      return [renterAddress];
    })(),
    cardInfo: (() => {
      const {
        token: { token, maskedPan, cardType },
      } = payment;

      return [
        {
          token,
          cardType,
          lastFour: maskedPan.substring(maskedPan.length - 4),
          nameOnCard: payment.billing.creditCard.cardName,
          amountDetails: {
            totalAmount: getPriceValues(state).total,
            currency: "USD",
          },
        },
      ];
    })(),
    insuranceContactPhone: insuranceContact.phone,
    insuranceContactEmail: insuranceContact.email,
    consentConfirmed: purchase.consent,
    excludedActivitiesConfirmed: purchase.excludedActivities,
    billingAddress:
      payment.billing.sameAddressAsContact === "yes"
        ? undefined
        : [
            {
              address1: payment.billing.differentAddressFields.address.address,
              city: payment.billing.differentAddressFields.address.city,
              state: payment.billing.differentAddressFields.address.state,
              zip: payment.billing.differentAddressFields.address.zip,
              country: "US",
              ...(() => {
                if (
                  payment.billing.differentAddressFields.type ===
                  ContactType.Company
                ) {
                  return {
                    contactType: CompanyTypes.Other,
                    companyName:
                      payment.billing.differentAddressFields.companyName,
                    contactFirstName: insuranceContact.firstName,
                    contactLastName: insuranceContact.lastName,
                  };
                } else {
                  return {
                    contactType: ContactType.Person,
                    contactFirstName:
                      payment.billing.differentAddressFields.firstName,
                    contactLastName:
                      payment.billing.differentAddressFields.lastName,
                  };
                }
              })(),
            },
          ],
    insuranceContactAddress: (() => {
      return [
        {
          contactFirstName: insuranceContact.firstName,
          contactLastName: insuranceContact.lastName,
          ...(() => {
            if (insuranceContact.sameAddressAsInsured === "yes") {
              return {
                companyName:
                  insured.type === ContactType.Company
                    ? insured.companyDetails.name
                    : undefined,
                address1: insured.address.address,
                city: insured.address.city,
                state: insured.address.state,
                country: "US",
                zip: insured.address.zip,
                contactType: insuredContactType,
              };
            } else {
              return {
                companyName:
                  insuranceContact.differentAddressFields.type ===
                  ContactType.Company
                    ? insuranceContact.differentAddressFields.companyName
                    : insured.companyDetails?.name || undefined,
                address1:
                  insuranceContact.differentAddressFields.address.address,
                city: insuranceContact.differentAddressFields.address.city,
                state: insuranceContact.differentAddressFields.address.state,
                country: "US",
                zip: insuranceContact.differentAddressFields.address.zip,
                contactType:
                  insuranceContact.differentAddressFields.type ===
                  ContactType.Person
                    ? ContactType.Person
                    : CompanyTypes.Other,
              };
            }
          })(),
        },
      ];
    })(),
  };
};

export const getApplicationIncomplete = (state: RxCoverageApplicationState) =>
  !state.purchase.success;
