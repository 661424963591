import { Effect } from "@reactables/core";
import { from, of, concat } from "rxjs";
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  distinctUntilChanged,
  switchMap,
  catchError,
} from "rxjs/operators";
import { RxCoverageApplicationState } from "../RxCoverageApplication";

import { getQuoteRequestParams } from "../Selectors/quote.selector";
import { getVenueAddressFromForm } from "../Selectors/venue.selector";

import { ControlModels } from "@reactables/forms";
import PriceService from "Services/PriceService";
import { QuoteResponse } from "Features/CoverageApplication/Models/quote.model";
import {
  ApplicationForm,
  KnownVenue,
} from "Features/CoverageApplication/Models/applicationForm.model";
import { facilityNameLookUp } from "../Operators/facility.operator";
import { mapCertHolders } from "../Operators/mapCertholders.operator";
import FacilityNameService from "Services/FacilityNameService";
import VenuePresetsService from "Services/VenuePresetsService";

/**
 * @description
 * - debounces form changes
 * - fetches quote
 * */
export const onFormChangeFetchQuote =
  (
    priceService: PriceService
  ): Effect<ControlModels.Form<ApplicationForm>, QuoteResponse> =>
  (formChange$) =>
    formChange$.pipe(
      debounceTime(500),
      map(({ payload: form }) =>
        getQuoteRequestParams({
          applicationForm: form,
        } as RxCoverageApplicationState)
      ),
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      ),
      switchMap(({ selection, daysOfWeek, ...request }) =>
        from(priceService.getQuote(request)).pipe(
          map(({ data }: { data: QuoteResponse }) => ({
            type: "fetchQuoteSuccess",
            payload: data,
          })),
          catchError(() => of({ type: "fetchQuoteError" }))
        )
      )
    );

/**
 * @description Fetches facility name referral on venue code search result
 */
export const onVenueCodeSearchGetFacilityName =
  (
    facilityNameService: FacilityNameService
  ): Effect<
    ControlModels.Form<ApplicationForm>,
    { facilityName: string; venueCode: string }
  > =>
  (formChange$) =>
    formChange$.pipe(
      map(({ payload: form }) => form.root.value as ApplicationForm),
      distinctUntilChanged(
        (prev, curr) =>
          curr.venue.venueSearchResults.knownVenue ===
          prev.venue.venueSearchResults.knownVenue
      ),
      filter(
        ({ venue }) =>
          venue.venueSearchResults.knownVenue && Boolean(venue.byVenueCode)
      ),
      map(({ venue }) => venue.venueSearchResults.knownVenue),
      mergeMap(({ facility, venue }: KnownVenue) => {
        return of(facility.code).pipe(
          facilityNameLookUp(facilityNameService),
          map((payload) => ({
            type: "venueCodeFacilityNameSuccess",
            payload: {
              venueCode: `${facility.code}-${venue.venueCode}`.toLowerCase(),
              facilityName: payload.facilityName,
            },
          }))
        );
      })
    );

/**
 * @description detects venue changes and get cert holders for venue
 * - returns empty cert holders if a valid venue has not been selected
 */
export const onVenueChangeFetchCertHolders =
  (
    venuePresetsService: VenuePresetsService
  ): Effect<ControlModels.Form<ApplicationForm>, string[] | unknown> =>
  (formChange$) =>
    formChange$.pipe(
      map(({ payload }) => payload),
      distinctUntilChanged(
        ({ root: { value: prevVal } }, { root: { value: currVal } }) => {
          const {
            venue: { confirmed: prevConfirmed, ...prevVenue },
          } = prevVal;
          const {
            venue: { confirmed, ...currVenue },
          } = currVal;
          return JSON.stringify(prevVenue) === JSON.stringify(currVenue);
        }
      ),
      debounceTime(500),
      switchMap((form) => {
        const getCertHolders = (code) =>
          from(venuePresetsService.getAdditionalCertHolders(code)).pipe(
            mapCertHolders,
            map((payload) => ({
              type: "fetchVenueCertHoldersSuccess",
              payload,
            })),
            catchError((error) =>
              of({
                type: "fetchVenueCertHoldersFailure",
                payload: error,
              })
            )
          );

        if (!form.venue.valid)
          return of({
            type: "clearVenueCertHolders",
          });

        // Cert Holders for known venues
        const knownVenue = form.root.value.venue.venueSearchResults.knownVenue;

        if (knownVenue) {
          return concat(
            of({ type: "fetchVenueCertHolders" }),
            getCertHolders(
              `${knownVenue.facility.code}-${knownVenue.venue.venueCode}`
            )
          );
        }

        // Unknown Venues
        const {
          companyName,
          address1,
          state: usState,
          zip,
          city,
        } = getVenueAddressFromForm(form);

        const certHolder = `${
          companyName ? companyName : ""
        }\n${address1}\n${city}, ${usState} ${zip}`;

        return of({
          type: "fetchVenueCertHoldersSuccess",
          payload: [certHolder],
        });
      })
    );
