import React from "react";
import { Link } from "react-router-dom";
import GatherGuard from "../../../Assets/Images/L_Intact_ins_Horiz_Entertainment_rgb.svg";

const Header = () => (
  <header className="header">
    <div className="container h-100">
      <div className="form-row h-100 align-items-center justify-content-between">
        <div className="col col-sm-auto">
          <Link to="/">
            <img
              width="175"
              height="36"
              src={GatherGuard}
              alt="Logo"
              className="img-fluid logo__img"
            />
          </Link>
        </div>
        <div className="col-auto gatherguard">GatherGuard</div>
        <div className="col-auto text-right">
          <div className="header__help-text">
            For&nbsp;assistance, please&nbsp;call
          </div>
          <a href="tel:+1-844-747-6240" className="header__help-num">
            (844) 747-6240
          </a>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
