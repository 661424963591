import { combineLatest, Observable, from, of } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import { Reactable, Action } from "@reactables/core";
import { ControlModels, RxFormActions } from "@reactables/forms";
import { normalizers } from "@jauntin/utilities";
import {
  Reducers,
  validatorFns,
  RxBuilder,
  FormBuilders,
} from "@jauntin/reactables";
import { refundsCancellations } from "./Configs/refundsCancellations.config";
import RefundCancelService from "../../../Services/RefundCancelService";

interface CancellationForm {
  policyNumber: string;
  creditCardNumber: string;
  venueZip: string;
  confirm: boolean;
}

export interface RxRefundsCancellationsActions {
  form: RxFormActions;
  submit: (payload: CancellationForm) => void;
  hideErrorModal: () => void;
}

export interface RxRefundsCancellationsState {
  form: ControlModels.Form<CancellationForm>;
  submission: { showErrorModal: boolean } & Reducers.LoadableState<{
    referenceNumber: string;
    policyNumber: string;
    email: string;
  }>;
}

const RxRefundsCancellations = (
  refundCancelService: RefundCancelService
): Reactable<RxRefundsCancellationsState, RxRefundsCancellationsActions> => {
  const rxForm = FormBuilders.build(refundsCancellations, {
    name: "rxRefundCancellationsForm",
    providers: { validators: validatorFns(), normalizers },
  });

  const rxSubmission = RxBuilder({
    name: "rxRefundsCancellations",
    initialState: { ...Reducers.loadableInitialState, showErrorModal: false },
    reducers: {
      submit: {
        reducer: Reducers.load,
        effects: [
          (submission$) =>
            submission$.pipe(
              mergeMap(
                ({
                  payload: { confirm, ...payload },
                }: Action<CancellationForm>) =>
                  from(refundCancelService.submitCancelPolicy(payload)).pipe(
                    map(({ data }) => ({
                      type: "submitSuccess",
                      payload: data,
                    })),
                    catchError((error) =>
                      of({ type: "submitFailure", payload: error })
                    )
                  )
              )
            ),
        ],
      },
      submitSuccess: Reducers.loadSuccess,
      submitFailure: (state, action) => ({
        ...Reducers.loadError(state, action),
        showErrorModal: true,
      }),
      hideErrorModal: (state) => ({ ...state, showErrorModal: false }),
    },
  });

  const state$ = combineLatest({
    form: rxForm[0] as Observable<ControlModels.Form<CancellationForm>>,
    submission: rxSubmission[0],
  });

  const actions = {
    form: rxForm[1],
    submit: rxSubmission[1].submit,
    hideErrorModal: rxSubmission[1].hideErrorModal,
  };

  return [state$, actions];
};

export default RxRefundsCancellations;
