import { OperatorFunction } from "rxjs";
import { ControlModels } from "@reactables/forms";
import { Action } from "@reactables/core";
import { map, distinctUntilChanged } from "rxjs/operators";
import * as FormSelectors from "../Selectors/form.selector";
import { ApplicationForm } from "Features/CoverageApplication/Models/applicationForm.model";
import { RxCoverageApplicationState } from "../RxCoverageApplication";

/**
 * @description Filters form value changes by key
 */
export const formValueChange =
  (key = "root"): OperatorFunction<RxCoverageApplicationState, unknown> =>
  (rxCoverageApplicationState$) =>
    rxCoverageApplicationState$.pipe(
      map((state) => FormSelectors.getControl(state, key).value),
      distinctUntilChanged()
    );

/**
 * @description maps form change to action
 */
export const formChangeToAction: OperatorFunction<
  ControlModels.Form<ApplicationForm>,
  Action<ControlModels.Form<ApplicationForm>>
> = (applicationForm$) =>
  applicationForm$.pipe(
    map((payload) => ({ type: "applicationFormChange", payload }))
  );
