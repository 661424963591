const nonAddressTypes = [
  "route",
  "country",
  "locality",
  "sublocality",
  "political",
  "neighborhood",
];
const addressTypeIsForAddress = (addressTypes) =>
  !addressTypes.some((a) => nonAddressTypes.includes(a));

export { addressTypeIsForAddress };
