import React from "react";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import rxApp from "./Features/Shared/Rx/RxApp";
import { StoreProvider } from "@reactables/react-helpers";
import App from "./App";
import { buildTime } from "Constants/buildTime";
import Storage from "Helpers/Storage";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);

// In a non-dev environment, avoid loading state saved in storage
// from a previous build.
if (
  process.env.NODE_ENV !== "development" &&
  Storage.getItem("buildTime") !== buildTime
) {
  Storage.setItem("buildTime", buildTime);
  Storage.removeItem("state");
}

root.render(
  <StoreProvider rxStore={rxApp}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>
);
