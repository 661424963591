import React from "react";
import { Route, Redirect } from "react-router-dom";

const GuardedRoute = ({
  component: Component,
  path,
  guards,
  state,
  redirectComponent: RedirectComponent = () => <Redirect to="/" />,
  exact = false,
  children = <></>,
  ...componentProps
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (guards.every((guard) => guard(state))) {
          return (
            <>
              <Component {...props} {...componentProps} />
              {children}
            </>
          );
        }

        return <RedirectComponent />;
      }}
    />
  );
};

export default GuardedRoute;
