import React from "react";
import { Button, TextInput, CheckboxInput } from "@jauntin/react-ui";
import { useReactable } from "@reactables/react-helpers";
import { Field, Form } from "@reactables/react-forms";
import RxRefundsCancellations from "../Rx/RxRefundsCancellations";
import RefundCancelService from "../../../Services/RefundCancelService";
import API from "../../../Helpers/API";
import { Modal } from "react-bootstrap";

const RefundsCancellations = () => {
  const [state, actions] = useReactable(
    RxRefundsCancellations,
    new RefundCancelService(new API())
  );

  if (!state) return;

  const {
    submission: { success, data, loading, showErrorModal },
    form,
  } = state;

  return success ? (
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h2>Your event insurance policy has been cancelled.</h2>
          <br />
          <h5>
            <div>
              <strong>Reference Number: #{data.referenceNumber}</strong>
            </div>
            <div>
              <strong>Policy Number: #{data.policyNumber}</strong>
            </div>
          </h5>
          <br />
          <p>
            The premium cost has been refunded to credit card with the last four
            digits of {form.creditCardNumber.value as string}. You will receive
            an email shortly at {data.email} with a copy of your refund receipt.
          </p>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div>
        <div className="row mr-0">
          <div className="col-lg-8">
            <h3 className="mt-3">Cancel Your Event Insurance Policy</h3>
            <div className="card">
              <div className="card-body">
                <Form rxForm={[state.form, actions.form]}>
                  <Field
                    component={TextInput}
                    name="policyNumber"
                    label="Last five digits of your policy number"
                    className="col-sm form-group"
                    inputClassName="form-control-lg w-25 mb-4 cancelfield"
                  />

                  <Field
                    component={TextInput}
                    name="creditCardNumber"
                    label="Last four digits of your credit card"
                    className="col-sm form-group"
                    inputClassName="form-control-lg w-25 mb-4 cancelfield"
                  />

                  <Field
                    component={TextInput}
                    name="venueZip"
                    label="Venue zipcode"
                    subtext="Please enter the venue zipcode as it appears on your policy. (Example: 12345-6789)"
                    className="col-sm form-group"
                    inputClassName="form-control-lg w-25 mb-4 cancelfield"
                  />

                  <Field
                    component={CheckboxInput}
                    name="confirm"
                    label={
                      <strong>Yes, I wish to cancel my policy in full.</strong>
                    }
                    className="col-sm form-group"
                    inputClassName="form-control-lg w-25 mb-4 cancelfield"
                  />
                </Form>

                <hr />

                <div className="text-right">
                  <Button
                    onClick={() => actions.submit(form.root.value)}
                    text="Submit"
                    className="btn-primary"
                    disabled={loading || !form.root.valid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showErrorModal}
        onHide={actions.hideErrorModal}
        dialogClassName="modal--width-md px-md-5"
      >
        <Modal.Header closeButton>
          <h2 className="modal-title h3">&nbsp;</h2>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3 text-center">
            Your request could not be processed at this time. Please call{" "}
            <a
              href="tel:+1-844-747-6240"
              className="pl-1 footer__help-num nowrap"
            >
              (844) 747-6240
            </a>{" "}
            or email us at{" "}
            <a
              href="mailto:gatherguard@intactinsurance.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>gatherguard@intactinsurance.com</strong>
            </a>
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text="Ok"
            className="btn-primary float-right px-5"
            onClick={actions.hideErrorModal}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RefundsCancellations;
