import { group } from "@reactables/forms";
import { eventType } from "./eventTypes.config";
import { venue } from "./venues.config";
import { event } from "./event.config";
import { basicCoverage } from "./basicCoverage.config";
import { additionalCoverages } from "./additionalCoverages.config";
import { insured } from "./insured.config";
import { insuranceContact } from "./insuranceContact.config";
import { payment } from "./payment.config";
import { purchase } from "./purchase.config";

export const form = group({
  controls: {
    eventType,
    venue,
    basicCoverage,
    event,
    additionalCoverages,
    insured,
    insuranceContact,
    payment,
    purchase,
  },
});
