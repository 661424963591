import { group, control } from "@reactables/forms";
import { ContactType } from "Constants/contactTypes";

export const sameAddress = control(["yes"]);

export const address = group({
  controls: {
    address: control(["", ["required"]]),
    city: control(["", ["required"]]),
    state: control(["", ["required"]]),
    zip: control({
      initialValue: "",
      validators: ["required", "zipCode"],
      normalizers: ["normalizeZip"],
    }),
  },
});

export const differentAddressFields = group({
  controls: {
    type: control([ContactType.Person]),
    address,
    firstName: control(["", "required"]),
    lastName: control(["", "required"]),
    // companyName replaces firstName & lastName if type is company
  },
});

export const differentAddressFieldsNoPersonName = group({
  controls: {
    type: control([ContactType.Person]),
    address,
    // companyName here if type is company
  },
});

export const companyName = control(["", "required"]);
