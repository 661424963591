import React from "react";
import { TextInput, PhoneInput, EmailInput, Button } from "@jauntin/react-ui";
import { Field } from "@reactables/react-forms";
import { useAppStore } from "@reactables/react-helpers";
import RadioInput from "Features/Shared/Components/RadioInput";
import ButtonEdit from "Features/Shared/Components/ButtonEdit";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import { AppState } from "Features/Shared/Rx/RxApp";
import { ContactType } from "Constants/contactTypes";
import ContactSameField from "./ContactSameField";
import { RxCoverageApplicationProp } from "../../../Rx/RxCoverageApplication/RxCoverageApplication";
import * as FormSelectors from "../../../Rx/RxCoverageApplication/Selectors/form.selector";
import * as InsuranceContactSelectors from "../../../Rx/RxCoverageApplication/Selectors/insuranceContact.selector";

const InsuranceContact = ({
  rxCoverageApplication,
  onConfirm,
}: {
  rxCoverageApplication: RxCoverageApplicationProp;
  onConfirm: () => void;
}) => {
  const [state, actions] = rxCoverageApplication;
  const contactSame =
    InsuranceContactSelectors.getIsContactSameAsInsured(state);

  const enabled = FormSelectors.getControl(state, "insured.confirmed").touched;

  const [appState] = useAppStore<AppState>();
  const usStates = appState?.usStates.data;
  if (!usStates) return;

  const { insuranceContact } = FormSelectors.getRootValue(state);

  return (
    <div className={`component ${enabled ? "enabled" : "disabled"}`}>
      <div className="form-row justify-content-between">
        <div className="col-auto my-auto">
          <h3>Insurance Contact</h3>
        </div>
        <ButtonEdit
          edit={() => {
            actions.applicationForm.updateValues({
              controlRef: ["insuranceContact", "confirmed"],
              value: false,
            });
          }}
          show={insuranceContact.confirmed}
        />
      </div>
      <div className="card">
        <div className="card-body">
          {insuranceContact.confirmed ? (
            <>
              <p>
                {insuranceContact.firstName} {insuranceContact.lastName}
              </p>
              {insuranceContact.differentAddressFields && (
                <>
                  {insuranceContact.differentAddressFields.companyName && (
                    <p>{insuranceContact.differentAddressFields.companyName}</p>
                  )}
                  <p>
                    {insuranceContact.differentAddressFields.address.address}
                  </p>
                  <p>{`${insuranceContact.differentAddressFields.address.city}, ${insuranceContact.differentAddressFields.address.state}, ${insuranceContact.differentAddressFields.address.zip}, US`}</p>
                </>
              )}
              <p>
                {insuranceContact.phone} -{" "}
                <span className="word-break-all">{insuranceContact.email}</span>
              </p>
            </>
          ) : (
            <>
              <div className="font-weight-bold">
                Enter a contact for the insurance policy
              </div>
              <div className="font-italic">
                We will use this information to email a copy of your proof of
                insurance, or to contact you about the policy.
              </div>
              <br />
              <div className="form-group">
                <ContactSameField
                  name="insuranceContact.sameAddressAsInsured"
                  disabled={!enabled}
                  onChange={(e) =>
                    actions.applicationForm.selectSameAddressAsInsured(
                      e.target.value
                    )
                  }
                />
              </div>
              <div id="contactIndividual" className="form-row">
                <Field
                  component={TextInput}
                  name="insuranceContact.firstName"
                  label="First Name"
                  ariaLabel="First Name"
                  placeholder="First Name"
                  wrapperClassName="col-sm"
                  inputClassName="form-control-lg"
                  disabled={!enabled}
                />
                <Field
                  component={TextInput}
                  name="insuranceContact.lastName"
                  label="Last Name"
                  ariaLabel="Last Name"
                  placeholder="Last Name"
                  wrapperClassName="col-sm"
                  inputClassName="form-control-lg"
                  disabled={!enabled}
                />
              </div>
              <div className="form-row">
                <Field
                  component={PhoneInput}
                  name="insuranceContact.phone"
                  subtext="This will only be used for customer service."
                  wrapperClassName="col-sm-6 d-flex flex-column"
                  inputClassName="form-control-lg"
                  errorClassName="form-group--order-4 my-1 ml-2"
                  disabled={!enabled}
                />
                <Field
                  component={EmailInput}
                  name="insuranceContact.email"
                  subtext="This is where the policy information will be sent."
                  wrapperClassName="col-sm-6 d-flex flex-column"
                  inputClassName="form-control-lg"
                  errorClassName="form-group--order-4 my-1 ml-2"
                  disabled={!enabled}
                />
              </div>
              {!contactSame && (
                <div id="newContactInfo">
                  <div className="form-group mt-4">
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        id={`insuranceContact.differentAddressFields.typeInd`}
                        name="insuranceContact.differentAddressFields.type"
                        className="custom-control-input"
                        component={RadioInput}
                        value={ContactType.Person}
                        onChange={() =>
                          actions.applicationForm.selectDifferentInsuranceAddressContactType(
                            ContactType.Person
                          )
                        }
                        disabled={!enabled}
                      />
                      <label
                        className="custom-control-label label"
                        htmlFor={`insuranceContact.differentAddressFields.typeInd`}
                      >
                        an individual
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        id={`insuranceContact.differentAddressFields.typeCorp`}
                        name="insuranceContact.differentAddressFields.type"
                        className="custom-control-input"
                        component={RadioInput}
                        value={ContactType.Company}
                        onChange={() =>
                          actions.applicationForm.selectDifferentInsuranceAddressContactType(
                            ContactType.Company
                          )
                        }
                        disabled={!enabled}
                      />
                      <label
                        className="custom-control-label label"
                        htmlFor={`insuranceContact.differentAddressFields.typeCorp`}
                      >
                        a company/organization
                      </label>
                    </div>
                  </div>
                  {insuranceContact.differentAddressFields.type ===
                    ContactType.Company && (
                    <div id="contactCompany" className="form-row">
                      <Field
                        component={TextInput}
                        name="insuranceContact.differentAddressFields.companyName"
                        label="Company name"
                        ariaLabel="Company name"
                        placeholder="Company name"
                        wrapperClassName="col-sm"
                        inputClassName="form-control-lg"
                        errorClassName="d-inline ml-2"
                        disabled={!enabled}
                      />
                    </div>
                  )}
                  <AddressFieldGroup
                    groupName={
                      "insuranceContact.differentAddressFields.address"
                    }
                    usStates={usStates}
                    disabled={!enabled}
                  />
                </div>
              )}
              <hr />
              <div className="float-right">
                <Button
                  disabled={
                    !FormSelectors.getControl(state, "insuranceContact")
                      .valid || !enabled
                  }
                  onClick={() => {
                    actions.applicationForm.confirmFormGroup(
                      "insuranceContact"
                    );
                    onConfirm();
                  }}
                  text="Confirm Insurance Contact"
                  className="btn-primary"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsuranceContact;
