import React from "react";
import { Field } from "@reactables/react-forms";
import { TextInput, EmailInput, PhoneInput, Button } from "@jauntin/react-ui";
import { RxVenueUploaderProp } from "../Rx/RxVenueUploader/RxVenueUploader";

const ContactForm = ({
  rxVenueUploader: [state, actions],
}: {
  rxVenueUploader: RxVenueUploaderProp;
}) => {
  const { form } = state;

  const { firstAndLastName, jobTitle, email, phoneNumber } = form[
    "uploadForm.contact"
  ].value as {
    firstAndLastName: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;
  };

  return (
    <div className="card">
      <div className="card-header bg-transparent d-flex">
        <span className="font-weight-bold">
          Your contact information (who is completing this form?)
        </span>
        {!state.editingContact && (
          <Button
            className="btn-link ml-auto"
            onClick={actions.toggleContactEdit}
            text="Edit"
          />
        )}
      </div>
      <div className="card-body">
        {state.editingContact ? (
          <>
            <Field
              name="uploadForm.contact.firstAndLastName"
              component={TextInput}
              label="First and Last Name"
              placeholder="First and Last Name"
              inputClassName="form-control-lg"
            />
            <Field
              name="uploadForm.contact.jobTitle"
              component={TextInput}
              label="Job Title"
              placeholder="Job Title"
              inputClassName="form-control-lg"
            />
            <div className="row">
              <div className="col">
                <Field
                  name="uploadForm.contact.phoneNumber"
                  component={PhoneInput}
                  label="Phone Number"
                  inputClassName="form-control-lg"
                />
              </div>
              <div className="col">
                <Field
                  name="uploadForm.contact.email"
                  component={EmailInput}
                  label="Email Address"
                  placeholder="your@email.com"
                  inputClassName="form-control-lg"
                />
              </div>
            </div>
            <div className="row">
              <Button
                className="btn btn-primary ml-auto"
                disabled={!form["uploadForm.contact"].valid}
                onClick={actions.toggleContactEdit}
                text="Save"
              />
            </div>
          </>
        ) : (
          <p>
            {[firstAndLastName, jobTitle, email, phoneNumber]
              .filter((item) => Boolean(item))
              .join(", ")}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
